import axios from 'axios';
import { API_URL } from '../config/env';
import { getUserToken } from './utils';

axios.defaults.validateStatus = false;
axios.defaults.baseURL = API_URL;

// const ROOT_URL = 'https://horsesfirst.qtipmeapi.com/api-v1';

export function getConfig(form = false, avatar = false) {
  if (getUserToken()) {
    const avatarType = avatar
      ? 'multipart/form-data'
      : 'application/x-www-form-urlencoded';
    const contentType = form ? avatarType : 'application/json';
    if (!getUserToken()) {
      return {
        headers: {
          'Content-Type': contentType,
        },
      };
    }
    return {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': contentType,
      },
    };
  }
  return {};
}

export async function registerUser(payload) {
  return await axios.post(`/users`, payload, getConfig());
}
export async function login(payload) {
  return await axios.post(`/auth`, payload);
}
export async function getProfile(token) {
  return await axios.get(`/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function registerCompany(payload) {
  // const data = new FormData();
  // data.append('username', payload.username);
  // data.append('email', payload.email);
  // if (payload.phone !== '') {
  // 	data.append('phone', payload.phone);
  // }
  // if (payload.companyId !== '') {
  // 	data.append('company_id', payload.companyId);
  // }
  // if (payload.companyName !== '') {
  // 	data.append('company_name', payload.companyName);
  // }
  // if (payload.address !== '') {
  // 	data.append('address', payload.address);
  // }
  // if (payload.logoUrl !== '') {
  // 	data.append('logo_url', payload.logoUrl);
  // }
  // if (payload.bannerUrl !== '') {
  // 	data.append('banner_url', payload.bannerUrl);
  // }
  // if (payload.homepageUrl !== '') {
  // 	const formatUrl = payload.homepageUrl.indexOf('https://') !== -1 ? payload.homepageUrl : `https://${payload.homepageUrl}`;
  // 	data.append('homepage_url', formatUrl);
  // }
  // if (payload.description !== '') {
  // 	data.append('description', payload.description);
  // }
  // if (payload.city !== '') {
  // 	data.append('city', payload.city);
  // }
  // if (payload.lat !== '') {
  // 	data.append('lat', payload.lat.toFixed(12));
  // }
  // if (payload.lng !== '') {
  // 	data.append('lng', payload.lng.toFixed(12));
  // }
  // if (payload.areaCode !== '') {
  // 	data.append('area_code', payload.areaCode);
  // }
  // data.append('token', WRITE_KEY);
  // data.append('token_id', TOKEN_ID)
  // const requestOptions = {
  // 	url: `${ROOT_URL}/portal/register-company.php`,
  // 	method: 'POST',
  // 	headers: {
  // 		'X-CORS-Access-Key': 'herp-derp',
  // 	},
  // 	data,
  // };
  // return await axios(requestOptions);
}
export async function changePassword(payload) {
  return await axios.patch(`/me/password`, payload, getConfig());
}
export async function updateProfile(payload) {
  return await axios.patch(`/me`, payload, getConfig());
}

export async function getCategories(type = 0) {
  return await axios.get(`/categories?type=${type}`, getConfig());
}

export async function getProducts(payload) {
  let params = '';
  if (payload && payload.category && payload.category !== 0) {
    params = `&category=${payload.category}`;
  }
  const page = payload && payload.page ? payload.page : '';
  return await axios.get(`/products?page=${page}${params}`);
}

export async function getProductDetail(payload) {
  return await axios.get(`/products/${payload.id}`, getConfig());
}
export async function getInventoryDetail(payload) {
  return await axios.get(`/additional-items/${payload.id}`, getConfig());
}
export async function getItems() {
  return await axios.get(`/items`, getConfig());
}
export async function getItemDetail(payload) {
  return await axios.get(`/items/${payload.id}`, getConfig());
}

export async function addProduct(payload) {
  return await axios.post(`/products`, payload, getConfig());
}
export async function addInventory(payload) {
  return await axios.post(`/additional-items`, payload, getConfig());
}
export async function addItem(payload) {
  return await axios.post('/items', payload, getConfig());
}
export async function updateItem(payload) {
  return await axios.patch(`/items/${payload.id}`, payload, getConfig());
}
export async function deleteItem(payload) {
  return await axios.delete(`/items/${payload.id}`, getConfig());
}

export async function getSellerProducts(payload) {
  let params = '';
  if (payload && payload.category && payload.category !== 0) {
    params = `&category=${payload.category}`;
  }
  const page = payload && payload.page ? payload.page : '';
  return await axios.get(`/products?page=${page}${params}`, getConfig());
}
export async function getSellerInventory(payload) {
  const page = payload && payload.page ? payload.page : '';
  const type = payload && payload.type ? payload.type : 0;
  return await axios.get(
    `/additional-items?page=${page}&type=${type}`,
    getConfig(),
  );
}

export async function sellerAddProduct(payload) {
  return await axios.post(`/products`, payload, getConfig());
}
export async function getCompanyProductDetail(payload) {
  // const data = new FormData();
  // data.append('token', payload.token);
  // data.append('token_id', payload.tokenId)
  // data.append('product_id', payload.product_id)
  // const requestOptions = {
  // 	url: `${ROOT_URL}/portal/company/get-product-details.php`,
  // 	method: 'POST',
  // 	headers: {
  // 		'X-CORS-Access-Key': 'herp-derp',
  // 	},
  // 	data,
  // };
  // try {
  // 	const response = await axios(requestOptions)
  // 	const newData = response.data || null;
  // 	return newData;
  // } catch (error) {
  // 	console.log(error);
  // }
}

export async function updateProduct(payload) {
  return await axios.patch(`/products/${payload.id}`, payload, getConfig());
}
export async function deleteProduct(payload) {
  return await axios.delete(`/products/${payload.id}`, getConfig());
}
export async function updateInventory(payload) {
  return await axios.patch(
    `/additional-items/${payload.id}`,
    payload,
    getConfig(),
  );
}
export async function deleteInventory(payload) {
  return await axios.delete(`/additional-items/${payload.id}`, getConfig());
}

export async function getTransaction(payload = null) {
  const page = payload && payload.page ? payload.page : '';
  const item = payload && payload.itemId ? payload.itemId : '';
  const startDate = payload && payload.startDate ? payload.startDate : '';
  const endDate = payload && payload.endDate ? payload.endDate : '';
  return await axios.get(
    `/transactions?page=${page}&item=${item}&start=${startDate}&end=${endDate}`,
    getConfig(),
  );
}

export async function getTransactionDetail(payload) {
  return await axios.get(`/transactions/${payload.id}`, getConfig());
}
export async function getTransactionAdditionalItems(payload) {
  return await axios.get(
    `/transactions/${payload.id}/additional-items`,
    getConfig(),
  );
}

export async function createTransaction(payload) {
  return await axios.post(`/transactions`, payload, getConfig());
}
export async function createInvoice(payload) {
  return await axios.post(
    `/transactions/${payload.id}/pdf`,
    payload,
    getConfig(),
  );
}
export async function sendInvoiceEmail(payload) {
  return await axios.post(
    `/transactions/${payload.id}/email`,
    payload,
    getConfig(),
  );
}

export async function addTransactionBid(payload) {
  return await axios.post(
    `/transactions/${payload.id}/bid`,
    payload,
    getConfig(),
  );
}
export async function addTransactionItemDetail(payload) {
  return await axios.patch(
    `/transactions/${payload.id}/items/${payload.itemId}`,
    payload,
    getConfig(),
  );
}
export async function addTransactionAdditionalItems(payload) {
  return await axios.post(
    `/transactions/${payload.id}/additional-items`,
    payload,
    getConfig(),
  );
}
export async function addTransactionAdditionalItemsBatch(payload) {
  return await axios.post(
    `/transactions/${payload.id}/additional-items/batch`,
    payload,
    getConfig(),
  );
}

export async function uploadImage(payload) {
  const data = new FormData();
  data.append('file', payload.file);
  data.append('upload_preset', 'luokki');
  const apiUrl = `https://api.cloudinary.com/v1_1/luokki/upload`;
  return await axios.post(apiUrl, data);
}

export async function getGeoLocation(payload) {
  return await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?&latlng=${payload.lat},${payload.lng}&key=AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
  );
}

export async function getComSeller(payload) {
  // const data = new FormData();
  // data.append('token', payload.token);
  // data.append('token_id', payload.tokenId)
  // const requestOptions = {
  // 	url: `${ROOT_URL}/portal/company/get-sellers.php`,
  // 	method: 'POST',
  // 	headers: {
  // 		'X-CORS-Access-Key': 'herp-derp',
  // 	},
  // 	data,
  // };
  // return await axios(requestOptions);
}
export async function getComSellerProduct(payload) {
  // const data = new FormData();
  // data.append('token', payload.token);
  // data.append('token_id', payload.tokenId)
  // data.append('seller_id', payload.sellerId)
  // if (payload.productId !== '') {
  // 	data.append('product_id', payload.productId)
  // }
  // if (payload.getImage) {
  // 	data.append('get_images', payload.getImage)
  // }
  // if (payload.getProperties) {
  // 	data.append('get_properties', payload.getProperties)
  // }
  // const requestOptions = {
  // 	url: `${ROOT_URL}/portal/company/get-seller-product-list.php`,
  // 	method: 'POST',
  // 	headers: {
  // 		'X-CORS-Access-Key': 'herp-derp',
  // 	},
  // 	data,
  // };
  // return await axios(requestOptions);
}
export async function getComSellerProductDetail(payload) {
  // const data = new FormData();
  // data.append('token', payload.token);
  // data.append('token_id', payload.tokenId)
  // data.append('seller_id', payload.sellerId)
  // data.append('product_id', payload.productId)
  // if (payload.getImage) {
  // 	data.append('get_images', payload.getImage)
  // }
  // if (payload.getProperties) {
  // 	data.append('get_properties', payload.getProperties)
  // }
  // const requestOptions = {
  // 	url: `${ROOT_URL}/portal/company/get-seller-product-details.php`,
  // 	method: 'POST',
  // 	headers: {
  // 		'X-CORS-Access-Key': 'herp-derp',
  // 	},
  // 	data,
  // };
  // return await axios(requestOptions);
}
export async function addProductToComCatalog(payload) {
  // const data = new FormData();
  // data.append('token', payload.token);
  // data.append('token_id', payload.tokenId)
  // data.append('product_owner_id', payload.sellerId)
  // data.append('product_id', payload.productId)
  // if (payload.priceMin) {
  // 	data.append('price_min', payload.priceMin)
  // }
  // if (payload.priceMax) {
  // 	data.append('price_max', payload.priceMax)
  // }
  // if (payload.status) {
  // 	data.append('status', payload.status)
  // }
  // const requestOptions = {
  // 	url: `${ROOT_URL}/portal/company/add-product-to-catalog.php`,
  // 	method: 'POST',
  // 	headers: {
  // 		'X-CORS-Access-Key': 'herp-derp',
  // 	},
  // 	data,
  // };
  // return await axios(requestOptions);
}
export async function getComCatalog(payload) {
  // const data = new FormData();
  // data.append('token', payload.token);
  // data.append('token_id', payload.tokenId)
  // if (payload.productId) {
  // 	data.append('product_id', payload.productId)
  // }
  // if (payload.getImage) {
  // 	data.append('get_images', payload.getImage)
  // }
  // if (payload.getProperties) {
  // 	data.append('product_id', payload.productId)
  // }
  // const requestOptions = {
  // 	url: `${ROOT_URL}/portal/company/get-catalog.php`,
  // 	method: 'POST',
  // 	headers: {
  // 		'X-CORS-Access-Key': 'herp-derp',
  // 	},
  // 	data,
  // };
  // return await axios(requestOptions);
}
