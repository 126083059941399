import React, { useState, useEffect } from 'react';
import { Link, useParams, Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
// import Moment from 'react-moment'
import moment from 'moment';
import Modal from 'react-modal';

import {
  getTransactionDetail,
  addTransactionBid,
  addTransactionAdditionalItemsBatch,
  getSellerInventory,
  sendInvoiceEmail,
} from '../../libs/apis';
import { numberFormat } from '../../libs/utils';
import Loader from '../../components/global/Loader';
import WidgetHorse from '../../components/global/WidgetHorse';

function Quotation() {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  moment.locale(lang);
  const { orderId } = useParams();
  const history = useHistory();
  const initialState = {
    data: null,
    isLoading: true,
    isError: false,
    message: '',
    orderStatus: null,
    isOpen: false,
    action: 0,
    notes: '',
    date: new Date(),
    price: 0,
    quotedPrice: 0,
    isRefreshing: false,
    isDetailOpen: false,
    hideProduct: true,
    hideBuyer: true,
    hideHorse: true,
    qty1: 0,
    items: [],
    works: [],
    isLoadingItem: false,
    isErrorItem: false,
    itemQty: [],
    worksQty: [],
    isLoadingWorks: false,
    isErrorWorks: false,
    discountItem: 0,
    vatItem: 24,
    discountWork: 0,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    data,
    isLoading,
    isOpen,
    isError,
    message,
    price,
    quotedPrice,
    items,
    works,
    worksQty,
    itemQty,
    discountItem,
    discountWork,
  } = state;

  useEffect(() => {
    const getItems = async () => {
      const payload = {
        id: orderId,
        type: 0,
      };
      await getSellerInventory(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              let itemQty = [];
              resp.data.response.forEach(() => itemQty.push(0));
              setState({ items: resp.data.response, itemQty: itemQty });
            }
          } else {
            setState({
              isErrorItem: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isErrorItem: true });
        })
        .finally(() => setState({ isLoadingItem: false }));
    };
    const getWorks = async () => {
      const payload = {
        id: orderId,
        type: 1,
      };
      await getSellerInventory(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              let worksQty = [];
              resp.data.response.forEach(() => worksQty.push(0));
              setState({ works: resp.data.response, worksQty: worksQty });
            }
          } else {
            setState({
              isErrorWorks: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isErrorWorks: true });
        })
        .finally(() => setState({ isLoadingWorks: false }));
    };
    const getProduct = async () => {
      const payload = {
        id: orderId,
      };
      await getTransactionDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              setState({ data: resp.data.response });
              const lb = resp.data.response.transaction_request;
              setState({
                quotedPrice: lb[0].price,
                date: new Date(lb[0].datetime),
              });
              getItems();
              getWorks();
            }
          } else {
            setState({
              isError: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getProduct();
  }, [orderId]);
  if (!orderId) return <Redirect to="/404" />;
  if (isLoading) return <Loader size="48" center />;
  if (isError)
    return (
      <div className="app-container text-center d-flex flex-direction-column align-items-center justify-content-center w-100 vh-100">
        <div className="content py-5">
          <h3 className="mb-3">{message}</h3>
          <Link to="/" className="btn btn-warning">
            Back To Previous
          </Link>
        </div>
      </div>
    );
  const { transaction_request, transaction_items } = data;

  const subtractQty = (i, type) => {
    if (type === 0) {
      const qtys = itemQty;
      const currentQty = qtys[i];
      const newQty = currentQty > 0 ? currentQty - 1 : 0;
      qtys[i] = newQty;
      setState({ itemQty: qtys });
    } else if (type === 1) {
      const qtys = worksQty;
      const currentQty = qtys[i];
      const newQty = currentQty > 0 ? currentQty - 1 : 0;
      qtys[i] = newQty;
      setState({ worksQty: qtys });
    }
  };
  const addQty = (i, type) => {
    if (type === 0) {
      const qtys = itemQty;
      qtys[i] += 1;
      setState({ itemQty: qtys });
    } else if (type === 1) {
      const qtys = worksQty;
      qtys[i] += 1;
      setState({ worksQty: qtys });
    }
  };
  const changeQty = (i, val, type) => {
    if (type === 0) {
      const qtys = itemQty;
      qtys[i] = val;
      setState({ itemQty: qtys });
    } else if (type === 1) {
      const qtys = worksQty;
      qtys[i] = val;
      setState({ worksQty: qtys });
    }
  };

  let estPrice = price;
  items.forEach((item, index) => {
    const eachItemPrice = item.price * itemQty[index];
    let discountItemPrice = 0;
    const vatItem = item.vat;
    if (discountItem > 0 && eachItemPrice > 0) {
      discountItemPrice = eachItemPrice * (discountItem / 100);
    }
    let vatItemPrice = 0;
    if (vatItem > 0 && eachItemPrice > 0) {
      vatItemPrice = (eachItemPrice - discountItemPrice) * (vatItem / 100);
    }
    estPrice += eachItemPrice - discountItemPrice + vatItemPrice;
  });
  works.forEach((work, index) => {
    // estPrice += work.price * worksQty[index];
    const eachWorkPrice = work.price * worksQty[index];
    let discountWorkPrice = 0;
    const vatWork = work.vat;
    if (discountWork > 0 && eachWorkPrice > 0) {
      discountWorkPrice = (eachWorkPrice * discountWork) / 100;
    }
    let vatWorkPrice = 0;
    if (vatWork > 0 && eachWorkPrice > 0) {
      vatWorkPrice = ((eachWorkPrice - discountWorkPrice) * vatWork) / 100;
    }
    estPrice += eachWorkPrice - discountWorkPrice + vatWorkPrice;
  });

  const handleSubmit = async () => {
    setState({ isSubmit: true, isSubmitError: false });
    let additionalItems = [];
    items.forEach((item, index) => {
      const price = item.price * itemQty[index];
      if (price > 0) {
        const newItem = {
          additional_item_id: item.id,
          price: item.price,
          quantity: itemQty[index],
          discount: parseFloat(discountItem),
          vat: parseFloat(item.vat),
        };
        additionalItems.push(newItem);
      }
    });
    works.forEach((work, index) => {
      const price = work.price * worksQty[index];
      if (price > 0) {
        const newItem = {
          additional_item_id: work.id,
          price: work.price,
          quantity: worksQty[index],
          discount: parseFloat(discountWork),
          vat: parseFloat(work.vat),
        };
        additionalItems.push(newItem);
      }
    });

    // console.log('items', items)
    const payload = {
      id: orderId,
      params: additionalItems,
    };
    await addTransactionAdditionalItemsBatch(payload)
      .then(resp => {
        // console.log('res', resp);
        processOrder(4);
      })
      .finally(() => setState({ isSubmit: false }));
  };
  // const createInvoicePdf = async () => {
  //   setState({ isSubmit: true, isSubmitError: false })
  //   const payload = {
  //     id: orderId,
  //   }
  //   await createInvoice(payload)
  //     .then(resp => {
  //       console.log('res', resp);
  //     })
  //     .finally(() => setState({ isSubmit: false }))
  // }

  const sendEmail = async () => {
    const payload = {
      id: orderId,
    };
    await sendInvoiceEmail(payload).then(resp => {
      if (resp.status === 200) {
        history.push(`/seller/order/${orderId}`);
      }
    });
  };

  const processOrder = async stat => {
    setState({ isBidding: true });
    const lastBid = transaction_request[0];

    const payload = {
      id: orderId,
      price: Math.round(estPrice * 100) / 100,
      datetime: moment(lastBid.datetime).format('YYYY-MM-DD HH:mm:ss'),
      note: lastBid.notes,
      status: stat,
    };

    await addTransactionBid(payload)
      .then(resp => {
        if (resp.status === 200) {
          sendEmail();
        }
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => setState({ isBidding: false }));
  };

  return (
    <div className="app-container">
      <div className="admin-header bg-primary py-3 bg-gradient shadow">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="admin-header-content d-flex justify-content-between align-items-center">
                <Link
                  className="text-light text-decoration-none"
                  to="/seller/order"
                >
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">{t('back_button')}</span>
                </Link>
                <div className="d-flex justify-content-end align-items-center">
                  <h6 className="text-light m-0">
                    {t('invoice_text')} #{orderId}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="alert-warning p-2 px-3 position-sticky sticky-top shadow">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="mb-0 small text-dark fs-14">
            <em>{t('final_price_estimation')}:</em>
          </h6>
          <h6 className="mb-0 small text-success fs-6 ms-3 d-flex align-items-center">
            <strong className="text-success">
              <CountUp
                decimals={2}
                start={parseFloat(numberFormat(price))}
                duration={0.5}
                prefix={`${t('$')} `}
                end={parseFloat(numberFormat(estPrice))}
              />
            </strong>
          </h6>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="content py-3">
              <div className="invoice-container">
                <div className="order-summary mb-3">
                  <div className="card shadow">
                    <div className="card-body p-2">
                      <div className="row g-2 mb-2 align-items-center">
                        <div className="col-3">
                          <img
                            src={data.product.image}
                            alt=""
                            className="img-thumbnail mb-0"
                          />
                        </div>
                        <div className="col-9">
                          <h6 className="mb-1 fw-bold">{data.product.name}</h6>
                          <h6 className="mb-2 small text-muted fw-light">
                            Quoted Price:{' '}
                            <strong className="text-success">
                              {t('$')}{' '}
                              {quotedPrice === 0 ? (
                                <span className="text-muted">
                                  {data.product.price_min} -{' '}
                                  {data.product.price_max}
                                </span>
                              ) : (
                                <span>{quotedPrice}</span>
                              )}
                            </strong>
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex mb-1 flex-wrap">
                        {transaction_items.map(horse => (
                          <div key={horse.id} className="mb-1">
                            <WidgetHorse data={horse} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {items.length > 0 && (
                  <div className="invoice-generator">
                    <div className="card shadow bg-white mb-3">
                      <div className="card-header bg-primary bg-gradient">
                        <h6 className="small mb-0 text-light">
                          <span className="fa fa-briefcase me-2"></span>
                          {t('item_label')}
                        </h6>
                      </div>
                      <div className="card-body p-0">
                        {items.map((i, index) => {
                          return (
                            <div
                              className={`spare-part p-2 ${
                                index > 0 ? 'border-top' : ''
                              }`}
                              key={index}
                            >
                              <div className="row gx-3 align-items-center">
                                <div className="col-4">
                                  <h6 className=" fs-14 mb-1">
                                    {i.name}
                                    {i.unit && (
                                      <span className="text-muted fw-normal">
                                        <br />/ <em>{i.unit}</em>
                                      </span>
                                    )}
                                  </h6>
                                  <h6 className="mb-0 small">
                                    {t('$')}{' '}
                                    <strong className="text-primary">
                                      {i.price}
                                    </strong>{' '}
                                    <span className="text-muted">
                                      ({t('exc_vat_label')})
                                    </span>
                                  </h6>
                                  <h6 className="mb-1 small">
                                    {t('$')}{' '}
                                    <strong className="text-primary">
                                      {i.vat > 0
                                        ? i.price +
                                          Math.floor(
                                            i.price * (i.vat / 100) * 100,
                                          ) /
                                            100
                                        : i.price}
                                    </strong>{' '}
                                    <span className="text-muted">
                                      ({t('inc_vat_label')})
                                    </span>
                                  </h6>
                                  <h6 className="text-muted fs-12 mb-0">
                                    ({t('inventory_vat_label')} {i.vat}%)
                                  </h6>
                                </div>
                                <div className="col-5">
                                  <div className="text-center mb-0">
                                    {/* <label htmlFor="a" className="fs-12 mb-1 fw-bold">{t('qty')}:</label> */}

                                    <div className="input-group input-group-sm flex-nowrap">
                                      <button
                                        className="btn btn-outline-danger"
                                        onClick={() => subtractQty(index, 0)}
                                      >
                                        <span className="fa fa-minus"></span>
                                      </button>
                                      <input
                                        type="number"
                                        id={`qty-${index}`}
                                        className="text-center form-control "
                                        min="0"
                                        placeholder="0"
                                        value={itemQty[index]}
                                        style={{ minWidth: '42px' }}
                                        onChange={e =>
                                          changeQty(index, e.target.value, 0)
                                        }
                                      />
                                      <button
                                        className="btn btn-outline-success "
                                        onClick={() => addQty(index, 0)}
                                      >
                                        <span className="fa fa-plus"></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <img
                                    className="rounded shadow-sm"
                                    src={i.image}
                                    alt={i.name}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="card-footer p-2">
                        <div className="row g-2 ">
                          <div className="col-4">
                            {/* <h6 className="mb-0 small">Discount & Tax for Items</h6> */}
                          </div>
                          <div className="col-8">
                            <div className="input-group input-group-sm align-items-center mb-0">
                              <span className="input-group-text bg-gradient justify-content-end w-50 text-success fw-bold">
                                {t('discount_label')}
                              </span>
                              <input
                                type="number"
                                className="form-control text-center"
                                placeholder="0"
                                min="0"
                                max="100"
                                pattern="\d*"
                                value={discountItem}
                                onChange={e => {
                                  if (e.target.value > 100) {
                                    setState({ discountItem: 100 });
                                  } else {
                                    setState({ discountItem: e.target.value });
                                  }
                                }}
                              />
                              <span className="input-group-text ">%</span>
                            </div>

                            {/* <div className="input-group input-group-sm align-items-center">
                              <span className="input-group-text justify-content-end w-50 text-dark fw-bold">
                                {t('vat_label')}
                              </span>
                              <input
                                type="number"
                                className="form-control text-center"
                                placeholder="24"
                                min="0"
                                max="100"
                                pattern="\d*"
                                value={vatItem}
                                onChange={e => {
                                  if (e.target.value > 100) {
                                    setState({ vatItem: 100 });
                                  } else {
                                    setState({ vatItem: e.target.value });
                                  }
                                }}
                              />
                              <span className="input-group-text">%</span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {works.length > 0 && (
                  <div className="invoice-generator">
                    <div className="card shadow bg-white mb-3">
                      <div className="card-header bg-primary bg-gradient">
                        <h6 className="small mb-0 text-light">
                          <span className="fa fa-wrench me-2"></span>
                          {t('work_label')}
                        </h6>
                      </div>
                      <div className="card-body p-0">
                        {works.map((i, index) => {
                          return (
                            <div
                              className={`spare-part p-2 ${
                                index > 0 ? 'border-top' : ''
                              }`}
                              key={index}
                            >
                              <div className="row gx-3 align-items-center">
                                <div className="col-4">
                                  <h6 className=" fs-14 mb-1">
                                    {i.name}
                                    {i.unit && (
                                      <span className="text-muted fw-normal">
                                        <br />/ <em>{i.unit}</em>
                                      </span>
                                    )}
                                  </h6>
                                  <h6 className="mb-0 fs-12">
                                    {t('$')}{' '}
                                    <strong className="text-primary">
                                      {i.price}
                                    </strong>{' '}
                                    <span className="text-muted">
                                      ({t('exc_vat_label')})
                                    </span>
                                  </h6>
                                  <h6 className="mb-1 fs-12">
                                    {t('$')}{' '}
                                    <strong className="text-primary">
                                      {i.vat > 0
                                        ? i.price +
                                          Math.floor(
                                            i.price * (i.vat / 100) * 100,
                                          ) /
                                            100
                                        : i.price}
                                    </strong>{' '}
                                    <span className="text-muted">
                                      ({t('inc_vat_label')})
                                    </span>
                                  </h6>
                                  <h6 className="text-muted fs-12 mb-0">
                                    ({t('inventory_vat_label')} {i.vat}%)
                                  </h6>
                                </div>
                                <div className="col-5">
                                  <div className="text-center mb-0">
                                    {/* <label htmlFor="a" className="fs-12 mb-1 fw-bold">{t('qty')}:</label> */}

                                    <div className="input-group flex-nowrap">
                                      <button
                                        className="btn btn-sm btn-outline-danger"
                                        onClick={() => subtractQty(index, 1)}
                                      >
                                        <span className="fa fa-minus"></span>
                                      </button>
                                      <input
                                        type="number"
                                        id={`work-qty-${index}`}
                                        className="text-center form-control form-control-sm"
                                        min="0"
                                        placeholder="0"
                                        value={worksQty[index]}
                                        style={{ minWidth: '42px' }}
                                        onChange={e =>
                                          changeQty(index, e.target.value, 1)
                                        }
                                      />
                                      <button
                                        className="btn btn-outline-success btn-sm"
                                        onClick={() => addQty(index, 1)}
                                      >
                                        <span className="fa fa-plus"></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <img
                                    className="rounded shadow-sm"
                                    src={i.image}
                                    alt={i.name}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="card-footer p-2">
                        <div className="row g-2 ">
                          <div className="col-4">
                            {/* <h6 className="mb-0 small">Discount & Tax for Items</h6> */}
                          </div>
                          <div className="col-8">
                            <div className="input-group input-group-sm align-items-center mb-0">
                              <span className="input-group-text bg-gradient justify-content-end w-50 text-success fw-bold">
                                {t('discount_label')}
                              </span>
                              <input
                                type="number"
                                className="form-control text-center"
                                placeholder="0"
                                min="0"
                                max="100"
                                pattern="\d*"
                                value={discountWork}
                                onChange={e => {
                                  if (e.target.value > 100) {
                                    setState({ discountWork: 100 });
                                  } else {
                                    setState({ discountWork: e.target.value });
                                  }
                                }}
                              />
                              <span className="input-group-text ">%</span>
                            </div>

                            {/* <div className="input-group input-group-sm align-items-center">
                              <span className="input-group-text justify-content-end w-50 text-dark fw-bold">
                                {t('vat_label')}
                              </span>
                              <input
                                type="number"
                                className="form-control text-center"
                                placeholder="24"
                                min="0"
                                max="100"
                                pattern="\d*"
                                value={vatWork}
                                onChange={e => {
                                  if (e.target.value > 100) {
                                    setState({ vatWork: 100 });
                                  } else {
                                    setState({ vatWork: e.target.value });
                                  }
                                }}
                              />
                              <span className="input-group-text">%</span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="invoice-summary">
                  <div className="card bg-white mb-3 shadow">
                    <div className="card-header bg-secondary bg-gradient">
                      <h6 className="mb-0 text-light">
                        {t('invoice_summary')}
                      </h6>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-container">
                        <table className="table mb-0 px-3 fs-12">
                          <thead className="table-light">
                            <tr>
                              {/* <th className="text-center">{t('invoice_no')}</th> */}
                              <th className="w-25">{t('invoice_items')}</th>
                              <th className="text-center lh-1">
                                {t('unit_price')}
                                <br />
                                <span className="fs-10 text-nowrap text-muted">
                                  (0% {t('vat_label')})
                                </span>
                              </th>
                              <th className="text-center">{t('qty')}</th>
                              <th className="text-end lh-1">
                                {t('invoice_price')}
                                <br />
                                <span className="fs-10 text-nowrap text-muted">
                                  (0% {t('vat_label')})
                                </span>
                              </th>
                              <th className="text-end lh-1">
                                {t('invoice_subtotal')}
                                <br />
                                <span className="fs-10 text-muted">
                                  ({t('invoice_subtotal_info')})
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr className="fw-bold fs-6">
                              <td className="text-center">1</td>
                              <td>{data.product.name}</td>
                              <td className="text-center ">1</td>
                              <td className="text-end text-primary">{transaction_request[0].price}</td>
                            </tr> */}
                            {itemQty.length > 0 &&
                              itemQty.map((ai, idx) => {
                                const item = items[idx];
                                if (ai === 0) return null;

                                const subtotal = item.price * ai;
                                const totalDiscount =
                                  subtotal - subtotal * (discountItem / 100);
                                const total =
                                  totalDiscount +
                                  totalDiscount * (item.vat / 100);

                                return (
                                  <tr key={idx}>
                                    <td className="lh-1 fw-bold">
                                      {item.name}
                                      <span className=" fs-10">
                                        &nbsp; ({t('vat_label')}: {item.vat}%)
                                      </span>
                                      {item.unit && (
                                        <span className="text-muted fs-12 fw-normal opacity-50">
                                          <br />/ <em>{item.unit}</em>
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-center fw-bold">
                                      {t('$')}
                                      {numberFormat(item.price)}
                                    </td>
                                    <td className="text-center">{ai}</td>
                                    <td className="text-end fw-bold">
                                      {t('$')}
                                      {numberFormat(subtotal)}
                                    </td>
                                    <td className="text-end text-primary fw-bold">
                                      {t('$')}
                                      {numberFormat(total)}
                                    </td>
                                  </tr>
                                );
                              })}
                            {worksQty.length > 0 &&
                              worksQty.map((ai, idx) => {
                                const item = works[idx];
                                if (ai === 0) return null;

                                const subtotal = item.price * ai;
                                const totalDiscount =
                                  subtotal - subtotal * (discountItem / 100);
                                const total =
                                  totalDiscount +
                                  totalDiscount * (item.vat / 100);

                                return (
                                  <tr key={idx}>
                                    <td className="lh-1 fw-bold">
                                      {item.name}
                                      <span className=" fs-10">
                                        &nbsp; ({t('vat_label')}: {item.vat}%)
                                      </span>
                                      {item.unit && (
                                        <span className="text-muted fw-normal opacity-50">
                                          <br />
                                          <em>{item.unit}</em>
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-center fw-bold">
                                      {t('$')}
                                      {numberFormat(item.price)}
                                    </td>
                                    <td className="text-center">{ai}</td>
                                    <td className="text-end fw-bold">
                                      {t('$')}
                                      {numberFormat(subtotal)}
                                    </td>
                                    <td className="text-end text-primary fw-bold">
                                      {t('$')}
                                      {numberFormat(total)}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                          <tfoot className="">
                            <tr>
                              <th colSpan="4" className="text-end">
                                <h6 className="small mb-0 fw-bold">
                                  {t('invoice_total')}
                                </h6>
                              </th>
                              <th className="text-end ">
                                <h6 className="mb-0 fw-bold text-success">
                                  <CountUp
                                    decimals={2}
                                    start={parseFloat(numberFormat(price))}
                                    duration={0.5}
                                    prefix={`${t('$')} `}
                                    end={parseFloat(numberFormat(estPrice))}
                                  />
                                </h6>
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="card-footer border-0 py-3">
                      <div className="btn-container">
                        <button
                          className="btn w-100 btn-primary btn-sm py-2"
                          onClick={() => setState({ isOpen: true })}
                        >
                          <span className="fa fa-send me-2"></span>
                          {t('send_final_invoice')}
                        </button>
                        {/* <button className="btn w-100 btn-sm py-2" onClick={createInvoicePdf}>{t('create_invoice')}</button> */}

                        <Modal
                          isOpen={isOpen}
                          onRequestClose={() => setState({ isOpen: false })}
                          contentLabel="Modal"
                          className="reveal small p-3 py-4 text-center"
                          ariaHideApp={false}
                        >
                          <h6 className="mt-3 text-warning">
                            <span className="fa fa-warning me-1"></span>
                            {t('invoice_warning_heading')}
                          </h6>
                          <div className="table-container">
                            <table className="table-bordered text-start table table-sm fs-10 mb-3">
                              <thead className="table-light">
                                <tr>
                                  {/* <th className="text-center">{t('invoice_no')}</th> */}
                                  <th className="w-50">{t('invoice_items')}</th>
                                  <th className="text-center lh-1">
                                    {t('unit_price')}
                                    <br />
                                    <span className="fs-10 text-nowrap text-muted">
                                      (0% {t('vat_label')})
                                    </span>
                                  </th>
                                  <th className="text-center">{t('qty')}</th>
                                  <th className="text-end lh-1">
                                    {t('invoice_price')}
                                    <br />
                                    <span className="fs-10 text-nowrap text-muted">
                                      (0% {t('vat_label')})
                                    </span>
                                  </th>
                                  <th className="text-end lh-1">
                                    {t('invoice_subtotal')}
                                    <br />
                                    <span className="fs-10 text-muted">
                                      ({t('invoice_subtotal_info')})
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* <tr className="fw-bold fs-6">
                              <td className="text-center">1</td>
                              <td>{data.product.name}</td>
                              <td className="text-center ">1</td>
                              <td className="text-end text-primary">{transaction_request[0].price}</td>
                            </tr> */}
                                {itemQty.length > 0 &&
                                  itemQty.map((ai, idx) => {
                                    const item = items[idx];
                                    if (ai === 0) return null;

                                    const subtotal = item.price * ai;
                                    const totalDiscount =
                                      subtotal -
                                      subtotal * (discountItem / 100);
                                    const total =
                                      totalDiscount +
                                      totalDiscount * (item.vat / 100);

                                    return (
                                      <tr key={idx}>
                                        <td className="lh-1 fw-bold">
                                          {item.name}
                                          <span className=" fs-10">
                                            &nbsp; ({t('vat_label')}: {item.vat}
                                            %)
                                          </span>
                                          {item.unit && (
                                            <span className="text-muted fw-normal opacity-50">
                                              <br />
                                              <em>{item.unit}</em>
                                            </span>
                                          )}
                                        </td>
                                        <td className="text-center fw-bold">
                                          {t('$')}
                                          {numberFormat(item.price)}
                                        </td>
                                        <td className="text-center">{ai}</td>
                                        <td className="text-end fw-bold">
                                          {t('$')}
                                          {numberFormat(subtotal)}
                                        </td>
                                        <td className="text-end text-primary fw-bold">
                                          {t('$')}
                                          {numberFormat(total)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                {worksQty.length > 0 &&
                                  worksQty.map((ai, idx) => {
                                    const item = works[idx];
                                    if (ai === 0) return null;

                                    const subtotal = item.price * ai;
                                    const totalDiscount =
                                      subtotal -
                                      subtotal * (discountItem / 100);
                                    const total =
                                      totalDiscount +
                                      totalDiscount * (item.vat / 100);

                                    return (
                                      <tr key={idx}>
                                        <td className="lh-1 fw-bold">
                                          {item.name}
                                          <span className=" fs-10">
                                            &nbsp; ({t('vat_label')}: {item.vat}
                                            %)
                                          </span>
                                          {item.unit && (
                                            <span className="text-muted fw-normal opacity-50">
                                              <br />
                                              <em>{item.unit}</em>
                                            </span>
                                          )}
                                        </td>
                                        <td className="text-center fw-bold">
                                          {t('$')}
                                          {numberFormat(item.price)}
                                        </td>
                                        <td className="text-center">{ai}</td>
                                        <td className="text-end fw-bold">
                                          {t('$')}
                                          {numberFormat(subtotal)}
                                        </td>
                                        <td className="text-end text-primary fw-bold">
                                          {t('$')}
                                          {numberFormat(total)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                              <tfoot className="">
                                <tr>
                                  <th colSpan="4" className="text-end">
                                    <h6 className="small mb-0 fw-bold">
                                      {t('invoice_total')}
                                    </h6>
                                  </th>
                                  <th className="text-end ">
                                    <h6 className="mb-0 fw-bold text-success">
                                      <CountUp
                                        decimals={2}
                                        start={parseFloat(numberFormat(price))}
                                        duration={0.5}
                                        prefix={`${t('$')} `}
                                        end={parseFloat(numberFormat(estPrice))}
                                      />
                                    </h6>
                                  </th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <p>{t('invoice_warning_text')}</p>
                          <div className="btn-container d-flex justify-content-center">
                            <button
                              className="btn w-50 lh-1 py-1 btn-outline-secondary"
                              onClick={() => setState({ isOpen: false })}
                            >
                              {t('cancel_button')}
                            </button>
                            <button
                              className="btn w-75 lh-1 py-1 btn-primary ms-2"
                              onClick={handleSubmit}
                            >
                              {t('finalize_invoice')}
                            </button>
                          </div>
                          <button
                            className="btn btn-close close-reveal"
                            onClick={() => setState({ isOpen: false })}
                          >
                            &times;
                          </button>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}

export default Quotation;
