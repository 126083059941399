import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/global/Loader';

import { getCategories, addProduct, uploadImage } from '../../libs/apis';

function SellerIndex() {
  const { t } = useTranslation();
  const history = useHistory();
  const initialState = {
    name: '',
    priceMin: '',
    priceMax: '',
    description: '',
    photo: null,
    uploading: false,
    errorUpload: false,
    loading: false,
    error: false,
    categories: [],
    selectedCategory: null,
    errorMessage: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    name,
    priceMin,
    priceMax,
    description,
    loading,
    photo,
    uploading,
    errorUpload,
    error,
    categories,
    selectedCategory,
    errorMessage,
  } = state;

  const handleSubmit = async ev => {
    ev.preventDefault();
    if (!photo) {
      setState({
        error: true,
        errorMessage: 'Please upload correct image',
        loading: false,
      });
    } else {
      setState({ loading: true, error: false, errorMessage: '' });
      const payload = {
        name,
        description,
        price_min: priceMin,
        price_max: priceMax,
        category_id: selectedCategory,
        image: photo,
      };
      await addProduct(payload)
        .then(resp => {
          if (resp.status === 201 || resp.status === 200) {
            history.push('/seller/product');
          } else {
            setState({ error: true, errorMessage: resp.data.error.message });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ error: true, errorMessage: 'Please upload an image' });
        })
        .finally(() => {
          setState({ loading: false });
        });
    }
  };

  const onDropImage = async e => {
    const file = e.target.files;
    setState({ uploading: true, errorUpload: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ photo: res.data.secure_url });
        }
      })
      .catch(err => {
        setState({ errorUpload: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploading: false });
      });
  };

  useEffect(() => {
    const getCats = async () => {
      await getCategories().then(resp => {
        if (resp.status === 200) {
          if (resp.data.response.length > 0) {
            setState({
              categories: resp.data.response,
              selectedCategory: resp.data.response[0].id,
            });
          }
        }
      });
    };
    getCats();
  }, []);

  return (
    <div className="admin-container">
      <div className="admin-header bg-primary bg-gradient py-3 sticky-top shadow-sm">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="admin-header-content d-flex justify-content-between align-items-center">
                <Link
                  to="/seller/product"
                  className=" text-light text-decoration-none"
                >
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">{t('back_button')}</span>
                </Link>
                <h6 className="text-light m-0">{t('seller_admin')}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container py-4 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <div className="d-flex justify-content-between align-items-center">
                  <h4>{t('add_new_product_heading')}</h4>
                </div>
                <p>
                  <span className="text-danger">*</span>{' '}
                  {t('warning_mandatory_text')}
                </p>
                <hr />

                <div className="form-container">
                  {error && (
                    <div className="alert alert-danger fade show" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <form onSubmit={handleSubmit}>
                    <div className="form-floating mb-3">
                      <select
                        className="form-select "
                        name="category"
                        onChange={e =>
                          setState({ selectedCategory: e.target.value })
                        }
                      >
                        <option value="0" disabled>
                          {t('category_select')}
                        </option>
                        {categories.length > 0 &&
                          categories.map((cat, index) => (
                            <option key={cat.id + index} value={cat.id}>
                              {t(cat.name.toLowerCase().replace(' ', '_'))}
                            </option>
                          ))}
                      </select>
                      <label htmlFor="category">
                        {t('select_category')}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-group">
                      {/* <ImageUploader
                        singleImage={true}
                        withIcon={true}
                        buttonText='Choose images'
                        onChange={onDrop}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                      />
                      <input type="hidden" name="photo" value={photo} /> */}
                      {errorUpload && (
                        <div className="alert alert-danger text-center">
                          <p className="m-0">{t('failed_to_upload')}</p>
                        </div>
                      )}
                      {uploading && <Loader size={24} />}
                      <div className="image-uploader text-center">
                        {photo && (
                          <div className="mb-3 d-flex justify-content-center">
                            <img
                              src={photo}
                              alt="upload"
                              className="img-thumbnail"
                            />
                          </div>
                        )}

                        <label
                          htmlFor="img"
                          className="position-relative w-100 d-flex align-items-center mb-3 btn btn-primary"
                        >
                          <span className="fa fa-image me-3"></span>
                          {photo ? (
                            <small>
                              {t('change_product_image')}
                              <span className="text-danger">*</span>
                            </small>
                          ) : (
                            <small>
                              {t('upload_product_image')}
                              <span className="text-danger">*</span>
                            </small>
                          )}
                          <input
                            type="file"
                            id="img"
                            required
                            onChange={onDropImage}
                            className="fs-6 small"
                            style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                              opacity: 0,
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        value={name}
                        onChange={e => setState({ name: e.target.value })}
                        disabled={loading}
                        required
                        placeholder={t('product_name_label')}
                      />
                      <label htmlFor="name">
                        {t('product_name_label')}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            type="number"
                            id="priceMin"
                            value={priceMin}
                            required
                            onChange={e =>
                              setState({ priceMin: e.target.value })
                            }
                            disabled={loading}
                            placeholder={t('price_min_label')}
                          />
                          <label htmlFor="priceMin">
                            {t('price_min_label')}
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            type="number"
                            id="priceMax"
                            required
                            value={priceMax}
                            onChange={e =>
                              setState({ priceMax: e.target.value })
                            }
                            disabled={loading}
                            placeholder={t('price_max_label')}
                          />
                          <label htmlFor="priceMax">
                            {t('price_max_label')}
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-floating mb-3">
                      <textarea
                        className="form-control"
                        name="description"
                        id="description"
                        rows="3"
                        required
                        placeholder={t('product_description_label')}
                        value={description}
                        onChange={e =>
                          setState({ description: e.target.value })
                        }
                        style={{ height: '120px' }}
                      ></textarea>
                      <label htmlFor="description">
                        {t('product_description_label')}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary w-100 mb-3"
                    >
                      {t('add_product_button')}{' '}
                      <span className="ms-2 fa fa-chevron-right"></span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerIndex;
