import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { deleteInventory } from '../../libs/apis';

export default function Inventory({ data, index = 0 }) {
  const { t } = useTranslation();
  const { id, name, image, price, unit, vat } = data;
  const [open, setOpen] = useState(false);
  const initialState = {
    isDeleted: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { isDeleted } = state;

  const handleDelete = async () => {
    setState({ isDeleting: true });
    const payload = {
      id,
    };
    await deleteInventory(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ isDeleted: true });
        }
      })
      .finally(() => setState({ isDeleting: false }));
  };
  if (isDeleted) return null;

  const includingVat =
    vat > 0 ? price + Math.floor(price * (vat / 100) * 100) / 100 : price;

  return (
    <div
      className={`inventory-item ${index > 0 ? 'border-top' : ''} p-2 ${
        index % 2 === 0 ? '' : 'bg-light'
      }`}
    >
      <div className="row g-2 align-items-center">
        <div className="col-2">
          <Link to={`/seller/inventory/${id}/edit`}>
            <img src={image} className="rounded shadow-sm border" alt={name} />
          </Link>
        </div>
        <div className="col-10">
          <div className="d-flex align-items-center justify-content-between">
            <div className="heading-container" style={{ flex: 1 }}>
              <h6 className="mb-2 small fw-bold">
                {name}
                {/* <span className="fs-10">
                  <br />({t('vat_label')}: {vat}%)
                </span> */}
                {/* {unit && (
                  <span className="text-muted fw-normal">
                    <br />
                    <em>{unit}</em>
                  </span>
                )} */}
              </h6>
              {/* <h6 className="small mb-0 text-primary fw-bold">
                {t('$')} {price}
              </h6> */}
              <h6 className="small mb-0 font-ssp">
                <span>
                  <span className="text-primary fw-bold">
                    {t('$')}
                    {price}
                  </span>{' '}
                  / {unit}
                </span>{' '}
                <span className="text-muted">
                  (
                  <Trans i18nKey="exc_price_label" vat={vat}>
                    Excluding {{ vat }}% VAT
                  </Trans>
                  )
                </span>
              </h6>
              <h6 className="small mb-0 font-ssp">
                <span>
                  <span className="text-primary fw-bold">
                    {t('$')}
                    {includingVat}
                  </span>{' '}
                  / {unit}
                </span>{' '}
                <span className="text-muted">
                  (
                  <Trans i18nKey="inc_price_label" vat={vat}>
                    Including {{ vat }}% VAT
                  </Trans>
                  )
                </span>
              </h6>
            </div>
          </div>

          <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            contentLabel="Modal"
            className="reveal small p-3"
            ariaHideApp={false}
          >
            <div className="modal-container">
              <h6 className="mb-4 text-center">
                <span className="fa text-warning fa-warning me-1"></span>Are you
                sure to remove this item?
              </h6>
              <div className="btn-container d-flex justify-content-center align-items-center">
                <button className="btn w-50 btn-sm btn-secondary">
                  Cancel
                </button>
                <button
                  className="ms-2 w-50 btn btn-sm btn-outline-danger"
                  onClick={handleDelete}
                >
                  Remove
                </button>
              </div>
            </div>
            <button
              className="btn btn-close close-reveal"
              onClick={() => setOpen(false)}
            >
              &times;
            </button>
          </Modal>
        </div>
      </div>
      <div className="button-group align-items-center d-flex pt-3 ">
        <Link
          to={`/seller/inventory/${id}/edit`}
          className="btn btn-secondary p-1 btn-sm lh-1 fs-12 font-ssp"
        >
          <span className="fa fa-edit me-1"></span>
          {t('edit_text')}
        </Link>
        <button
          className="ms-2 btn btn-danger p-1 btn-sm lh-1 fs-12 font-ssp"
          onClick={() => setOpen(true)}
        >
          <span className="fa fa-trash me-1"></span>
          {t('remove_text')}
        </button>
      </div>
    </div>
  );
}
