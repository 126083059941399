import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuthState } from '../../context';

export default function Dashboard() {
  const history = useHistory();
  const { user } = useAuthState();
  return (
    <div className="admin-container">
      <div className="admin-header bg-success bg-gradient py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="admin-header-content d-flex justify-content-between align-items-center">
                <button
                  className="btn text-light m-0 btn-sm"
                  onClick={() => history.goBack()}
                >
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">Back</span>
                </button>
                <h6 className="text-light m-0">Luokki Admin</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <h5>Welcome, {user}</h5>
                <p>Here are cool things you can do</p>
                <div className="py-3">
                  <div className="row gx-3">
                    <div className="col-6">
                      <Link
                        to="/admin/seller/"
                        className="btn w-100 mb-3 rounded-pill btn-success"
                      >
                        <small>View</small>
                        <br />
                        <strong>Seller</strong>
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="/admin/buyer/"
                        className="btn w-100 mb-3 rounded-pill btn-success"
                      >
                        <small>View</small>
                        <br />
                        <strong>Buyer</strong>
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="/admin/category"
                        className="btn w-100 mb-3 rounded-pill btn-success"
                      >
                        <small>View</small>
                        <br />
                        <strong>Category</strong>
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="/admin/target"
                        className="btn w-100 mb-3 rounded-pill btn-success"
                      >
                        <small>View</small>
                        <br />
                        <strong>Target</strong>
                      </Link>
                    </div>
                  </div>
                </div>
                <h6>All admin functionality:</h6>
                <ul>
                  <li>
                    <Link to="/">Register a Seller</Link>
                  </li>
                  <li>
                    <Link to="/">View/Edit Seller</Link>
                  </li>
                  <li>
                    <Link to="/">Delete a Seller</Link>
                  </li>
                  <li>
                    <Link to="/">Register a Buyer</Link>
                  </li>
                  <li>
                    <Link to="/">View/Edit Buyer</Link>
                  </li>
                  <li>
                    <Link to="/">Delete a Buyer</Link>
                  </li>
                  <li>
                    <Link to="/">Register a Company</Link>
                  </li>
                  <li>
                    <Link to="/">View/Edit Company</Link>
                  </li>
                  <li>
                    <Link to="/">Delete a Company</Link>
                  </li>
                  <li>
                    <Link to="/">Create Product Category</Link>
                  </li>
                  <li>
                    <Link to="/">View/Edit Product Category</Link>
                  </li>
                  <li>
                    <Link to="/">Delete Product Category</Link>
                  </li>
                  <li>
                    <Link to="/">Create Target Category</Link>
                  </li>
                  <li>
                    <Link to="/">View/Edit Target Category</Link>
                  </li>
                  <li>
                    <Link to="/">Delete Target Category</Link>
                  </li>
                  <li>
                    <Link to="/">Create Access Keys (read/write)</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
