import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Page from '../../components/global/Page';
import InternalHeader from '../../components/global/InternalHeader';

function Order() {
  const { t } = useTranslation();
  return (
    <div className="app-container">
      <InternalHeader title={t('notification_page')} />
      <div className="main-content py-3">
        <Page>
          <div className="content text-center py-5">
            <h3 className="mb-5">{t('notification_placeholder_text')}</h3>
            <Link to="/">
              <button className="btn mb-5 w-100 btn-primary">
                {t('go_to_homepage_button')}
              </button>
            </Link>
          </div>
        </Page>
      </div>
    </div>
  );
}

export default Order;
