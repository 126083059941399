import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { useHistory } from 'react-router-dom';

import InternalHeader from '../../components/global/InternalHeader';
import Loader from '../../components/global/Loader';
import Page from '../../components/global/Page';
import { useAuthState, useAuthDispatch, updateUser } from '../../context';
import { updateProfile, getGeoLocation, uploadImage } from '../../libs/apis';
import userImg from '../../assets/images/user.png';

function EditProfile() {
  const userDetails = useAuthState();

  const { user, role, token, tokenId, profile } = userDetails;
  const dispatch = useAuthDispatch();
  const history = useHistory();

  const initialState = {
    name: profile ? (profile.name ? profile.name : '') : '',
    legalId: profile ? (profile.legal_id ? profile.legal_id : '') : '',
    phone: profile ? (profile.phone ? profile.phone : '') : '',
    address: profile ? (profile.address ? profile.address : '') : '',
    city: profile ? (profile.city ? profile.city : '') : '',
    email: profile ? profile.email : '',
    pinCode: profile ? (profile.pincode ? profile.pincode : '') : '',
    homepageUrl: profile
      ? profile.homepage_url
        ? profile.homepage_url.replace('https://', '')
        : ''
      : '',
    lat: profile ? (profile.lat ? parseFloat(profile.lat) : '') : '',
    lng: profile ? (profile.lng ? parseFloat(profile.lng) : '') : '',
    description: profile
      ? profile.description
        ? profile.description
        : ''
      : '',
    loading: false,
    error: false,
    uploading: false,
    errorUpload: false,
    uploadBanner: false,
    errorBannerUpload: false,
    photoUrl: profile ? (profile.logo_url ? profile.logo_url : '') : '',
    bannerUrl: profile ? (profile.banner_url ? profile.banner_url : '') : '',
    newBannerUrl: null,
    zoom: 15,
    message: '',
    center: [],
    geolocationStatus: false,
    draggable: true,
    geocode: [],
    geoAddress: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    name,
    phone,
    email,
    address,
    description,
    lat,
    lng,
    loading,
    error,
    uploading,
    errorUpload,
    message,
    photoUrl,
    zoom,
    geolocationStatus,
    center,
    draggable,
    geocode,
    geoAddress,
    legalId,
    bannerUrl,
    homepageUrl,
    newBannerUrl,
    errorBannerUpload,
  } = state;

  const getGeoLoc = async (lati, long) => {
    await getGeoLocation({ lat: lati, lng: long }).then(res => {
      if (res.status === 200 && res.data) {
        const result = res.data.results;
        const filterResult = result.filter(
          e => e.types.indexOf('street_address') !== -1,
        );
        const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
        if (selectedGeo) {
          const geo = selectedGeo.address_components.reverse();
          const geoAddress = selectedGeo.formatted_address;
          setState({ geocode: geo, geoAddress });
          // console.log('geo', geo)
        }
      }
    });
  };

  useEffect(() => {
    const getInitialGeoLoc = async (lati, long) => {
      await getGeoLocation({ lat: lati, lng: long }).then(res => {
        if (res.status === 200 && res.data) {
          const result = res.data.results;
          const filterResult = result.filter(
            e => e.types.indexOf('street_address') !== -1,
          );
          const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
          if (selectedGeo) {
            const geo = selectedGeo.address_components.reverse();
            const geoAddress = selectedGeo.formatted_address;
            setState({ geocode: geo, geoAddress });
          }
        }
      });
    };
    if (lat === '' && lng === '') {
      navigator.geolocation.getCurrentPosition(
        position => {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          console.log('getCurrentPosition Success ' + latitude + longitude); // logs position correctly
          getInitialGeoLoc(latitude, longitude);
          setState({
            lat: latitude,
            lng: longitude,
            geolocationStatus: true,
            center: [latitude, longitude],
          });
        },
        error => {
          console.error(JSON.stringify(error));
        },
        { enableHighAccuracy: true, timeout: 2000, maximumAge: 1000 },
      );
    } else {
      getInitialGeoLoc(lat, lng);
      setState({ geolocationStatus: true, center: [lat, lng] });
    }
  }, [token, tokenId, lat, lng]);

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };

  const onMarkerInteractionUp = async () => {
    setState({ draggable: true });
    getGeoLoc(lat, lng);
  };

  const onChangeMap = ({ center, zoom }) => {
    setState({
      center: center,
      zoom: zoom,
    });
  };

  const submitForm = async ev => {
    ev.preventDefault();
    setState({ loading: true, error: false });
    let areaCode2 = '',
      city2 = '';
    if (geocode.length > 0) {
      areaCode2 = geocode[0].long_name;
      city2 = geocode[3].long_name;
    }

    const payload = {
      name,
      phone,
      address,
      description,
      lat,
      lng,
      logo_url: photoUrl,
      homepageUrl,
      legalId,
      bannerUrl: newBannerUrl ? newBannerUrl : bannerUrl,
      token,
      tokenId,
      pinCode: areaCode2,
      city: city2,
    };
    await updateProfile(payload).then(resp => {
      if (resp.status === 200) {
        const newProfile = resp.data;
        updateUser(dispatch, newProfile);
        history.replace('/profile');
      }
    });
  };

  const onDropImage = async e => {
    const file = e.target.files;
    setState({ uploading: true, errorUpload: false });
    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ photoUrl: res.data.secure_url });
        }
      })
      .catch(err => {
        setState({ errorUpload: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploading: false });
      });
  };

  const onDropBanner = async e => {
    const file = e.target.files;
    setState({ uploadBanner: true, errorBannerUpload: false });
    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ newBannerUrl: res.data.secure_url });
        }
      })
      .catch(err => {
        setState({ errorBannerUpload: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploadBanner: false });
      });
  };

  return (
    <div className="app-container">
      <InternalHeader title={`Update: ${profile.name ? profile.name : user}`} />
      <div className="main-content pb-5">
        <Page>
          <div className="content py-3 text-break">
            {error && (
              <div className="alert alert-danger fade show" role="alert">
                {message}
              </div>
            )}

            {role === 'company' && (
              <div className="form-group mb-4">
                {errorBannerUpload && (
                  <div className="alert alert-danger text-center">
                    <p className="m-0">
                      Failed to upload banner image, try again.
                    </p>
                  </div>
                )}
                <div className="image-uploader banner text-center">
                  {newBannerUrl ? (
                    <div className="mb-0 text-center">
                      <img src={newBannerUrl} alt="upload" className="" />
                    </div>
                  ) : (
                    <div className="mb-0 text-center">
                      {bannerUrl ? (
                        <img src={bannerUrl} alt="upload" className="" />
                      ) : (
                        <img
                          src="https://via.placeholder.com/800x240"
                          alt="upload"
                          className=""
                        />
                      )}
                    </div>
                  )}

                  <label
                    htmlFor="img"
                    className={`position-relative rounded-0 w-100 d-flex align-items-center mb-3 btn btn-primary`}
                  >
                    <span className="fa fa-image me-3"></span>
                    <small>Change Banner</small>
                    <input
                      type="file"
                      id="img"
                      onChange={onDropBanner}
                      className="fs-6 small"
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        opacity: 0,
                      }}
                    />
                  </label>
                </div>
              </div>
            )}

            <div className="form-group">
              {errorUpload && (
                <div className="alert alert-danger text-center">
                  <p className="m-0">Failed to upload logo image, try again.</p>
                </div>
              )}
              <div className="image-uploader cover-image">
                {photoUrl ? (
                  <div className="mb-3 text-center">
                    <img
                      src={photoUrl}
                      alt="upload"
                      className="shadow img-thumbnail"
                    />
                  </div>
                ) : (
                  <div className="mb-3 text-center">
                    {!uploading ? (
                      <img
                        src={userImg}
                        alt="upload"
                        className=" shadow img-thumbnail"
                      />
                    ) : (
                      <Loader size={24} />
                    )}
                  </div>
                )}

                <label
                  htmlFor="img"
                  className={`position-relative rounded-pill w-100 d-flex align-items-center mb-3 btn btn-primary`}
                >
                  <span className="fa fa-image me-3"></span>
                  {role === 'company' ? (
                    <small>Change Logo</small>
                  ) : (
                    <small>Upload Profile Photo</small>
                  )}

                  <input
                    type="file"
                    id="img"
                    onChange={onDropImage}
                    className="fs-6 small"
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      right: '0',
                      bottom: '0',
                      opacity: 0,
                    }}
                  />
                </label>
              </div>
            </div>
            <form onSubmit={submitForm}>
              {/* <div className="profile-container mb-2">
                <div className="edit-cover-image ">
                  <img src="https://www.lifelearnplatform.com/wp-content/uploads/sites/8/2020/02/jussi.jpg" alt="avatar" width="120" className="shadow border" />
                </div>
              </div> */}
              {photoUrl !== '' && <input type="hidden" value={photoUrl} />}
              <div className="form-floating mb-3">
                <input
                  className="form-control form-control-disabled"
                  type="text"
                  id="username"
                  value={user}
                  readOnly
                  disabled
                />
                <label htmlFor="username">Username</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  className="form-control form-control-disabled"
                  type="email"
                  id="email"
                  value={email}
                  readOnly
                  disabled
                />
                <label htmlFor="email">Email</label>
              </div>
              {role === 'company' ? (
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="text"
                    id="legalName"
                    value={name}
                    onChange={e => setState({ name: e.target.value })}
                    disabled={loading}
                    placeholder="Company Name"
                  />
                  <label htmlFor="legalName">Company Name</label>
                </div>
              ) : (
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="text"
                    id="legalName"
                    value={name}
                    onChange={e => setState({ name: e.target.value })}
                    disabled={loading}
                    placeholder="Legal Name"
                  />
                  <label htmlFor="legalName">Legal Name</label>
                </div>
              )}
              {role === 'company' && (
                <>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="text"
                      id="companyId"
                      value={legalId}
                      onChange={e => setState({ legalId: e.target.value })}
                      disabled={loading}
                      placeholder="Legal ID"
                    />
                    <label htmlFor="companyId">Legal ID</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="text"
                      id="homepageUrl"
                      value={homepageUrl}
                      onChange={e => setState({ homepageUrl: e.target.value })}
                      disabled={loading}
                      placeholder="Homepage URL"
                    />
                    <label htmlFor="homepageUrl">Homepage URL</label>
                  </div>
                </>
              )}

              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="text"
                  id="phone"
                  value={phone}
                  onChange={e => setState({ phone: e.target.value })}
                  disabled={loading}
                  placeholder="Phone Number"
                />
                <label htmlFor="phone">Phone Number</label>
              </div>

              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  name="description"
                  id="description"
                  rows="3"
                  placeholder="Your Bio"
                  value={description}
                  onChange={e => setState({ description: e.target.value })}
                  style={{ height: '120px' }}
                ></textarea>
                <label htmlFor="description">Your Bio</label>
              </div>

              {/* <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="text"
                  id="city"
                  value={city}
                  onChange={(e) => setState({ city: e.target.value })}
                  disabled={loading}
                  placeholder="City" />
                <label htmlFor="city">City</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="text"
                  id="pinCode"
                  value={pinCode}
                  onChange={(e) => setState({ pinCode: e.target.value })}
                  disabled={loading}
                  placeholder="Pin Code" />
                <label htmlFor="pinCode">Pin Code</label>
              </div> */}

              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  name="address"
                  id="address"
                  rows="3"
                  placeholder="Address"
                  value={address}
                  onChange={e => setState({ address: e.target.value })}
                  style={{ height: '120px' }}
                ></textarea>
                <label htmlFor="address">Address</label>
              </div>

              {geolocationStatus && (
                <div
                  className="rounded mb-4 p-3 bg-gradient"
                  style={{ background: '#ddd' }}
                >
                  <label htmlFor="gmap" className="mb-2">
                    Set Your Geo Location
                  </label>

                  <div
                    className="mb-3"
                    style={{ height: '50vh', width: '100%' }}
                  >
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                      }}
                      center={center}
                      zoom={zoom}
                      draggable={draggable}
                      onChange={onChangeMap}
                      onChildMouseDown={onMarkerInteraction}
                      onChildMouseUp={onMarkerInteractionUp}
                      onChildMouseMove={onMarkerInteraction}
                      // onChildClick={() => console.log('child click')}
                      // onClick={() => console.log('mapClick')}
                    >
                      <Marker lat={lat} lng={lng} text={geoAddress} />
                    </GoogleMapReact>
                  </div>
                </div>
              )}

              <button
                type="submit"
                className="btn bg-gradient btn-lg btn-success w-100"
              >
                <span className="me-3 fa fa-save"></span>Save
              </button>
            </form>
          </div>
        </Page>
      </div>
    </div>
  );
}

export default EditProfile;

const Marker = ({ text = null }) => (
  <div
    className="map-marker text-center"
    style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
  >
    <div className="icon">
      <span className="fa fa-map-marker"></span>
    </div>
    {text && (
      <div className="text bg-white  p-2 rounded shadow">
        <p className="m-0">{text}</p>
      </div>
    )}
  </div>
);
