import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../context';

import { getSellerProducts } from '../../libs/apis';
import Product from './Product';

export default function ProductSummary() {
  const { t } = useTranslation();
  const { user } = useAuthState();
  const initialState = {
    data: [],
    isLoading: true,
    isError: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, isLoading } = state;

  useEffect(() => {
    const getProductData = async () => {
      await getSellerProducts()
        .then(resp => {
          if (resp.status === 200) {
            setState({ data: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getProductData();
  }, []);

  if (!user) return null;

  return (
    <div className="product-summary py-3">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="text-dark text-uppercase fw-bold">
          {t('product_list')}
        </h6>
        {data.length > 0 && (
          <Link
            to="/seller/product/new"
            className="btn btn-sm mb-3 rounded-pill btn-primary"
          >
            <span className="fa fa-plus me-1"></span>
            <small>{t('new')}</small> <strong>{t('product')}</strong>
          </Link>
        )}
      </div>

      {data.length > 0 && (
        <div>
          {data.map((p, index) => {
            return <Product data={p} key={p.id} />;
          })}
        </div>
      )}
      {data.length === 0 && !isLoading && (
        <div className="text-center my-3">
          <Link
            to="/seller/product/new"
            className="btn mb-0 rounded-pill btn-warning"
          >
            <span className="fa fa-plus me-2"></span>
            <small>{t('add_your_first')}</small> <strong>{t('product')}</strong>
          </Link>
        </div>
      )}
    </div>
  );
}
