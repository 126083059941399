import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import WidgetOrder from '../../components/global/WidgetOrder';

function Quotation() {
  const { t } = useTranslation();
  const { orderId } = useParams();

  return (
    <div className="app-container">
      <div className="admin-header bg-primary bg-gradient py-3 shadow">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="admin-header-content d-flex justify-content-between align-items-center">
                <Link
                  className="text-light text-decoration-none"
                  to="/seller/order"
                >
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">{t('back_button')}</span>
                </Link>
                <div className="d-flex justify-content-end align-items-center">
                  <h6 className="text-light m-0">
                    {t('order_text')} #{orderId}
                  </h6>
                  {/* <div className="text-muted mx-2">|</div>
                  <Link to={`/seller/order/${orderId}/invoice`} className="btn p-0 lh- mb-0 bg-no-gradient">
                    <span className="fa fa-file-pdf-o mb-1 text-white"></span>
                  </Link>
                  <div className="text-muted mx-2">|</div>
                  <button className="btn p-0 lh- mb-0 bg-no-gradient">
                    <span className="fa fa-send-o mb-1 text-white"></span>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <WidgetOrder orderId={orderId} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quotation;
