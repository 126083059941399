import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

export default function InternalHeader({
  go = null,
  title = 'Luokki',
  children = null,
}) {
  const history = useHistory();
  return (
    <div className="internal-header sticky-top shadow-sm">
      <div className="back-button">
        {go ? (
          <Link to={go}>
            <span className="fa fa-chevron-left"></span>
          </Link>
        ) : (
          <button onClick={() => history.goBack()}>
            <span className="fa fa-chevron-left"></span>
          </button>
        )}
      </div>
      <div className="header-content">
        {children ? (
          <>{children}</>
        ) : (
          <div className="header-title">
            <h2>{title}</h2>
          </div>
        )}
      </div>
      <Helmet>
        <title>{title} | Luokki</title>
      </Helmet>
    </div>
  );
}
