import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuthState } from '../../context';
// import CalendarToday from '../../components/seller/CalendarToday';

function SellerIndex() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, role } = useAuthState();

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');

  return (
    <div className="admin-container">
      <div className="admin-header py-3 bg-primary bg-gradient shadow">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <h1 className="fs-6 text-light mb-0">
                  <span className="fa fa-dashboard me-2"></span>
                  {t('seller_dashboard')}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container py-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content mt-1">
                <div className="heading text-center mb-3">
                  <h3 className="fs-6 fw-bold">👋🏻 Welcome, {user.name}</h3>
                </div>

                <Link
                  to="/seller/schedule"
                  className="btn btn-primary btn-lg w-100"
                >
                  <span className="fa fa-calendar me-2"></span>View My Schedule
                </Link>

                <hr />

                <div className="admin-menu  mb-3">
                  <div className="row gx-3">
                    <div className="col-12">
                      <Link
                        to="/seller/order"
                        className="btn shadow btn-sm btn-outline-primary mb-3 w-100 d-flex justify-content-center align-items-center"
                      >
                        <span className="icon fs-5 me-3">
                          <span className="fa fa-list"></span>
                        </span>
                        <span className="text lh-1 text-left">
                          <span className="small">{t('view_all_text')}</span>
                          <br />{' '}
                          <span className="fw-bold">{t('order_text')}</span>
                        </span>
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="/seller/product"
                        className="btn btn-sm shadow btn-outline-primary mb-3 w-100 d-flex justify-content-center align-items-center"
                      >
                        <span className="icon fs-5 me-3">
                          <span className="fa fa-wrench"></span>
                        </span>
                        <span className="text lh-1 text-left">
                          <span className="small">{t('view_all_text')}</span>
                          <br />{' '}
                          <span className="fw-bold">{t('product_text')}</span>
                        </span>
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="/seller/inventory"
                        className="btn btn-sm shadow btn-outline-primary mb-3 w-100 d-flex justify-content-center align-items-center"
                      >
                        <span className="icon fs-5 me-3">
                          <span className="fa fa-briefcase"></span>
                        </span>
                        <span className="text lh-1 text-left">
                          <span className="small">{t('view_all_text')}</span>
                          <br />{' '}
                          <span className="fw-bold">{t('inventory_text')}</span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}

export default SellerIndex;
