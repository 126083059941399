import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../context';
import { getTransaction } from '../../libs/apis';
import LoadMore from '../global/LoadMore';
import Loader from '../global/Loader';
import BoxOrder from '../global/BoxOrder';

export default function OrderSummary({ data }) {
  const { t } = useTranslation();
  const { user } = useAuthState();
  const initialState = {
    orders: [],
    isLoading: true,
    isError: false,
    message: '',
    current: 1,
    last: 99,
    hasNext: true,
    isLoadMore: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { orders, isLoading, current, hasNext, isLoadMore } = state;

  useEffect(() => {
    const getOrderData = async () => {
      await getTransaction()
        .then(resp => {
          if (resp.status === 200) {
            setState({ orders: resp.data.response });
            const page = resp.data.metadata.pagination.page || null;
            if (page) {
              setState({ current: page.current, last: page.last });
              const hasNextPage = page.current < page.last;
              setState({ hasNext: hasNextPage });
            }
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getOrderData();
  }, [user]);

  const nextPage = async () => {
    setState({ isLoadMore: true });
    const payload = {
      page: current + 1,
    };
    await getTransaction(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ orders: [...orders, ...resp.data.response] });
          const page = resp.data.metadata.pagination.page || null;
          if (page) {
            setState({ current: page.current, last: page.last });
            const hasNextPage = page.current < page.last;
            setState({ hasNext: hasNextPage });
          }
        }
      })
      .finally(() => setState({ isLoadMore: false }));
  };

  if (!user) return null;

  return (
    <div className="order-container">
      <div className="content py-3">
        <div className="d-flex align-items-center mb-3 justify-content-between">
          <h6 className="mb-0 text-dark text-uppercase fw-bold">
            {t('order_text')}
          </h6>
          {/* <Link to="/orders" className="btn btn-sm  rounded-pill btn-primary">
						<small>{t('view_all_text')}</small> <strong>{t('order_text')}</strong>
					</Link> */}
          <select name="" id="" className="form-select form-select-sm w-auto">
            <option value="">All Order </option>
            <option value="">New Order </option>
            <option value="">Confirmed Order </option>
            <option value="">Cancelled Order </option>
            <option value="">Completed Order </option>
          </select>
        </div>

        {orders.length > 0 ? (
          <div className="order-list-container">
            {orders.map(order => {
              return <BoxOrder data={order} key={order.id} />;
            })}
          </div>
        ) : (
          <div className="text-center p-3">
            {isLoading ? (
              <Loader center size="24" />
            ) : (
              <h6>{t('there_is_no_order')}</h6>
            )}
          </div>
        )}
        {hasNext && <LoadMore isLoadMore={isLoadMore} loadMore={nextPage} />}
      </div>
    </div>
  );
}
