import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';

import { useAuthState } from '../../context';
import { registerCompany, uploadImage, getGeoLocation } from '../../libs/apis';
import Page from '../../components/global/Page';
import Loader from '../../components/global/Loader';
import InternalHeader from '../../components/global/InternalHeader';

function RegisterCompany() {
  // initial state as objects
  const initialState = {
    username: '',
    email: '',
    phone: '',
    companyId: '',
    companyName: '',
    address: '',
    city: '',
    areaCode: '',
    lat: '',
    lng: '',
    logoUrl: '',
    bannerUrl: '',
    homepageUrl: '',
    description: '',
    uploadingLogo: false,
    errorLogoUpload: false,
    uploadingBanner: false,
    errorBannerUpload: false,
    loading: false,
    error: false,
    message: false,
    success: false,
    zoom: 15,
    geolocationStatus: false,
    center: [],
    draggable: true,
    geocode: [],
    geoAddress: '',
  };
  // the state using react.useState
  const [state, setMyState] = useState(initialState);
  // shorthand function to setState using react Hooks
  const setState = newState => {
    // get the previousState in react hooks
    setMyState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };
  // destructures the variables, ready to use
  const {
    username,
    email,
    phone,
    loading,
    error,
    message,
    success,
    companyId,
    companyName,
    address,
    logoUrl,
    bannerUrl,
    homepageUrl,
    description,
    uploadingLogo,
    errorLogoUpload,
    uploadingBanner,
    errorBannerUpload,
    lat,
    lng,
    zoom,
    geolocationStatus,
    center,
    draggable,
    geocode,
    geoAddress,
  } = state;

  const { user } = useAuthState();
  const history = useHistory();

  if (user) {
    history.push('/profile');
  }

  const getGeoLoc = async (lati, long) => {
    await getGeoLocation({ lat: lati, lng: long }).then(res => {
      if (res.status === 200 && res.data) {
        const result = res.data.results;
        const filterResult = result.filter(
          e => e.types.indexOf('street_address') !== -1,
        );
        const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
        if (selectedGeo) {
          const geo = selectedGeo.address_components.reverse();
          const geoAddress = selectedGeo.formatted_address;
          setState({ geocode: geo, geoAddress });
          // console.log('geo', geo)
        }
      }
    });
  };

  const handleRegister = async e => {
    e.preventDefault();
    setState({ loading: true, message: '', error: false });
    try {
      // firstly get user postal_code and city
      let areaCode2 = '',
        city2 = '';
      if (geocode.length > 0) {
        areaCode2 = geocode[0].long_name;
        city2 = geocode[3].long_name;
      }
      const payload = {
        username,
        email,
        phone,
        companyId,
        companyName,
        address,
        logoUrl,
        bannerUrl,
        homepageUrl,
        description,
        lat: geolocationStatus ? lat : '',
        lng: geolocationStatus ? lng : '',
        areaCode: areaCode2,
        city: city2,
      };
      let response = await registerCompany(payload);
      if (response && response.status !== 200) {
        setState({ message: response.data.failure.message, error: true });
      } else {
        setState({ success: true, message: '', error: false });
      }
      setState({ loading: false });
    } catch (error) {
      setState({ error: true, message: error, loading: false });
      console.log(error);
    }
  };

  const removeLogo = () => {
    setState({ logoUrl: '' });
  };

  const removeBanner = () => {
    setState({ bannerUrl: '' });
  };

  const onDropLogo = e => {
    const file = e.target.files;
    setState({ uploadingLogo: true, errorLogoUpload: false });
    const payload = {
      file: file[0],
    };
    uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ logoUrl: res.data.secure_url });
        }
      })
      .catch(err => {
        setState({ errorLogoUpload: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploadingLogo: false });
      });
  };

  const onDropBanner = e => {
    const file = e.target.files;
    setState({ uploadingBanner: true, errorBannerUpload: false });
    const payload = {
      file: file[0],
    };
    uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ bannerUrl: res.data.secure_url });
        }
      })
      .catch(err => {
        setState({ errorBannerUpload: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploadingBanner: false });
      });
  };

  useEffect(() => {
    const getInitialGeoLoc = async (lati, long) => {
      await getGeoLocation({ lat: lati, lng: long }).then(res => {
        if (res.status === 200 && res.data) {
          const result = res.data.results;
          const filterResult = result.filter(
            e => e.types.indexOf('street_address') !== -1,
          );
          const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
          if (selectedGeo) {
            const geo = selectedGeo.address_components.reverse();
            const geoAddress = selectedGeo.formatted_address;
            setState({ geocode: geo, geoAddress });
          }
        }
      });
    };
    navigator.geolocation.getCurrentPosition(
      position => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        console.log('getCurrentPosition Success ' + latitude + longitude); // logs position correctly
        getInitialGeoLoc(latitude, longitude);
        setState({
          lat: latitude,
          lng: longitude,
          geolocationStatus: true,
          center: [latitude, longitude],
        });
      },
      error => {
        console.error(JSON.stringify(error));
      },
      { enableHighAccuracy: true, timeout: 2000, maximumAge: 1000 },
    );
  }, []);

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };
  const onMarkerInteractionUp = () => {
    setState({ draggable: true });
    getGeoLoc(lat, lng);
  };

  const onChangeMap = ({ center, zoom }) => {
    setState({
      center: center,
      zoom: zoom,
    });
  };

  return (
    <div className="app-container">
      <InternalHeader go="/" title="Register Company" />
      <div className="main-content py-3">
        <Page>
          {error ? (
            <div className="alert alert-danger fade show" role="alert">
              {message}
            </div>
          ) : null}
          {success ? (
            <div className="p-3 text-center">
              <h4>Success!</h4>
              <p>
                Please check your email to find your account detail and{' '}
                <Link to="/login">
                  <strong>Login here</strong>
                </Link>
              </p>
            </div>
          ) : (
            <form>
              <div className="p-3">
                <h4 className="small mb-3 text-center">
                  Please register your company account
                </h4>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="email"
                    id="email"
                    value={email}
                    onChange={e => setState({ email: e.target.value })}
                    disabled={loading}
                    required
                    placeholder="Email Address"
                  />
                  <label htmlFor="email">Email Address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="text"
                    id="username"
                    value={username}
                    minLength="7"
                    maxLength="32"
                    onChange={e => setState({ username: e.target.value })}
                    disabled={loading}
                    required
                    placeholder="Username"
                  />
                  <label htmlFor="username">Username</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="text"
                    id="phone"
                    value={phone}
                    onChange={e => setState({ phone: e.target.value })}
                    disabled={loading}
                    placeholder="Phone"
                  />
                  <label htmlFor="phone">Phone</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="text"
                    id="companyId"
                    value={companyId}
                    onChange={e => setState({ companyId: e.target.value })}
                    disabled={loading}
                    placeholder="Company ID"
                  />
                  <label htmlFor="companyId">Company ID</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="text"
                    id="companyName"
                    value={companyName}
                    onChange={e => setState({ companyName: e.target.value })}
                    disabled={loading}
                    placeholder="Company Name"
                  />
                  <label htmlFor="companyName">Company Name</label>
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    name="address"
                    id="address"
                    rows="3"
                    placeholder="Address"
                    value={address}
                    disabled={loading}
                    onChange={e => setState({ address: e.target.value })}
                    style={{ height: '120px' }}
                  ></textarea>
                  <label htmlFor="address">Address</label>
                </div>

                {geolocationStatus && (
                  <div
                    className="mb-3"
                    style={{ height: '50vh', width: '100%' }}
                  >
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                      }}
                      center={center}
                      zoom={zoom}
                      draggable={draggable}
                      onChange={onChangeMap}
                      onChildMouseDown={onMarkerInteraction}
                      onChildMouseUp={onMarkerInteractionUp}
                      onChildMouseMove={onMarkerInteraction}
                      // onChildClick={() => console.log('child click')}
                      // onClick={() => console.log('mapClick')}
                    >
                      <Marker lat={lat} lng={lng} text={geoAddress} />
                    </GoogleMapReact>
                  </div>
                )}

                <div className="form-group">
                  {errorLogoUpload && (
                    <div className="alert alert-danger text-center">
                      <p className="m-0">
                        Failed to upload logo image, try again.
                      </p>
                    </div>
                  )}
                  {uploadingLogo && <Loader size={24} />}
                  <div className="image-uploader">
                    {logoUrl && (
                      <div className="mb-3 d-flex justify-content-center">
                        <img src={logoUrl} alt="upload" />
                      </div>
                    )}
                    {logoUrl ? (
                      <button
                        onClick={removeLogo}
                        className="btn btn-danger btn-sm w-100 mb-3 rounded-0"
                        style={{ marginTop: '-1rem' }}
                      >
                        <span className="fa fa-times me-2"></span>Remove Logo
                      </button>
                    ) : (
                      <label
                        htmlFor="img"
                        className={`position-relative w-100 d-flex align-items-center mb-3 btn btn-primary`}
                      >
                        <span className="fa fa-image me-3"></span>
                        <small>Upload Company Logo</small>
                        <input
                          type="file"
                          id="img"
                          onChange={onDropLogo}
                          className="fs-6 small"
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            right: '0',
                            bottom: '0',
                            opacity: 0,
                          }}
                        />
                      </label>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  {errorBannerUpload && (
                    <div className="alert alert-danger text-center">
                      <p className="m-0">
                        Failed to upload banner image, try again.
                      </p>
                    </div>
                  )}
                  {uploadingBanner && <Loader size={24} />}
                  <div className="image-uploader">
                    {bannerUrl && (
                      <div className="mb-3 d-flex justify-content-center">
                        <img src={bannerUrl} alt="upload" />
                      </div>
                    )}
                    {bannerUrl ? (
                      <button
                        onClick={removeBanner}
                        className="btn btn-danger btn-sm w-100 mb-3 rounded-0"
                        style={{ marginTop: '-1rem' }}
                      >
                        <span className="fa fa-times me-2"></span>Remove Banner
                      </button>
                    ) : (
                      <label
                        htmlFor="img"
                        className={`position-relative w-100 d-flex align-items-center mb-3 btn btn-primary`}
                      >
                        <span className="fa fa-image me-3"></span>
                        <small>Upload Company Banner</small>
                        <input
                          type="file"
                          id="img"
                          onChange={onDropBanner}
                          className="fs-6 small"
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            right: '0',
                            bottom: '0',
                            opacity: 0,
                          }}
                        />
                      </label>
                    )}
                  </div>
                </div>

                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="url"
                    id="homepageUrl"
                    value={homepageUrl}
                    onChange={e => setState({ homepageUrl: e.target.value })}
                    disabled={loading}
                    placeholder="Homepage URL"
                  />
                  <label htmlFor="homepageUrl">Homepage URL</label>
                </div>

                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    name="description"
                    id="description"
                    rows="3"
                    placeholder="Description"
                    value={description}
                    disabled={loading}
                    onChange={e => setState({ description: e.target.value })}
                    style={{ height: '120px' }}
                  ></textarea>
                  <label htmlFor="description">Description</label>
                </div>

                <button
                  className="btn mt-1 mb-3 bg-gradient btn-lg btn-success shadow w-100"
                  onClick={handleRegister}
                  disabled={loading}
                >
                  Register Company
                </button>
              </div>
            </form>
          )}

          {!success && (
            <div
              className="p-4 py-5 border-top bg-gradient bg-light text-center"
              style={{ margin: '0 -0.75rem' }}
            >
              <h6>Or login to your account</h6>
              <div className="row my-3">
                <div className="col">
                  <Link
                    to="/login"
                    className="btn bg-gradient btn-lg shadow btn-outline-primary lh-sm w-100"
                  >
                    <span className="text">
                      <strong>Login</strong>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </Page>
      </div>
    </div>
  );
}

export default RegisterCompany;

const Marker = ({ text = null }) => (
  <div
    className="map-marker text-center"
    style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
  >
    <div className="icon">
      <span className="fa fa-map-marker"></span>
    </div>
    {text && (
      <div className="text bg-white  p-2 rounded shadow">
        <p className="m-0">{text}</p>
      </div>
    )}
  </div>
);
