import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/global/Loader';

import { addInventory, uploadImage } from '../../libs/apis';

function SellerIndex() {
  const { t } = useTranslation();
  const history = useHistory();
  const initialState = {
    name: '',
    price: '',
    priceVat: '',
    image: '',
    type: 0,
    unit: '',
    vat: 24,
    vatExceed: false,
    uploading: false,
    errorUpload: false,
    isLoading: false,
    isError: false,
    errorMessage: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    name,
    price,
    priceVat,
    image,
    type,
    unit,
    vat,
    vatExceed,
    uploading,
    errorUpload,
    isLoading,
    isError,
    errorMessage,
  } = state;

  const handleSubmit = async ev => {
    ev.preventDefault();
    setState({ isLoading: true, isError: false, errorMessage: '' });
    const payload = {
      name,
      price,
      image,
      type,
      unit,
      vat,
    };
    await addInventory(payload)
      .then(resp => {
        if (resp.status === 201 || resp.status === 200) {
          history.push('/seller/inventory');
        } else {
          setState({ isError: true, errorMessage: resp.data.error.message });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true, errorMessage: err.error.message });
      })
      .finally(() => {
        setState({ isLoading: false });
      });
  };

  const onDropImage = async e => {
    const file = e.target.files;
    setState({ uploading: true, errorUpload: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ image: res.data.secure_url });
        }
      })
      .catch(err => {
        setState({ errorUpload: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploading: false });
      });
  };

  return (
    <div className="admin-container">
      <div className="admin-header bg-primary bg-gradient py-3 sticky-top shadow-sm">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="admin-header-content d-flex justify-content-between align-items-center">
                <Link
                  to="/seller/inventory"
                  className=" text-light text-decoration-none"
                >
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">{t('back_button')}</span>
                </Link>
                <h6 className="text-light m-0">{t('seller_admin')}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container py-4 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <div className="d-flex justify-content-between align-items-center">
                  <h4>{t('add_new_inventory_heading')}</h4>
                </div>
                <p>
                  <span className="text-danger">*</span>{' '}
                  {t('warning_mandatory_text')}
                </p>
                <hr />

                <div className="form-container">
                  {isError && (
                    <div className="alert alert-danger fade show" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <form onSubmit={handleSubmit}>
                    <div className="form-floating mb-3">
                      <select
                        className="form-select "
                        name="category"
                        onChange={e => setState({ type: e.target.value })}
                      >
                        <option value="0">{t('item_label')}</option>
                        <option value="1">{t('work_label')}</option>
                      </select>
                      <label htmlFor="category">
                        {t('select_category')}{' '}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-group">
                      {/* <ImageUploader
                        singleImage={true}
                        withIcon={true}
                        buttonText='Choose images'
                        onChange={onDrop}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                      />
                      <input type="hidden" name="photo" value={photo} /> */}
                      {errorUpload && (
                        <div className="alert alert-danger text-center">
                          <p className="m-0">{t('failed_to_upload')}</p>
                        </div>
                      )}
                      {uploading && <Loader size={24} />}
                      <div className="image-uploader text-center">
                        {image && (
                          <div className="mb-3 d-flex justify-content-center">
                            <img
                              src={image}
                              alt="upload"
                              className="img-thumbnail"
                            />
                          </div>
                        )}

                        <label
                          htmlFor="img"
                          className="position-relative w-100 d-flex align-items-center mb-3 btn btn-primary"
                        >
                          <span className="fa fa-image me-3"></span>
                          {image ? (
                            <small>
                              {t('change_image')}{' '}
                              <span className="text-danger">*</span>
                            </small>
                          ) : (
                            <small>
                              {t('upload_image')}{' '}
                              <span className="text-danger">*</span>
                            </small>
                          )}
                          <input
                            type="file"
                            id="img"
                            required
                            onChange={onDropImage}
                            className="fs-6 small"
                            style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                              opacity: 0,
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        value={name}
                        onChange={e => setState({ name: e.target.value })}
                        disabled={isLoading}
                        required
                        placeholder={t('inventory_name_label')}
                      />
                      <label htmlFor="name">
                        {t('inventory_name_label')}{' '}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-floating input-group mb-3">
                      <span className="input-group-text">/</span>
                      <input
                        className="form-control"
                        type="text"
                        id="unit"
                        value={unit}
                        onChange={e => setState({ unit: e.target.value })}
                        disabled={isLoading}
                        required
                        placeholder={t('inventory_unit_label')}
                      />
                      <label htmlFor="unit" style={{ left: '2em' }}>
                        {t('inventory_unit_label')}{' '}
                        <span className="text-danger">*</span>
                      </label>
                    </div>

                    <div className="form-floating input-group mb-3">
                      <span className="input-group-text">{t('$')}</span>
                      <input
                        className="form-control"
                        type="number"
                        id="price"
                        pattern="\d*"
                        value={price}
                        required
                        onChange={e => {
                          const value = parseInt(e.target.value);
                          const newVatPrice =
                            Math.floor((value + (value * vat) / 100) * 100) /
                            100;
                          setState({
                            price: value,
                            priceVat: newVatPrice,
                          });
                        }}
                        disabled={isLoading}
                        placeholder={t('inventory_price_label')}
                      />
                      <label htmlFor="price" style={{ left: '2em' }}>
                        {t('inventory_price_label')}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-floating input-group mb-3">
                      <span className="input-group-text">{t('$')}</span>
                      <input
                        className="form-control"
                        type="number"
                        pattern="\d*"
                        id="priceVat"
                        value={priceVat}
                        required
                        onChange={e => {
                          const value = parseInt(e.target.value);
                          const vatPercent = vat / 100;
                          const newPrice =
                            Math.floor((value / (1 + vatPercent)) * 100) / 100;
                          setState({ priceVat: value, price: newPrice });
                        }}
                        disabled={isLoading}
                        placeholder={t('inventory_price_inc_label')}
                      />
                      <label htmlFor="priceVat" style={{ left: '2em' }}>
                        {t('inventory_price_inc_label')}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-floating mb-3 input-group">
                      <input
                        className={`form-control ${
                          vatExceed ? 'is-invalid' : ''
                        }`}
                        type="text"
                        id="vat"
                        value={vat}
                        required
                        step="1"
                        min="0"
                        max="100"
                        pattern="\d*"
                        onChange={e => {
                          const val = e.target.value;
                          const newVal = val === '' ? 0 : val;
                          const vatVal = parseInt(newVal);
                          if (vatVal > 100) {
                            setState({ vatExceed: true });
                          } else {
                            setState({ vatExceed: false });
                          }
                          const percentVat = vatVal / 100;
                          const priceWithVat =
                            Math.floor((price + price * percentVat) * 100) /
                            100;
                          setState({
                            vat: vatVal,
                            priceVat: priceWithVat,
                          });
                        }}
                        disabled={isLoading}
                        placeholder={`${t('inventory_vat_label')} (0-100%)`}
                      />

                      <label htmlFor="vat">
                        {`${t('inventory_vat_label')} (0-100%)`}
                        <span className="text-danger">*</span>
                      </label>
                      <span className="input-group-text">%</span>
                    </div>

                    {vatExceed ? (
                      <button
                        type="button"
                        className="btn btn-lg btn-secondary w-100 mb-3"
                        disabled
                      >
                        {t('save_button')}{' '}
                        <span className="ms-2 fa fa-chevron-right"></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary w-100 mb-3"
                      >
                        {t('save_button')}{' '}
                        <span className="ms-2 fa fa-chevron-right"></span>
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerIndex;
