let expireDate = localStorage.getItem('expires_in')
  ? new Date(JSON.parse(localStorage.getItem('expires_in')))
  : new Date();

let now = new Date();
let isExpired = expireDate.getTime() <= now.getTime();
if (isExpired) {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('expires_in');
}

let user = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : '';

let role = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user')).authorization.param
  : '';

export const initialState = {
  user: '' || user,
  role: '' || role,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        user: action.payload,
        role: action.payload.authorization.param,
      };
    case 'UPDATE_USER':
      return {
        ...initialState,
        user: action.payload,
      };
    case 'LOGOUT':
      return {
        ...initialState,
        user: '',
      };

    case 'LOGIN_ERROR':
      return {
        ...initialState,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
