import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { useAuthState } from '../../context';
import {
  getComSellerProductDetail,
  addProductToComCatalog,
  getComCatalog,
} from '../../libs/apis';
import Loader from '../../components/global/Loader';
import defaultItem from '../../assets/images/default-product.jpg';

function ViewSellerProductDetail() {
  const { t } = useTranslation();
  const history = useHistory();
  const userDetails = useAuthState();
  const { sellerId, productId } = useParams();
  const { role } = userDetails;

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'company') {
    history.push('/login?redirect=/company&u=company');
  }
  const { profile, token, tokenId } = userDetails;
  const { name, legal_id } = profile;

  const initialState = {
    catalog: [],
    data: null,
    loading: false,
    error: false,
    errorMessage: '',
    adding: false,
    errorAdding: false,
    errorAddingMessage: '',
    successAdding: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, catalog } = state;

  useEffect(() => {
    const getData = async () => {
      setState({ loading: true, error: false });
      const payload = {
        token,
        tokenId,
        sellerId,
        productId,
      };
      await getComSellerProductDetail(payload)
        .then(res => {
          if (res.status === 200) {
            setState({ data: res.data.data });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ error: true });
        })
        .finally(() => setState({ loading: false }));
      const payload2 = {
        token,
        tokenId,
        sellerId,
      };
      await getComCatalog(payload2).then(res => {
        if (res.status === 200) {
          if (Array.isArray(res.data.data)) {
            setState({ catalog: res.data.data });
          } else {
            setState({ catalog: [res.data.data] });
          }
        }
      });
    };
    getData();
  }, [token, tokenId, sellerId, productId]);

  if (!data) return <Loader size={48} center />;
  const {
    product_category_name,
    product_name,
    product_thumbnail_url,
    product_description,
    seller_name,
    seller_phone,
    seller_email,
    seller_address,
    price_min,
    price_max,
    seller_logo_url,
  } = data;

  const inCatalog = catalog.findIndex(c => c.product_id === productId) !== -1;

  const addToCatalog = async () => {
    setState({
      adding: true,
      successAdding: false,
      errorAdding: false,
      errorAddingMessage: '',
    });
    const payload = {
      token,
      tokenId,
      productId,
      sellerId,
    };
    await addProductToComCatalog(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ successAdding: true });
        } else {
          setState({ successAdding: false, errorAdding: true });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({
          successAdding: false,
          errorAdding: true,
          errorAddingMessage: err,
        });
      })
      .finally(() => setState({ adding: false }));
  };

  return (
    <div className="admin-container">
      <Helmet>
        <title>View Product Detail | {name} Company</title>
      </Helmet>
      <div className="admin-header bg-danger bg-gradient py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="admin-header-content d-flex justify-content-between align-items-center">
                <button
                  className="btn text-light m-0 btn-sm"
                  onClick={() => history.goBack()}
                >
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">Back</span>
                </button>
                <h6 className="text-light text-right m-0">
                  {name} Company
                  <br />
                  <small>{legal_id}</small>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="seller-cover">
        {product_thumbnail_url ? (
          <img src={product_thumbnail_url} alt="seller" className="img- " />
        ) : (
          <img src={defaultItem} alt="seller" className="img- " />
        )}
      </div>
      <div className="main-container py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="">
                <h1 className="fs-1 mb-1">{product_name}</h1>
                <h6 className="mb-2 ">
                  <span className="fa fa-tags text-primary opacity-50 me-1"></span>{' '}
                  {product_category_name}
                </h6>
                <h5 className=" mb-3 mt-1">
                  <span className="text-primary opacity-50 me-1">{t('$')}</span>{' '}
                  {price_min} - {price_max}
                </h5>
                <h5 className="mb-0 text-primary small">Description</h5>
                <p>{product_description}</p>
                <div className="bg-light border rounded shadow p-3 mb-3">
                  <h6 className="small mb-3 text-primary">
                    Seller Information
                  </h6>
                  <div className="row align-items-center">
                    <div className="col-3">
                      <div className="cover-image">
                        <img
                          src={seller_logo_url}
                          alt=""
                          className="img-thumbnail shadow"
                          style={{ width: '60px' }}
                        />
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="seller-info" style={{ flex: '1' }}>
                        <h5 className="mb-0">{seller_name}</h5>
                        {seller_phone && (
                          <h6 className="mb-0 mt-1 fw-normal">
                            <span className="w-24 fa fa-phone opacity-50 me-2"></span>
                            {seller_phone}
                          </h6>
                        )}
                        {seller_email && (
                          <h6 className="mb-0 mt-1 fw-normal">
                            <span className="w-24  fa fa-envelope opacity-50 me-2"></span>
                            {seller_email}
                          </h6>
                        )}
                        {seller_address && (
                          <h6 className="mb-0 mt-1 fw-normal">
                            <span className="w-24  fa fa-map-marker opacity-50 me-2"></span>
                            <small>{seller_address}</small>
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="btn-container mb-3">
                  {inCatalog ? (
                    <button
                      className="btn btn-secondary btn-lg w-100 shadow disabled"
                      disabled
                    >
                      <span className="fa fa-check opacity-50 me-2"></span>
                      <small>In</small> <strong>Catalog</strong>
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary btn-lg w-100 shadow"
                      onClick={addToCatalog}
                    >
                      <span className="fa fa-plus opacity-50 me-2"></span>
                      <small>Add To</small> <strong>Catalog</strong>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewSellerProductDetail;
