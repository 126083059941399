import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import Skeleton from 'react-loading-skeleton';

import InternalHeader from '../../components/global/InternalHeader';
import { getCategories, getProducts } from '../../libs/apis';

function Search() {
  const { t } = useTranslation();

  const loc = useLocation();
  const history = useHistory();
  const search = qs.parse(loc.search);
  const defaultCat = search.category ? search.category : 0;

  const initialState = {
    location: null,
    categories: [],
    selectedCategory: defaultCat,
    data: [],
    loading: false,
    error: false,
    searchText: '',
  };

  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    location,
    categories,
    selectedCategory,
    data,
    loading,
    searchText,
  } = state;

  const handleCategoryChange = ev => {
    ev.preventDefault();
    if (ev.target.value !== selectedCategory) {
      history.push(`/search?category=${ev.target.value}`);
      setState({ selectedCategory: ev.target.value });
    }
  };

  useEffect(() => {
    const getProductData = async loc => {
      setState({ loading: true, error: false });
      await getProducts()
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              setState({ data: resp.data.response });
            }
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ error: true });
        })
        .finally(() => setState({ loading: false }));
    };
    navigator.geolocation.getCurrentPosition(
      position => {
        // let lat = position.coords.latitude
        // let lng = position.coords.longitude
        // console.log("getCurrentPosition Success " + lat + ', ' + lng) // logs position correctly
        // const loc = {
        //   center: {
        //     lat: lat,
        //     lng: lng,
        //   },
        //   zoom: 8,
        // }
        const loc = {
          center: {
            lat: 60.631802,
            lng: 24.8578,
          },
          zoom: 8,
        };
        setState({ location: loc });
        getProductData(loc);
      },
      error => {
        const loc = {
          center: {
            lat: 60.631802,
            lng: 24.8578,
          },
          zoom: 8,
        };
        setState({ location: loc });
        getProductData(loc);
      },
      { enableHighAccuracy: true, timeout: 2000, maximumAge: 1000 },
    );

    const getCats = async () => {
      await getCategories().then(resp => {
        if (resp.status === 200) {
          if (resp.data.response && resp.data.response.length > 0) {
            setState({ categories: resp.data.response });
          }
        }
      });
    };
    getCats();
  }, [selectedCategory]);

  return (
    <div className="app-container">
      <InternalHeader go="/" title={t('search_page')}>
        <div className="search-header">
          <select
            className="form-select form-select-sm"
            aria-label={t('category_select')}
            name="category"
            onChange={handleCategoryChange}
            value={search.category}
          >
            <option value="0">{t('category_select')}</option>
            {categories.length > 0 &&
              categories.map((cat, index) => {
                const { id, name } = cat;
                return (
                  <option value={id} key={id + index}>
                    {name}
                  </option>
                );
              })}
          </select>
        </div>
      </InternalHeader>

      {loading ? (
        <div className="map-container">
          <Skeleton height={280} />
        </div>
      ) : (
        <div className="map-container">
          {location && data.length > 0 && (
            <SimpleMap location={location} data={data} />
          )}
        </div>
      )}

      <div className="search-widget bg-light shadow-sm py-3 mb-0">
        <div className="container">
          <div className="row">
            <div className="col">
              <form action="search" id="search-form">
                <div className="input-groups mb-0 d-flex align-items-center ">
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    value={searchText}
                    onChange={e => setState({ searchText: e.target.value })}
                    placeholder={t('search_by_name_text')}
                    disabled
                  />
                  <button
                    className="btn btn-sm btn-primary btn-round ms-3"
                    disabled
                  >
                    <span className="fa fa-search"></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-3">
        <div className="row">
          <div className="col">
            <div className="list-order-container">
              <h3>{t('near_you_heading')}</h3>

              <div className="list-order-item">
                {loading && (
                  <div className="order-item">
                    <div className="order-item-image">
                      <Skeleton height={48} />
                    </div>
                    <div className="order-item-description">
                      <h4>
                        <Skeleton />
                      </h4>
                      <p className="mb-0 text-muted">
                        <Skeleton />
                      </p>
                    </div>
                  </div>
                )}
                {data.length > 0 &&
                  data.map((p, index) => {
                    const { id, name, price_min, price_max, image } = p;
                    return (
                      <div className="order-item" key={id + index}>
                        <div className="order-item-image">
                          <Link to={`/product/${id}`}>
                            <img src={image} alt={name} />
                          </Link>
                        </div>
                        <div className="order-item-description">
                          <h4>
                            <Link to={`/product/${id}`}>{name}</Link>
                          </h4>
                          <p className="mb-0 text-muted">
                            {t('$')} {price_min}-{price_max}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const AnyReactComponent = ({ me = false, text = '' }) => (
  <div className="d-flex justify-content-center text-center align-items-center">
    <p className="m-0">
      <span className="fa fa-stack me-1">
        <span className="fa fa-stack-2x">
          <span className="fa fa-circle text-success"></span>
        </span>
        <span className="fa fa-stack-1x">
          <span
            className={`fa text-warning ${me ? 'fa-star' : 'fa-map-marker'}`}
          ></span>
        </span>
      </span>
    </p>
    <p
      className="m-0 text-center text-danger "
      style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
    >
      {text}
    </p>
  </div>
);

const SimpleMap = ({ location, data = [] }) => {
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '280px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY` }}
        defaultCenter={location.center}
        defaultZoom={location.zoom}
      >
        <AnyReactComponent
          lat={location.center.lat}
          lng={location.center.lng}
          me
          text={`Me`}
        />
        {data.length > 0 &&
          data.map((d, index) => {
            const { seller, id, name } = d;
            return (
              <AnyReactComponent
                key={id + index}
                lat={seller.latitude}
                lng={seller.longitude}
                text={name}
              />
            );
          })}
      </GoogleMapReact>
    </div>
  );
};

export default Search;
