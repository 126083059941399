import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from '../../context';

import Logo from '../../assets/images/logo.png';
// import userImg from '../../assets/images/user.png';

export default function L() {
  const { user, role } = useAuthState();
  if (user) {
    return (
      <div className="main-header bg-light py-1 shadow-sm sticky-top">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="header-container d-flex align-items-center justify-content-between">
                <div className="logo text-center" style={{ flex: 1 }}>
                  <Link to={`${role === 'seller' ? '/seller' : '/'}`}>
                    <img src={Logo} alt="Luokki" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="main-header py-1 bg-light shadow-sm">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="Luokki" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
