import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useAuthState } from '../../context';

export default function BoxOrder({ data }) {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  const { role, user } = useAuthState();
  const { username } = user;
  const { id, product, transaction_request, transaction_items, type } = data;
  const { name } = product;
  const txCount = transaction_request.length;
  const lastTx = transaction_request[0];
  const firstTx = txCount > 1 ? transaction_request[txCount - 1] : lastTx;
  const { status, note, datetime, price } = lastTx;
  // const statuses = ['new_order_status', 'change_from_seller_status', 'change_from_buyer_status', 'confirm_from_seller_status', 'confirm_from_buyer_status', 'complete_from_seller_status', 'complete_from_buyer_status', 'cancel_from_seller_status', 'cancel_from_buyer_status'];
  // const today = new Date().getTime();
  // const deliver = new Date(datetime).getTime();
  // const due = today >= deliver;
  // const statusText = status === 5 && !due ? 'waiting_for_delivery' : statuses[status - 1];
  // const cancelled = status > 7;

  const stats = [
    {
      status: 'new_order_status',
      color: 'text-dark',
      background: 'bg-warning',
      border: 'border-warning',
      alert: 'alert-warning',
    },
    {
      status: 'changed_status',
      color: 'text-dark',
      background: 'bg-info',
      border: 'border-info',
      alert: 'alert-info',
    },
    {
      status: 'confirmed_status',
      color: 'text-light',
      background: 'bg-success',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'invoice_sent_status',
      color: 'text-success',
      background: 'bg-light',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'payment_confirmation_status',
      color: 'text-success',
      background: 'bg-light',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'completed_status',
      color: 'text-success',
      background: 'bg-light',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'cancelled_status',
      color: 'text-danger',
      background: 'bg-danger',
      border: 'border-danger',
      alert: 'alert-danger',
    },
  ];

  const showComment = lastTx.user.username !== username;
  let firstPrice = firstTx.price;
  let firstDate = firstTx.datetime;

  const stat = status - 1;

  const types = [
    {
      typeName: 'type_normal',
      typeColor: 'secondary',
    },
    {
      typeName: 'type_opendate',
      typeColor: 'secondary',
    },
    {
      typeName: 'type_continuous',
      typeColor: 'warning',
    },
    {
      typeName: 'type_emergency',
      typeColor: 'danger',
    },
  ];

  return (
    <div
      className={`card shadow position-relative mb-3 border ${
        type === 4 ? 'border-danger' : ''
      } ${type > 1 ? 'border-2' : ''} ${type === 2 ? 'border-secondary' : ''} ${
        type === 3 ? 'border-warning' : ''
      }`}
    >
      <div className="card-body p-2">
        <div className="d-flex justify-content-between">
          <h6 className="fw-bold text-dark">{name}</h6>
          <div className="text-right d-flex align-items-center">
            {/* <div className={`mb-1 fs-10 small border p-1 lh-1 rounded-3 fw-normal bg-gradient ${status > 4 && !cancelled ? 'bg-success text-light border-success' : status > 7 ? 'bg-danger text-light border-danger' : status === 1 ? 'bg-warning border-warning' : 'text-secondary'}`}> */}
            {type > 1 && (
              <div
                className={`me-1 mb-1 fs-12 small border p-1 lh-1 rounded-3 fw-normal bg-gradient border-${
                  types[type - 1].typeColor
                } text-${types[type - 1].typeColor}`}
              >
                {t(types[type - 1].typeName)}
              </div>
            )}
            <div
              className={`mb-1 fs-12 small border p-1 lh-1 rounded-3 fw-normal bg-gradient ${stats[stat].background} ${stats[stat].color} ${stats[stat].border}`}
            >
              {t(stats[stat].status)}
            </div>
            {/* <h6 className="small fs-12 mb-0 text-muted"><Moment format="MMM DD, YYYY">{created_at}</Moment></h6> */}
          </div>
        </div>
        {/* <p className="mb-0 small">{t('product_text')}: <strong>{name}</strong></p> */}

        <div className="d-flex justify-content-between align-items-end">
          <div className="buyer-info">
            <div className="mb-1 fs-12 d-flex flex-wrap align-items-center">
              {transaction_items.map((h, i) => (
                <h6
                  className="mb-1 me-1 shadow-sm small d-flex align-items-center rounded-pill p-1 bg-primary px-2 text-nowrap mb-0 text-light text-center fs-12 text-break"
                  key={h.id}
                >
                  {/* {h.item.image && (
                    <img src={h.item.image} alt={name} className="me-1 rounded-circle shadow-sm" width="20" height="20" style={{ height: '20px', objectFit: 'cover' }} />
                  )} */}
                  {h.item.name}
                </h6>
              ))}
            </div>
            {/* <h6 className="mb-1 small"><span className="fa w-24 fa-user opacity-50 me-2"></span><strong>{user.name ? user.name : user.username}</strong></h6>
												{user.address && (
													<h6 className="small mb-1"><span className="w-24 fa fa-map-marker me-2 opacity-50"></span>{user.address}</h6>
												)} */}
            {type === 2 && status === 1 ? (
              <h6 className="small fs-10 alert-warning p-1 rounded border text-dark mb-0">
                <em>{t('open_date_text')}</em>
              </h6>
            ) : (
              <h6 className="small fs-12 mb-0">
                <span className="fs-14 fa fa-clock-o me-2 opacity-50"></span>
                <Moment locale={lang} format="MMM DD - HH:mm">
                  {datetime}
                </Moment>
              </h6>
            )}
          </div>

          <Link
            to={`/${role === 'seller' ? 'seller/' : ''}order/${id}`}
            className={`text-nowrap stretched-link btn mb-0 btn-sm fs-10 bg-gradient btn-light rounded-3  border `}
          >
            {t('view_order_button')} #{id}{' '}
            <span className="fa fa-chevron-right ms-2"></span>
          </Link>
        </div>
        {status < 5 &&
          showComment &&
          (firstDate !== datetime || firstPrice !== price) && (
            <div
              className="summary p-1 border shadow-sm bg-gradient mt-2 py-1 opacity-75 rounded"
              style={{ background: '#eee' }}
            >
              <div className=" align-items-center">
                {firstPrice !== price && (
                  <h6 className="m-0  lh-1 fs-12  fw-bold">
                    <span className="small fw-normal text-muted">
                      {firstPrice}
                    </span>
                    <span className="mx-1 fa text-success fa-long-arrow-right"></span>
                    {price}
                  </h6>
                )}

                {firstDate !== datetime && (
                  <h6 className="m-0 mt-1 lh-1 fs-12 fw-bold">
                    <span className="small fw-normal text-muted ">
                      <Moment locale={lang} format="MMM DD - HH:mm">
                        {firstDate}
                      </Moment>
                    </span>
                    <span className="mx-1 fa text-success fa-long-arrow-right"></span>
                    <Moment locale={lang} format="MMM DD - HH:mm">
                      {datetime}
                    </Moment>
                  </h6>
                )}
              </div>
              {note && note !== '' && (
                <p className=" mt-1 mb-0 w-100 lh-1 fs-12">
                  <span className="fa fa-quote-left me-1 text-muted fs-10 opacity-25"></span>
                  <em>{note}</em>
                </p>
              )}
            </div>
          )}

        {/* <div className="button-group">
											<Link to={`/seller/order/${id}`} className={`btn btn-sm bg-gradient ${status === 7 ? 'btn-outline-secondary' : 'btn-secondary'}`}><span className="fa fa-search me-2"></span>{t('view_order_button')}</Link>
										</div> */}
      </div>
    </div>
  );
}
