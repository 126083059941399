import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../context';
import { getTransaction } from '../../libs/apis';
import Moment from 'react-moment';
import 'moment/locale/fi';
import 'moment/locale/id';

export default function TransactionHistory({ horseId = null }) {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  const { user } = useAuthState();
  const initialState = {
    orders: [],
    isLoading: true,
    isError: false,
    message: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { orders } = state;

  useEffect(() => {
    const getOrderData = async () => {
      let payload = {};
      if (horseId) {
        payload.itemId = horseId;
      }
      await getTransaction(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ orders: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getOrderData();
  }, [horseId]);

  if (!user) return null;

  return (
    <div className="order-container">
      <div className="content py-3">
        {orders.length > 0 ? (
          <div className="order-list-container">
            {orders.map(order => {
              const { id, product, created_at, transaction_items } = order;

              return (
                <div
                  key={id}
                  className="border rounded-3 mb-2 bg-white p-2 position-relative"
                >
                  <div className="d-flex justify-content-between">
                    <div className="item-header">
                      <h6 className="fw-bold small mb-2">
                        <Link to={`/order/${id}`} className="stretched-link">
                          {product.name}
                        </Link>
                      </h6>
                      <div className="d-flex align-items-center">
                        {product.seller.photo && (
                          <img
                            src={product.seller.photo}
                            width="20"
                            className="me-1 rounded-circle"
                            alt={product.seller.name}
                          />
                        )}
                        <h6 className="mb-0 small text-muted">
                          {product.seller.name}
                        </h6>
                      </div>
                    </div>
                    <div className="item-meta">
                      <h6 className="small text-muted text-right">
                        <Moment locale={lang} format="MMM d, Y">
                          {created_at}
                        </Moment>
                      </h6>
                      {!horseId && (
                        <div
                          className="d-flex justify-content-end"
                          style={{ maxWidth: '100px', overflowX: 'auto' }}
                        >
                          {transaction_items.map((h, idx) => (
                            <img
                              src={h.item.image}
                              alt={h.item.name}
                              className="rounded-circle shadow-sm ms-1"
                              width="24"
                              key={h.id + idx}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center p-3">
            <h6>{t('there_is_no_order')}</h6>
          </div>
        )}
      </div>
    </div>
  );
}
