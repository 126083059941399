import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../context';

export default function MainMenu() {
  const { t } = useTranslation();
  const location = useLocation();
  const route = location.pathname;
  const isHome = route === '/';
  const { role, user } = useAuthState();
  if (role === 'seller') {
    return (
      <div className="main-menu shadow border-top">
        <div className={`menu-item ${route === '/seller' ? 'active' : ''}`}>
          <Link to="/seller">
            <span className="menu-icon">
              <span className="fa fa-dashboard"></span>
            </span>
            <span className="menu-text">{t('menu_dashboard')}</span>
          </Link>
        </div>
        <div
          className={`menu-item ${route === '/seller/order' ? 'active' : ''}`}
        >
          <Link to="/seller/order">
            <span className="menu-icon">
              <span className="fa fa-list"></span>
            </span>
            <span className="menu-text">{t('menu_order')}</span>
          </Link>
        </div>
        <div
          className={`menu-item ${
            route === '/seller/schedule' ? 'active' : ''
          }`}
        >
          <Link to="/seller/schedule">
            <span className="menu-icon">
              <span className="fa fa-calendar"></span>
            </span>
            <span className="menu-text">{t('menu_schedule')}</span>
          </Link>
        </div>
        {/* <div className={`menu-item ${route === '/seller/product' ? 'active' : ''}`}>
          <Link to="/seller/product">
            <span className="menu-icon"><span className="fa fa-briefcase"></span></span>
            <span className="menu-text">{t('menu_products')}</span>
          </Link>
        </div> */}
        <div
          className={`menu-item ${route === '/seller/invoice' ? 'active' : ''}`}
        >
          <Link to="/seller/invoice">
            <span className="menu-icon">
              <span className="fa fa-file-text-o"></span>
            </span>
            <span className="menu-text">{t('menu_invoice')}</span>
          </Link>
        </div>
        <div className={`menu-item ${route === '/profile' ? 'active' : ''}`}>
          <Link to="/profile">
            <span className="menu-icon">
              <span className="fa fa-user"></span>
            </span>
            <span className="menu-text">{t('menu_profile')}</span>
          </Link>
        </div>
      </div>
    );
  }
  if (user) {
    return (
      <div className="main-menu shadow border-top">
        {/* <div className={`menu-item ${isHome ? 'active' : ''}`}>
          <Link to="/">
            <span className="menu-icon"><span className="fa fa-home"></span></span>
            <span className="menu-text">{t('menu_home')}</span>
          </Link>
        </div>
         */}
        <div className={`menu-item ${route === '/explore' ? 'active' : ''}`}>
          <Link to="/explore">
            <span className="menu-icon">
              <span className="fa fa-search"></span>
            </span>
            <span className="menu-text">{t('menu_explore')}</span>
          </Link>
        </div>
        <div className={`menu-item ${route === '/orders' ? 'active' : ''}`}>
          <Link to="/orders">
            <span className="menu-icon">
              <span className="fa fa-list"></span>
            </span>
            <span className="menu-text">{t('menu_order')}</span>
          </Link>
        </div>
        <div className={`menu-item ${route === '/schedule' ? 'active' : ''}`}>
          <Link to="/schedule">
            <span className="menu-icon">
              <span className="fa fa-calendar"></span>
            </span>
            <span className="menu-text">{t('menu_schedule')}</span>
          </Link>
        </div>
        {/* <div className={`menu-item ${route === '/wishlist' ? 'active' : ''}`}>
          <Link to="/wishlist">
            <span className="menu-icon">
              <span className="fa fa-star"></span>
            </span>
            <span className="menu-text">{t('menu_favorite')}</span>
          </Link>
        </div> */}
        {/* <div className={`menu-item ${route === '/notification' ? 'active' : ''}`}>
        <Link to="/notification">
          <span className="menu-icon"><span className="fa fa-bell"></span></span>
          <span className="menu-text">{t('menu_notification')}</span>
        </Link>
      </div> */}
        <div className={`menu-item ${route === '/profile' ? 'active' : ''}`}>
          <Link to="/profile">
            <span className="menu-icon">
              <span className="fa fa-user"></span>
            </span>
            <span className="menu-text">{t('menu_profile')}</span>
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div className="main-menu shadow border-top">
      <div className={`menu-item ${isHome ? 'active' : ''}`}>
        <Link to="/">
          <span className="menu-icon">
            <span className="fa fa-home"></span>
          </span>
          <span className="menu-text">{t('menu_home')}</span>
        </Link>
      </div>
      <div className={`menu-item ${route === '/explore' ? 'active' : ''}`}>
        <Link to="/explore">
          <span className="menu-icon">
            <span className="fa fa-search"></span>
          </span>
          <span className="menu-text">{t('menu_explore')}</span>
        </Link>
      </div>
      <div className={`menu-item ${route === '/login' ? 'active' : ''}`}>
        <Link to="/login">
          <span className="menu-icon">
            <span className="fa fa-sign-in"></span>
          </span>
          <span className="menu-text">{t('login_button')}</span>
        </Link>
      </div>
    </div>
  );
}
