import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useAuthState } from '../../context';
import userImg from '../../assets/images/user.png';

function CompanyIndex() {
  const history = useHistory();
  const userDetails = useAuthState();
  const { role } = userDetails;

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'company') {
    history.push('/login?redirect=/company&u=company');
  }
  const { profile } = userDetails;
  const { name, logo_url, banner_url, legal_id } = profile;

  return (
    <div className="admin-container">
      <Helmet>
        <title>Dashboard: {name} Company</title>
      </Helmet>
      <div className="admin-header bg-danger bg-gradient py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="admin-header-content d-flex justify-content-between align-items-center">
                <button
                  className="btn text-light m-0 btn-sm"
                  onClick={() => history.goBack()}
                >
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">Back</span>
                </button>
                <h6 className="text-light text-right m-0">
                  {name} Company
                  <br />
                  <small>{legal_id}</small>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      {banner_url && banner_url !== '' && (
        <div className="text-center">
          <img src={banner_url} alt={name} />
        </div>
      )}
      <div className="main-container pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="">
                <div className="cover-image" style={{ marginTop: '-60px' }}>
                  {logo_url ? (
                    <img
                      src={logo_url}
                      alt={name}
                      className="shadow img-thumbnail"
                      style={{ minHeight: '120px' }}
                    />
                  ) : (
                    <img
                      src={userImg}
                      alt="default avatar"
                      className=" shadow img-thumbnail"
                    />
                  )}
                </div>
                <div className="py-3 text-center">
                  <h5>Welcome, {name}</h5>
                  <p>Here are cool things you can do</p>

                  <div className="d-flex flex-wrap">
                    <div className="w-100 px-3">
                      <Link
                        to="/edit-company"
                        className="btn w-100 btn-success mb-4"
                      >
                        Update Company Profile
                      </Link>
                    </div>
                    <div className="w-50 px-3">
                      <Link
                        to="/company/seller"
                        className="w-100 btn btn-lg btn-danger lh-1 py-3"
                      >
                        <small>View</small>
                        <br />
                        <strong>Seller</strong>
                      </Link>
                    </div>
                    <div className="w-50 px-3">
                      <Link
                        to="/company/catalog"
                        className="w-100 btn btn-lg btn-danger lh-1 py-3"
                      >
                        <small>View</small>
                        <br />
                        <strong>Catalog</strong>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyIndex;
