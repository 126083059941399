import React from 'react';
import { useParams } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

// import InternalHeader from '../../components/global/InternalHeader';
import WidgetOrder from '../../components/global/WidgetOrder';

function Quotation() {
  // const { t } = useTranslation();
  const { orderId } = useParams();

  return (
    <div className="app-container">
      {/* <InternalHeader go="/orders" title={t('quotation_page')} /> */}
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="content">
              {/* <h6 className="mb-3">{t('quotation_heading')}: <span className="text-primary">{name}</span></h6> */}
              <div className="quotation-status pb-5">
                <WidgetOrder orderId={orderId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quotation;
