import Browse from '../pages/portal/Browse';
import Error404 from '../pages/portal/Error404';
import BuyerOnly from '../pages/portal/BuyerOnly';
import Home from '../pages/portal/Home';
import Login from '../pages/portal/Login';
import Search from '../pages/portal/Search';
import RegisterBuyer from '../pages/portal/RegisterBuyer';
import RegisterSeller from '../pages/portal/RegisterSeller';
import RegisterCompany from '../pages/portal/RegisterCompany';

import EditProfile from '../pages/user/EditProfile';
import Item from '../pages/user/Item';
import AddItem from '../pages/user/AddItem';
import EditItem from '../pages/user/EditItem';
import Profile from '../pages/user/Profile';
import ChangePassword from '../pages/user/ChangePassword';

import OrderConfirm from '../pages/transactions/OrderConfirm';
import Product from '../pages/transactions/Product';
import Notification from '../pages/transactions/Notification';
import Schedule from '../pages/transactions/SchedulePage';
import Favorite from '../pages/transactions/Favorite';
import OrderPage from '../pages/transactions/OrderPage';
import Purchase from '../pages/transactions/Purchase';
import OrderDetail from '../pages/transactions/OrderDetail';

import AdminIndex from '../pages/admin/index';
import AdminSellerIndex from '../pages/admin/AdminSellerIndex';
import AdminBuyerIndex from '../pages/admin/AdminBuyerIndex';

import SellerIndex from '../pages/seller/index';
import SellerAddProduct from '../pages/seller/AddProduct';
import SellerEditProduct from '../pages/seller/EditProduct';
import SellerProcessOrder from '../pages/seller/ProcessOrder';
import SellerSchedule from '../pages/seller/SellerSchedule';
import SellerOrder from '../pages/seller/SellerOrder';
import SellerProduct from '../pages/seller/SellerProduct';
import SellerProcessInvoice from '../pages/seller/ProcessInvoice';
import SellerInvoice from '../pages/seller/SellerInvoice';
import SellerInventory from '../pages/seller/Inventory';
import SellerAddInventory from '../pages/seller/AddInventory';
import SellerEditInventory from '../pages/seller/EditInventory';

import CompanyIndex from '../pages/company/index';
import EditCompanyProfile from '../pages/company/EditCompanyProfile';
import ViewSeller from '../pages/company/ViewSeller';
import ViewSellerProduct from '../pages/company/ViewSellerProduct';
import ViewSellerProductDetail from '../pages/company/ViewSellerProductDetail';
import ViewCatalog from '../pages/company/ViewCatalog';

const routes = [
  // public routes
  {
    path: '/explore',
    component: Browse,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/login',
    component: Login,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/search',
    component: Search,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/register-seller',
    component: RegisterSeller,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/register-buyer',
    component: RegisterBuyer,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/register-company',
    component: RegisterCompany,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/for-buyer',
    component: BuyerOnly,
    isPrivate: false,
    showHeader: true,
  },
  //admin routes
  // {
  // 	path: '/admin/buyer/register',
  // 	component: RegisterBuyer,
  // 	isPrivate: false,
  // 	showHeader: true,
  // },
  // {
  // 	path: '/admin/seller/register',
  // 	component: RegisterSeller,
  // 	isPrivate: false,
  // 	showHeader: true,
  // },
  {
    path: '/seller/product/edit/:productId',
    component: SellerEditProduct,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/product/new',
    component: SellerAddProduct,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/order/:orderId/invoice',
    component: SellerProcessInvoice,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/seller/inventory/new',
    component: SellerAddInventory,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/inventory/:inventoryId/edit',
    component: SellerEditInventory,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/inventory',
    component: SellerInventory,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/product',
    component: SellerProduct,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/invoice',
    component: SellerInvoice,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/schedule',
    component: SellerSchedule,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/seller/order/:orderId',
    component: SellerProcessOrder,
    isPrivate: false,
    showHeader: false,
  },
  {
    path: '/seller/order/',
    component: SellerOrder,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/seller',
    component: SellerIndex,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/company/catalog',
    component: ViewCatalog,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/company/seller/:sellerId/product/:productId',
    component: ViewSellerProductDetail,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/company/seller/:sellerId',
    component: ViewSellerProduct,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/company/seller',
    component: ViewSeller,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/edit-company',
    component: EditCompanyProfile,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/company',
    component: CompanyIndex,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/admin/seller',
    component: AdminSellerIndex,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/admin/buyer',
    component: AdminBuyerIndex,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/admin',
    component: AdminIndex,
    isPrivate: false,
    showHeader: true,
  },
  //user routes
  {
    path: '/profile',
    component: Profile,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/edit-profile',
    component: EditProfile,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/change-password',
    component: ChangePassword,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/notification',
    component: Notification,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/schedule',
    component: Schedule,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/wishlist',
    component: Favorite,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/item/new',
    component: AddItem,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/item/:itemId/edit',
    component: EditItem,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/item/:itemId',
    component: Item,
    isPrivate: true,
    showHeader: true,
  },
  //transaction routes
  {
    path: '/product/:productId',
    component: Product,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/purchase/:productId',
    component: Purchase,
    isPrivate: true,
    showHeader: false,
    showMenu: false,
  },
  {
    path: '/order/:orderId/confirm',
    component: OrderConfirm,
    isPrivate: true,
    showHeader: true,
  },
  {
    path: '/order/:orderId',
    component: OrderDetail,
    isPrivate: true,
    showHeader: false,
  },
  {
    path: '/orders',
    component: OrderPage,
    isPrivate: true,
    showHeader: true,
  },

  //common routes
  {
    path: '/',
    component: Home,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/for-buyer',
    component: BuyerOnly,
    isPrivate: false,
    showHeader: true,
  },
  {
    path: '/error',
    component: Error404,
    isPrivate: false,
    showHeader: true,
  },
];

export default routes;
