import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  useAuthState,
  setUser,
  useAuthDispatch,
  setCredentials,
} from '../../context';
import { registerUser, getProfile } from '../../libs/apis';
import Page from '../../components/global/Page';
import InternalHeader from '../../components/global/InternalHeader';

function RegisterPage() {
  const { t } = useTranslation();
  const initialState = {
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    type: 'buyer',
    success: false,
    loading: false,
    error: false,
    message: '',
    passwordAlert: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    username,
    email,
    password,
    confirmPassword,
    type,
    success,
    loading,
    error,
    message,
    passwordAlert,
  } = state;

  const { user } = useAuthState();
  const dispatch = useAuthDispatch();
  const history = useHistory();

  if (user) {
    history.push('/');
  }

  const handleLogin = async token => {
    await getProfile(token)
      .then(resp => {
        if (resp.status === 200) {
          if (resp.data.response) {
            setUser(dispatch, resp.data.response);
            setTimeout(() => {
              history.push('/');
            }, 100);
          }
        } else {
          console.log('err', resp.data.error);
          setState({
            error: true,
            message: resp.data.error.message,
            loading: false,
          });
        }
      })
      .catch(err => console.log('err', err))
      .finally(() => {
        console.log('finish profile');
        setState({ loading: false });
      });
  };
  const handleRegister = async e => {
    e.preventDefault();
    setState({ loading: true, error: false, message: '' });
    const payload = {
      username,
      password,
      email,
      authorization: type,
    };
    await registerUser(payload)
      .then(resp => {
        if (resp.status === 201) {
          if (resp.data.response) {
            if (resp.data.response.token) {
              setCredentials(resp.data.response);
              handleLogin(resp.data.response.token);
            }
          }
        } else {
          setState({ error: true, message: resp.data.error.message });
        }
      })
      .catch(err => console.log('err', err))
      .finally(() => setState({ loading: false }));
  };

  return (
    <div className="app-container">
      <InternalHeader go="/" title={t('register_buyer_page')} />
      <div className="main-content py-3">
        <Page>
          {error ? (
            <div className="alert alert-danger fade show" role="alert">
              {message}
            </div>
          ) : null}
          {success ? (
            <div className="p-3 text-center">
              <h4>Success!</h4>
              <p>
                Please check your email to find your account detail and{' '}
                <Link to="/login">
                  <strong>Login here</strong>
                </Link>
              </p>
            </div>
          ) : (
            <form onSubmit={handleRegister}>
              <div className="p-3">
                <h5 className=" mb-3 text-center">
                  {t('register_buyer_heading')}
                </h5>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="email"
                    id="email"
                    value={email}
                    onChange={e => setState({ email: e.target.value })}
                    disabled={loading}
                    required
                    placeholder={t('email_label')}
                  />
                  <label htmlFor="email">{t('email_label')}</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="text"
                    id="username"
                    value={username}
                    minLength="3"
                    maxLength="20"
                    required
                    onChange={e => {
                      const regex = /^[a-zA-Z0-9_]+$/;
                      if (e.target.value.length < 1) {
                        setState({ username: e.target.value });
                      } else if (regex.test(e.target.value)) {
                        setState({ username: e.target.value });
                      }
                    }}
                    disabled={loading}
                    placeholder={t('username_label')}
                  />
                  <label htmlFor="username">{t('username_label')}</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    className={`form-control`}
                    type="password"
                    id="password"
                    minLength="8"
                    required
                    value={password}
                    onChange={e => setState({ password: e.target.value })}
                    disabled={loading}
                    placeholder={t('password_label')}
                  />
                  <label htmlFor="password">{t('password_label')}</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className={`form-control ${
                      passwordAlert ? 'is-invalid' : ''
                    }`}
                    type="password"
                    id="confirmPassword"
                    minLength="8"
                    required
                    value={confirmPassword}
                    onChange={e => {
                      setState({ confirmPassword: e.target.value });
                      if (e.target.value !== password) {
                        setState({ passwordAlert: true });
                      } else {
                        setState({ passwordAlert: false });
                      }
                    }}
                    disabled={loading}
                    placeholder={t('confirm_password_label')}
                  />
                  <label htmlFor="confirmPassword">
                    {t('confirm_password_label')}
                  </label>
                </div>

                <button
                  className="btn bg-gradient mt-1 btn-lg btn-primary shadow w-100"
                  type="submit"
                  disabled={loading}
                >
                  {t('register_buyer_button')}
                </button>
              </div>
            </form>
          )}

          {!success && (
            <div
              className="p-4 py-5 border-top bg-gradient bg-light text-center"
              style={{ margin: '0 -0.75rem' }}
            >
              <h6>{t('or_login')}</h6>
              <div className="row my-3">
                <div className="col">
                  <Link
                    to="/login"
                    className="btn bg-gradient btn-lg shadow btn-outline-primary lh-sm w-100"
                  >
                    <span className="text">
                      <strong>{t('login_button')}</strong>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </Page>
      </div>
    </div>
  );
}

export default RegisterPage;
