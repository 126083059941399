import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useAuthState } from '../../context';
import { getComSeller } from '../../libs/apis';
import Loader from '../../components/global/Loader';
import userImg from '../../assets/images/user.png';

function CompanyIndex() {
  const history = useHistory();
  const userDetails = useAuthState();
  const { role } = userDetails;

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'company') {
    history.push('/login?redirect=/company&u=company');
  }
  const { profile, token, tokenId } = userDetails;
  const { name, legal_id } = profile;

  const initialState = {
    data: [],
    loading: false,
    error: false,
    errorMessage: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, loading } = state;

  useEffect(() => {
    const getData = async () => {
      setState({ loading: true, error: false });
      const payload = {
        token,
        tokenId,
      };
      await getComSeller(payload)
        .then(res => {
          if (res.status === 200) {
            setState({ data: res.data.data });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ error: true });
        })
        .finally(() => setState({ loading: false }));
    };
    getData();
  }, [token, tokenId]);

  return (
    <div className="admin-container">
      <Helmet>
        <title>View Sellers | {name} Company</title>
      </Helmet>
      <div className="admin-header bg-danger bg-gradient py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="admin-header-content d-flex justify-content-between align-items-center">
                <button
                  className="btn text-light m-0 btn-sm"
                  onClick={() => history.goBack()}
                >
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">Back</span>
                </button>
                <h6 className="text-light text-right m-0">
                  {name} Company
                  <br />
                  <small>{legal_id}</small>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="">
                <div className="page-header mb-3">
                  <h1 className="fs-5">View Sellers</h1>
                </div>

                {!loading && data.length > 0 ? (
                  <div className="seller-list">
                    {data.map((seller, index) => {
                      const {
                        user_id,
                        legal_name,
                        logo_url,
                        address,
                        username,
                        phone,
                        email,
                      } = seller;
                      return (
                        <div
                          className="seller-item bg-light bg-gradient mb-2 shadow-sm rounded border p-3 position-relative"
                          key={user_id + index}
                        >
                          <div className="row align-items-center">
                            <div className="col-3">
                              <div className="seller-cover">
                                {logo_url ? (
                                  <img
                                    src={logo_url}
                                    alt="seller"
                                    className="img-thumbnail rounded-circle shadow"
                                  />
                                ) : (
                                  <img
                                    src={userImg}
                                    alt="seller"
                                    className="img-thumbnail rounded-circle shadow"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-9">
                              <h5 className="text-primary mb-2">
                                <Link
                                  to={`/company/seller/${user_id}`}
                                  className="stretched-link"
                                >
                                  {legal_name ? legal_name : username}
                                </Link>
                              </h5>
                              {email && (
                                <h6 className="small mb-0 mt-1 fw-normal">
                                  <span className="w-24 fa fa-envelope opacity-50 me-1"></span>{' '}
                                  {email}
                                </h6>
                              )}
                              {phone && (
                                <h6 className="small mb-0 mt-1 fw-normal">
                                  <span className="w-24 fa fa-phone opacity-50 me-1"></span>{' '}
                                  {phone}
                                </h6>
                              )}
                              {address && (
                                <h6 className="small mb-0 mt-1 fw-normal">
                                  <span className="w-24 fa fa-map-marker opacity-50 me-1"></span>{' '}
                                  {address}
                                </h6>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="text-center py-3">
                    <Loader size={32} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyIndex;
