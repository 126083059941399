import React, { useState, useEffect, forwardRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../context';
// import DateTimePicker from 'react-datetime-picker'
import Moment from 'react-moment';
import moment from 'moment';
import Modal from 'react-modal';
import GoogleMapReact from 'google-map-react';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fi';
import 'moment/locale/id';
import fi from 'date-fns/locale/fi';
import id from 'date-fns/locale/id';

import {
  getTransactionDetail,
  addTransactionBid,
  createInvoice,
  sendInvoiceEmail,
} from '../../libs/apis';
import Loader from './Loader';
import defaultItem from '../../assets/images/default-product.jpg';
import WidgetBuyer from './WidgetBuyer';
import WidgetSeller from './WidgetSeller';
import WidgetHorse from './WidgetHorse';

registerLocale('fi', fi);
registerLocale('id', id);

export default function WidgetOrder({ orderId }) {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  moment.locale(lang);
  const { user, role } = useAuthState();
  const isSeller = role === 'seller' || false;
  const today = new Date();
  const newDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
    7,
    0,
    0,
  );

  const CustomInputCalendar = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`btn btn-outline-secondary w-100 text-start`}
      onClick={onClick}
      ref={ref}
    >
      <span className="fa fa-calendar me-2"></span>
      <span className="font-cabin fs-14">{value}</span>
    </button>
  ));

  const types = [
    {
      typeName: 'type_normal',
      typeColor: 'secondary',
      orderName: 'order_normal',
    },
    {
      typeName: 'type_opendate',
      typeColor: 'secondary',
      orderName: 'order_opendate',
    },
    {
      typeName: 'type_continuous',
      typeColor: 'warning',
      orderName: 'order_continuous',
    },
    {
      typeName: 'type_emergency',
      typeColor: 'danger',
      orderName: 'order_emergency',
    },
  ];

  const initialState = {
    data: null,
    isLoading: true,
    isError: false,
    message: '',
    orderStatus: null,
    isOpen: false,
    action: 0,
    notes: '',
    date: newDate,
    // time: moment(newDate).format('HH:mm'),
    // newDatetime: moment(newDate).format('YYYY-MM-DD HH:mm'),
    price: 0,
    isRefreshing: false,
    isDetailOpen: false,
    isMapOpen: false,
    hideProduct: true,
    hideBuyer: true,
    hideHorse: true,
    isBidding: false,
    isConfirm: false,
    invoiceFile: null,
    isEmailSuccess: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    data,
    isLoading,
    isBidding,
    isError,
    message,
    isOpen,
    action,
    notes,
    price,
    isRefreshing,
    isDetailOpen,
    isMapOpen,
    date,
    isConfirm,
    invoiceFile,
    isEmailSuccess,
  } = state;

  const changeStatus = 2;
  const confirmStatus = 3;
  const paymentStatus = 5;
  const completeStatus = 6;
  const cancelStatus = 7;

  useEffect(() => {
    const getInvoice = async () => {
      const payload = {
        id: orderId,
      };
      await createInvoice(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              setState({ invoiceFile: resp.data.response.path || null });
            }
          } else {
            setState({
              isError: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    const getProduct = async () => {
      const payload = {
        id: orderId,
      };
      await getTransactionDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              setState({ data: resp.data.response });
              const lb = resp.data.response.transaction_request[0];
              setState({
                price: lb.price,
                date: new Date(moment(lb.datetime).format()),
              });
              if (lb.status > 3) {
                getInvoice();
              }
            }
          } else {
            setState({
              isError: true,
              message: resp.data.error.message,
            });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getProduct();
  }, [orderId]);

  if (!user) return null;
  if (!orderId) return <Redirect to="/404" />;
  if (isLoading) return <Loader size="48" center />;
  if (isError)
    return (
      <div className="app-container text-center d-flex flex-direction-column align-items-center justify-content-center w-100 vh-100">
        <div className="content py-5">
          <h3 className="mb-3">{message}</h3>
          <Link to="/" className="btn btn-warning">
            Back To Previous
          </Link>
        </div>
      </div>
    );

  const refreshStatus = async () => {
    setState({ isRefreshing: true });
    const payload = {
      id: orderId,
    };
    await getTransactionDetail(payload)
      .then(resp => {
        if (resp.status === 200) {
          if (resp.data.response) {
            setState({ data: resp.data.response });
            const lb = resp.data.response.transaction_request;
            setState({
              price: lb[0].price,
              date: new Date(moment(lb[0].datetime).format()),
            });
          }
        } else {
          setState({
            isError: true,
            message: resp.data.error.message,
          });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true });
      })
      .finally(() => setState({ isRefreshing: false }));
  };

  const {
    product,
    transaction_request,
    transaction_items,
    type,
    interval,
  } = data;
  const { name, image } = product;

  const bids = transaction_request;
  const horseCount = transaction_items.length;

  const lastBid = bids[0];
  const lastStatus = lastBid.status;

  const confirmed = lastStatus === 3;
  const invoice = lastStatus === 4;
  const paymentConfirm = lastStatus === 5;
  const completed = lastStatus === 6;
  const cancelled = lastStatus === 7;

  const todayTime = today.getTime();
  const deliver = new Date(
    moment(lastBid.datetime).format('MM/DD/YYYY'),
  ).getTime();
  const delivered = todayTime >= deliver;

  const stats = [
    {
      status: 'new_order_status',
      color: 'text-dark',
      background: 'bg-warning',
      border: 'border-warning',
      alert: 'alert-warning',
    },
    {
      status: 'changed_status',
      color: 'text-dark',
      background: 'bg-info',
      border: 'border-info',
      alert: 'alert-info',
    },
    {
      status: 'confirmed_status',
      color: 'text-success',
      background: 'bg-success',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'invoice_sent_status',
      color: 'text-success',
      background: 'bg-white',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'payment_confirmation_status',
      color: 'text-success',
      background: 'bg-success',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'completed_status',
      color: 'text-success',
      background: 'bg-success',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'cancelled_status',
      color: 'text-danger',
      background: 'bg-danger',
      border: 'border-danger',
      alert: 'alert-danger',
    },
  ];

  const stat = lastStatus - 1;

  const processOrder = async stat => {
    setState({ isBidding: true });
    const payload = {
      id: orderId,
      price: price,
      datetime: moment(date).format('YYYY-MM-DD HH:mm:ss'),
      note: notes,
      status: stat,
    };
    await addTransactionBid(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({
            data: resp.data.response,
            isOpen: false,
            notes: '',
            date: new Date(
              moment(
                resp.data.response.transaction_request[0].datetime,
              ).format(),
            ),
          });
        }
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => setState({ isBidding: false }));
  };

  const generatePdf = () => {
    const payload = {
      id: orderId,
    };
    createInvoice(payload).then(resp => {
      if (resp.status === 200) {
        if (resp.data.response) {
          const file =
            resp.data.response.path && resp.data.response.path !== ''
              ? resp.data.response.path
              : null;
          if (file) {
            window.open(file);
          }
        }
      }
    });
  };

  const sendEmail = async () => {
    setState({ isEmailSuccess: false });
    const payload = {
      id: orderId,
    };
    await sendInvoiceEmail(payload).then(resp => {
      if (resp.status === 200) {
        setState({ isEmailSuccess: true });
      }
    });
  };

  const intervalText =
    interval && interval % 30 === 0 ? 'interval_month' : 'interval_week';
  const intervalCount =
    interval && interval % 30 === 0 ? interval / 30 : interval / 7;

  return (
    <div className="order-detail">
      {type === 4 && (
        <div
          className="bg-danger p-2 mb-0 text-center shadow position-sticky sticky-top"
          style={{ margin: '0 -.75rem' }}
        >
          <h6 className="text-light mb-0">{t(types[type - 1].orderName)}</h6>
        </div>
      )}
      {type === 3 && (
        <div
          className="bg-warning p-2 mb-0 text-center shadow position-sticky sticky-top"
          style={{ margin: '0 -.75rem' }}
        >
          <h6 className="text-light mb-0">{t(types[type - 1].orderName)}</h6>
        </div>
      )}
      {type === 2 && (
        <div
          className="bg-secondary p-2 mb-0 text-center shadow position-sticky sticky-top"
          style={{ margin: '0 -.75rem' }}
        >
          <h6 className="text-light mb-0">{t(types[type - 1].orderName)}</h6>
        </div>
      )}
      {delivered && lastStatus > 3 && (
        <div
          className="invoice-tools shadow-sm border p-2 bg-white"
          style={{ margin: '0 -.75rem' }}
        >
          <div className="d-flex align-items-center justify-content-center">
            {invoiceFile && invoiceFile !== '' ? (
              <a
                className="btn btn-sm w-50 fs-12 me-2 btn-light border-dark"
                href={invoiceFile}
                target="_blank"
                rel="noreferrer"
              >
                <span className="fa fa-file-pdf-o text-danger me-2"></span>
                {t('download_invoice')}
              </a>
            ) : (
              <button
                className="btn btn-sm w-50 fs-12 me-2 btn-light border-dark"
                onClick={generatePdf}
              >
                <span className="fa fa-file-pdf-o text-danger me-2"></span>
                {t('download_invoice')}
              </button>
            )}

            {isSeller && (
              <button
                className="btn btn-sm w-50 fs-12 btn-outline-success"
                onClick={sendEmail}
              >
                <span className="fa fa-send me-2"></span>
                {t('resend_invoice')}
              </button>
            )}
          </div>
          <Modal
            isOpen={isEmailSuccess}
            onRequestClose={() => setState({ isEmailSuccess: false })}
            contentLabel="Modal"
            className="reveal small p-3"
            ariaHideApp={false}
          >
            <div className="pt-4">
              <h6 className="mb-3 text-center text-success fw-bold">
                Success sending invoice to buyer's email.
              </h6>
              <button
                onClick={() => setState({ isEmailSuccess: false })}
                className="btn btn-sm btn-secondary w-100"
              >
                Close
              </button>
            </div>
            <button
              className="btn btn-close close-reveal"
              onClick={() => setState({ isEmailSuccess: false })}
            >
              &times;
            </button>
          </Modal>
        </div>
      )}
      <div className="content py-3">
        <div className="multisteps-form pt-3">
          <div className="row">
            <div className="col-12 ms-auto me-auto mb-3">
              <div className="multisteps-form__progress">
                <button
                  className={`multisteps-form__progress-btn active fs-10 ${
                    lastBid.status > 0 ? 'js-active' : ''
                  }`}
                  type="button"
                  title={t('request_label')}
                >
                  {t('request_label')}
                </button>
                <button
                  className={`multisteps-form__progress-btn active fs-10 ${
                    lastBid.status > 1 ? 'js-active' : ''
                  }`}
                  type="button"
                  title={t('quotation_label')}
                >
                  {t('quotation_label')}
                </button>
                <button
                  className={`multisteps-form__progress-btn active fs-10 ${
                    lastBid.status > 2 ? 'js-active' : ''
                  }`}
                  type="button"
                  title={t('confirmation_label')}
                >
                  {t('confirmation_label')}
                </button>
                <button
                  className={`multisteps-form__progress-btn active fs-10 ${
                    lastBid.status === 6 ? 'js-active' : ''
                  }`}
                  type="button"
                  title={t('completed_label')}
                >
                  {t('completed_label')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`alert position-relative p-2 rounded-3 shadow-sm bg-gradient ${stats[stat].alert}`}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="small mb-0 text-uppercase fs-12 opacity-75">
              {t('status_text')}:
            </h6>
            {lastStatus === 6 ? (
              <h6 className="mb-0">
                <strong className="text-success">
                  {t('order_completed_status')}
                </strong>{' '}
                <span className="fa fa-check opacity-75 ms-1"></span>
              </h6>
            ) : (
              <h6 className="mb-0">
                <strong className={`${stats[stat].color}`}>
                  {t(stats[stat].status)}
                </strong>
                {!cancelled && (
                  <span className="status-icon ms-2">
                    {isRefreshing ? (
                      <span className="fa fa-refresh fa-spin opacity-50"></span>
                    ) : (
                      <span
                        className="fa fa-refresh opacity-75"
                        onClick={() => refreshStatus()}
                      ></span>
                    )}
                  </span>
                )}
              </h6>
            )}
          </div>
        </div>

        <div className="quotation-status pb-5">
          <div className="product-card mb-3 bg-white p-2 shadow-sm border rounded">
            <div className="row g-2 align-items-center">
              <div className="col col-auto">
                <img
                  src={image}
                  className="img-thumbnail rounded-circle"
                  width="50"
                  alt="waiting vendor"
                  onClick={() => setState({ isDetailOpen: true })}
                />
              </div>
              <div className="col auto">
                <h6
                  className="fw-bold mb-1"
                  onClick={() => setState({ isDetailOpen: true })}
                >
                  {name}{' '}
                  <span className="fa fa-info-circle ms-1 opacity-50 text-muted"></span>
                </h6>
                {/* <h6 className="small mb-1 fw-normal"><span className="fa fa-tags text-primary opacity-50 me-2"></span>{product.category.name}</h6> */}
                <h6 className="fs-6 mb-0">
                  <small className="fs-12 opacity-50">{t('price')}:</small>{' '}
                  <strong>
                    <span className="text-success">{t('$')}</span>{' '}
                    {lastBid.price === 0 ? (
                      <span className="text-muted">
                        {product.price_min} - {product.price_max}
                      </span>
                    ) : (
                      <span>{lastBid.price}</span>
                    )}
                  </strong>
                </h6>
              </div>
            </div>
            <Modal
              isOpen={isDetailOpen}
              onRequestClose={() => setState({ isDetailOpen: false })}
              contentLabel="Modal"
              className="reveal small p-3"
              ariaHideApp={false}
            >
              <div className="modal-container">
                {/* <h3 className="mb-3 small text-uppercase fw-bold text-primary">{t('order_detail')}</h3> */}
                <div className="card2">
                  <div className="card-body2">
                    <h6 className="text-uppercase small text-success mb-3">
                      {t('product_information_heading')}
                    </h6>
                    {image ? (
                      <img
                        src={image}
                        alt=""
                        className="w-100 img-thumbnail mb-2"
                      />
                    ) : (
                      <img
                        src={defaultItem}
                        alt=""
                        className="w-100 img-thumbnail mb-2 "
                      />
                    )}

                    <div className="d-flex justify-content-between">
                      <div>
                        <h5 className="fw-bold mb-1 text-success">{name}</h5>
                        <h6 className="small mb-2 fw-normal">
                          <span className="fa fa-tags text-primary opacity-50 me-2"></span>
                          {t(
                            product.category.name
                              .toLowerCase()
                              .replace(' ', '_'),
                          )}
                        </h6>
                      </div>
                      <h6 className="mb-2 fw-bold">
                        <span className="text-success">{t('$')}</span>{' '}
                        {product.price_min} - {product.price_max}
                      </h6>
                    </div>

                    <hr />

                    <h6>
                      <span className="fa fa-info-circle opacity-50 me-2"></span>
                      {t('modal_description_heading')}
                    </h6>
                    <p>{product.description}</p>

                    {product.gallery.length > 0 && (
                      <div className="gallery mb-3">
                        <h5 className="small">{t('modal_gallery_heading')}</h5>
                        <div className="row g-1">
                          {product.gallery.map((g, index) => (
                            <div className="col-6" key={g + index}>
                              <img
                                src={g}
                                alt={g + index}
                                className="img-thumbnail w-100"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  onClick={() => setState({ isDetailOpen: false })}
                  className="btn btn-sm btn-secondary w-100"
                >
                  Close
                </button>
              </div>
              <button
                className="btn btn-close close-reveal"
                onClick={() => setState({ isDetailOpen: false })}
              >
                &times;
              </button>
            </Modal>
          </div>
          {interval && interval > 0 && type === 3 && (
            <div className="card mb-3 shadow-sm border-2 border-warning">
              <div className="card-header p-2 px-3 bg-warning rounded-0">
                <h6 className="small m-0 text-light">
                  <span className="fa fa-refresh me-2"></span>
                  {t('continuous_order')}
                </h6>
              </div>
              <div className="card-body p-3">
                <div className="continuous-info">
                  <div className=" d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="small fw-bold mb-0">
                        {t('interval_text')}:
                      </h6>
                      <h6 className="text-primary fw-bold mb-0">
                        {t('every_text')}{' '}
                        <strong>
                          <u>{intervalCount}</u>
                        </strong>{' '}
                        {t(intervalText)}
                      </h6>
                    </div>
                    {user.username !== data.user.username && (
                      <div classname="d-none">
                        {lastStatus < 6 ? (
                          <button
                            className="btn btn-sm btn-secondary btn-disabled opacity-50 border rounded-3 bg-gradient"
                            disabled
                          >
                            <span className="text">
                              {t('initiate_next_continuous')}
                            </span>
                            <span className="fa fa-chevron-right ms-2"></span>
                          </button>
                        ) : (
                          <button className="btn btn-sm btn-success rounded-3 bg-gradient">
                            <span className="text">
                              {t('initiate_next_continuous')}
                            </span>
                            <span className="fa fa-chevron-right ms-2"></span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="horse-card mb-3 shadow-sm p-2 bg-light bg-gradient border rounded">
            {transaction_items.length > 0 && (
              <div className="horse-list d-flex flex-wrap align-items-center">
                {transaction_items.map(horse => (
                  <WidgetHorse data={horse} key={horse.id} />
                ))}
              </div>
            )}
          </div>

          <div className="buyer-card mb-3 shadow border rounded bg-white p-2">
            {isSeller ? (
              <WidgetBuyer data={data.user} />
            ) : (
              <WidgetSeller data={product.seller} />
            )}
          </div>
          <div className="delivery-card mb-3 shadow border rounded bg-white p-2">
            {data.latitude && data.longitude ? (
              <>
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                  onClick={() => setState({ isMapOpen: true })}
                >
                  <h6 className="small font-cabin text-success mb-0">
                    {t('delivery_address_label')}
                  </h6>
                  <button className="btn btn-sm fs-12 btn-light text-secondary border">
                    <span className="fa fa-map me-1"></span>
                    {t('view_button')}
                  </button>
                </div>
                {data.address && data.address !== '' ? (
                  <h6
                    className=" fw-light font-ssp small mb-2"
                    dangerouslySetInnerHTML={{
                      __html: data.address.replace(/\n/g, '<br/>'),
                    }}
                  />
                ) : (
                  <h6 className=" fw-light font-ssp small mb-2">
                    {data.user.address}, {data.user.city}
                  </h6>
                )}
                <Modal
                  isOpen={isMapOpen}
                  onRequestClose={() => setState({ isMapOpen: false })}
                  contentLabel="Modal"
                  className="reveal small p-3"
                  ariaHideApp={false}
                >
                  <h6 className=" font-cabin text-success mb-3">
                    <span className="fa fa-map-marker me-2 text-danger opacity-50"></span>
                    {t('delivery_address_label')}
                  </h6>
                  <div className="border border-warning p-2 mb-3 rounded alert-warning">
                    {data.address && data.address !== '' ? (
                      <h6
                        className="fs-14 mb-0"
                        dangerouslySetInnerHTML={{
                          __html: data.address.replace(/\n/g, '<br/>'),
                        }}
                      />
                    ) : (
                      <h6 className="fs-14 mb-0">
                        {data.user.address}, {data.user.city}
                      </h6>
                    )}
                  </div>
                  <div className="map-container border mb-3">
                    <div style={{ height: '240px' }} className="">
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                        }}
                        center={[data.latitude, data.longitude]}
                        zoom={15}
                        draggable={true}
                      >
                        <Marker
                          photo={data.user.photo}
                          lat={data.latitude}
                          lng={data.longitude}
                        />
                      </GoogleMapReact>
                    </div>
                  </div>
                  <button
                    onClick={() => setState({ isMapOpen: false })}
                    className="btn btn-sm btn-secondary w-100"
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-close close-reveal"
                    onClick={() => setState({ isMapOpen: false })}
                  >
                    &times;
                  </button>
                </Modal>
              </>
            ) : (
              <>
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                  onClick={() => setState({ isMapOpen: true })}
                >
                  <h6 className="small font-cabin text-success mb-0">
                    {t('delivery_address_label')}
                  </h6>
                  <button className="btn btn-sm fs-12 btn-light text-secondary border">
                    <span className="fa fa-map me-1"></span>
                    {t('view_button')}
                  </button>
                </div>
                <h6 className=" fw-light font-ssp small mb-2">
                  {data.user.address}, {data.user.city}
                </h6>
                <Modal
                  isOpen={isMapOpen}
                  onRequestClose={() => setState({ isMapOpen: false })}
                  contentLabel="Modal"
                  className="reveal small p-3"
                  ariaHideApp={false}
                >
                  <h6 className=" font-cabin text-success mb-2">
                    <span className="fa fa-map-marker me-2 text-danger opacity-50"></span>
                    {t('delivery_address_label')}
                  </h6>
                  <div className="map-container border mb-3">
                    <div style={{ height: '240px' }} className="">
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                        }}
                        center={[user.latitude, user.longitude]}
                        zoom={15}
                        draggable={true}
                      >
                        <Marker
                          photo={user.photo}
                          lat={user.latitude}
                          lng={user.longitude}
                        />
                      </GoogleMapReact>
                    </div>
                  </div>
                  <button
                    onClick={() => setState({ isMapOpen: false })}
                    className="btn btn-sm btn-secondary w-100"
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-close close-reveal"
                    onClick={() => setState({ isMapOpen: false })}
                  >
                    &times;
                  </button>
                </Modal>
              </>
            )}
          </div>

          {bids.length > 0 && (
            <div className="card bg-light bg-gradient mb-3 shadow">
              <div className="card-header bg-secondary bg-gradient py-2">
                <h6 className="m-0 text-light">{t('order_process_heading')}</h6>
              </div>
              <div className="card-body py-2">
                {lastStatus < 4 && (
                  <div className="d-flex justify-content-between bg-light bg-gradient py-2">
                    {(lastBid.user.username === user.username &&
                      lastBid.status < 3) ||
                    lastBid.status === 3 ? (
                      <button
                        className="btn w-50 btn-sm btn-disabled btn-secondary shadow-sm me-2"
                        disabled
                      >
                        {t('confirm_button')}
                      </button>
                    ) : (
                      <>
                        {isSeller && type === 2 && lastBid.status === 1 ? (
                          <button
                            className="btn w-50 btn-sm btn-disabled btn-secondary shadow-sm me-2"
                            disabled
                          >
                            {t('confirm_button')}
                          </button>
                        ) : (
                          <button
                            className="btn w-50 btn-sm shadow-sm me-2  btn-success"
                            onClick={() =>
                              setState({ isOpen: true, action: 1 })
                            }
                          >
                            {t('confirm_button')}
                          </button>
                        )}
                      </>
                    )}
                    <button
                      className="btn w-50 btn-sm me-2 shadow-sm  btn-warning"
                      onClick={() => setState({ isOpen: true, action: 2 })}
                    >
                      {t('change_button')}
                    </button>
                    <button
                      className="btn w-50 btn-sm shadow-sm  btn-danger"
                      onClick={() => setState({ isOpen: true, action: 3 })}
                    >
                      {t('cancel_button')}
                    </button>
                  </div>
                )}
                {invoice && !isSeller && (
                  <div
                    className={`order-item position-relative border rounded my-2 p-3 shadow text-center bg-white `}
                  >
                    <button
                      className="mb-0 btn btn-primary w-100"
                      onClick={() => setState({ isConfirm: true })}
                    >
                      <span className="fa fa-check me-2"></span>
                      {t('confirm_payment')}
                    </button>
                    <Modal
                      isOpen={isConfirm}
                      onRequestClose={() => setState({ isConfirm: false })}
                      contentLabel="Modal"
                      className="reveal small p-3"
                      ariaHideApp={false}
                    >
                      <div className="modal-s">
                        <h6 className="text-primary mb-3">
                          {t('please_confirm_heading')}
                        </h6>
                        <p>{t('please_confirm_text')}</p>
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-secondary btn-sm w-50"
                            onClick={() => setState({ isConfirm: false })}
                          >
                            {t('cancel_button')}
                          </button>
                          <button
                            className="btn btn-primary ms-2 btn-sm w-75"
                            onClick={() => processOrder(paymentStatus)}
                          >
                            {t('confirm_payment')}
                          </button>
                        </div>
                      </div>
                      <button
                        className="btn btn-close close-reveal"
                        onClick={() => setState({ isConfirm: false })}
                      >
                        &times;
                      </button>
                    </Modal>
                  </div>
                )}
                {(invoice || paymentConfirm) && isSeller && (
                  <div
                    className={`order-item position-relative border rounded my-2 p-3 shadow text-center bg-white `}
                  >
                    <button
                      className="mb-0 btn btn-primary w-100"
                      onClick={() => setState({ isConfirm: true })}
                    >
                      <span className="fa fa-check me-2"></span>
                      {t('complete_order')}
                    </button>
                    <Modal
                      isOpen={isConfirm}
                      onRequestClose={() => setState({ isConfirm: false })}
                      contentLabel="Modal"
                      className="reveal small p-3"
                      ariaHideApp={false}
                    >
                      <div className="modal-s">
                        <h6 className="text-primary mb-3">
                          {t('please_confirm_complete_heading')}
                        </h6>
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-secondary btn-sm w-50"
                            onClick={() => setState({ isConfirm: false })}
                          >
                            {t('cancel_button')}
                          </button>
                          <button
                            className="btn btn-primary ms-2 btn-sm w-75"
                            onClick={() => processOrder(completeStatus)}
                          >
                            {t('complete_order')}
                          </button>
                        </div>
                      </div>
                      <button
                        className="btn btn-close close-reveal"
                        onClick={() => setState({ isConfirm: false })}
                      >
                        &times;
                      </button>
                    </Modal>
                  </div>
                )}
                {completed && (
                  <div
                    className={`order-item position-relative border rounded my-2 p-3 shadow text-center bg-white `}
                  >
                    <h6 className="mb-0 text-success">
                      🎉 {t('order_has_been_completed_status')} 🎉
                    </h6>
                  </div>
                )}
                {cancelled && (
                  <div
                    className={`order-item position-relative border border-danger rounded my-2 p-3 shadow text-center bg-white `}
                  >
                    <h6 className="mb-0 text-danger">
                      <span className="fa fa-warning me-2"></span>
                      {t('order_cancelled')}
                    </h6>
                  </div>
                )}
                {confirmed && (
                  <div
                    className={`order-item position-relative border border-2 border-primary rounded my-2 p-3 shadow text-center bg-light `}
                  >
                    {!delivered ? (
                      <h6 className="mb-0">
                        <span className="fa fa-clock-o me-2"></span>
                        {t('waiting_for_delivery')}..
                      </h6>
                    ) : (
                      <>
                        {isSeller ? (
                          <Link
                            to={`/seller/order/${orderId}/invoice`}
                            className="btn btn-primary w-100"
                          >
                            <span className="fa fa-send me-2"></span>
                            {t('create_final_invoice')}
                          </Link>
                        ) : (
                          <h6 className="mb-0 small text-primary">
                            <span className="fa fa-clock-o me-2"></span>
                            {t('waiting_seller_send_invoice')}
                          </h6>
                        )}
                      </>
                    )}
                  </div>
                )}
                {bids.map((trans, index) => {
                  const { datetime, note, price } = trans;
                  const isBuyer = trans.user.username === user.username;
                  const prevIndex = bids.length > 1 ? bids.length - 1 : 0;
                  if (trans.status === 4) {
                    return (
                      <div
                        key={trans.id}
                        className={`position-relative order-item border rounded-3 p-2 my-2 bg-white shadow border-2 border-secondary ${
                          lastStatus === 6 ? 'opacity-50' : ''
                        } `}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="small mb-1 text-primary">
                            <span>{t('invoice_sent_status')}</span>
                            <span className="ms-2 font-ssp fs-12 text-muted">
                              <Moment utc fromNow local locale={lang}>
                                {trans.created_at}
                              </Moment>
                            </span>
                          </h6>
                          {isBuyer ? (
                            <h6 className="fs-12 p-1 lh-1 rounded justify-items-end bg-warning bg-gradient">
                              {trans.user.name}
                            </h6>
                          ) : (
                            <h6 className="fs-12 p-1 lh-1 rounded justify-items-end bg-secondary text-light bg-gradient">
                              {trans.user.name}
                            </h6>
                          )}
                        </div>
                        <h6 className="small mb-1">
                          <span>{t('final_price')}</span>
                          <span className="mx-1 fa text-success fa-long-arrow-right"></span>
                          <strong>
                            <span className="text-dark">
                              {t('$')}
                              {price === 0 ? (
                                <span className="text-muted">
                                  {product.price_min} - {product.price_max}
                                </span>
                              ) : (
                                <span>{price}</span>
                              )}
                            </span>
                          </strong>
                        </h6>
                        {isSeller ? (
                          <div className="d-flex mt-3">
                            {invoiceFile && invoiceFile !== '' ? (
                              <a
                                className="btn p-1 py-0 btn-light btn-sm fs-12 me-2 border-secondary"
                                href={invoiceFile}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className="fa fa-file-pdf-o text-danger me-1"></span>
                                {t('download_invoice')}
                              </a>
                            ) : (
                              <button
                                className="btn p-1 py-0 btn-light btn-sm fs-12 me-2 border-secondary"
                                onClick={generatePdf}
                              >
                                <span className="fa fa-file-pdf-o text-danger me-1"></span>
                                {t('download_invoice')}
                              </button>
                            )}
                            <button
                              className="btn p-1 py-0 btn-outline-primary btn-sm fs-12"
                              onClick={sendEmail}
                            >
                              <span className="fa fa-send me-1"></span>
                              {t('resend_invoice')}
                            </button>
                          </div>
                        ) : (
                          <div className="mt-2 mb-0 alert-warning p-1 fs-14 border text-dark rounded lh-1">
                            <p className="mb-2">
                              <span className="fa fa-quote-left me-1 text-muted opacity-50 fs-10"></span>
                              {t('invoice_sent_status_info')}
                            </p>
                            {invoiceFile && invoiceFile !== '' ? (
                              <a
                                className="btn p-1 py-0 btn-light btn-sm fs-12 me-2 border-secondary"
                                href={invoiceFile}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className="fa fa-file-pdf-o text-danger me-1"></span>
                                {t('download_invoice')}
                              </a>
                            ) : (
                              <button
                                className="btn p-1 py-0 btn-light btn-sm fs-12 me-2 border-secondary"
                                onClick={generatePdf}
                              >
                                <span className="fa fa-file-pdf-o text-danger me-1"></span>
                                {t('download_invoice')}
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  }
                  if (trans.status === 5) {
                    return (
                      <div
                        key={trans.id}
                        className={`position-relative order-item border rounded-3 p-2 my-2 bg-white shadow border-2 border-success ${
                          lastStatus === 6 ? 'opacity-50' : ''
                        } `}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="small mb-1 text-primary">
                            <span>{t('payment_confirmation_status')}</span>
                            <span className="ms-2 font-ssp fs-12 text-muted">
                              <Moment utc fromNow local locale={lang}>
                                {trans.created_at}
                              </Moment>
                            </span>
                          </h6>
                          {isBuyer ? (
                            <h6 className="fs-12 p-1 lh-1 rounded justify-items-end bg-warning bg-gradient">
                              {trans.user.name}
                            </h6>
                          ) : (
                            <h6 className="fs-12 p-1 lh-1 rounded justify-items-end bg-secondary text-light bg-gradient">
                              {trans.user.name}
                            </h6>
                          )}
                        </div>
                        <h6 className="small mb-1">
                          <span>{t('final_price')}</span>
                          <span className="mx-1 fa text-success fa-long-arrow-right"></span>
                          <strong>
                            <span className="text-dark">
                              {t('$')}
                              {price === 0 ? (
                                <span className="text-muted">
                                  {product.price_min} - {product.price_max}
                                </span>
                              ) : (
                                <span>{price}</span>
                              )}
                            </span>
                          </strong>
                        </h6>
                        {!isBuyer && (
                          <p className=" mt-2 mb-0 bg-light p-1 fs-14 border text-dark rounded lh-1">
                            <span className="fa fa-quote-left me-1 text-muted opacity-50 fs-10"></span>
                            {t('payment_confirmation_status_seller')}
                          </p>
                        )}
                        {/* <div className="d-flex mt-3">
													<button className="btn p-1 py-0 btn-light btn-sm fs-12 me-2 border-secondary"><span className="fa fa-file-pdf-o text-danger me-1"></span>{t('download_invoice')}</button>
													<button className="btn p-1 py-0 btn-outline-primary btn-sm fs-12"><span className="fa fa-send me-1"></span>{t('resend_invoice')}</button>
												</div> */}
                      </div>
                    );
                  }
                  if (trans.status === 6) return null;
                  return (
                    <div
                      key={trans.id}
                      className={`position-relative order-item border rounded-3 p-2 my-2 bg-white shadow border-2 border-secondary ${
                        index === 0 && isBuyer ? 'opacity-100' : 'opacity-75'
                      } ${cancelled || completed ? 'opacity-75' : ''} ${
                        confirmed && !delivered ? 'opacity-75' : ''
                      } ${lastStatus === 6 ? 'opacity-50' : ''} `}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="small mb-1">
                          <span
                            className={`${
                              trans.status < 3 ? 'text-warning' : 'text-success'
                            }`}
                          >
                            {t(stats[trans.status - 1].status)}
                          </span>
                          <span className="ms-2 font-ssp fs-12 text-muted">
                            <Moment utc fromNow local locale={lang}>
                              {trans.created_at}
                            </Moment>
                          </span>
                        </h6>
                        {isBuyer ? (
                          <h6 className="fs-12 p-1 lh-1 rounded justify-items-end bg-warning bg-gradient">
                            {trans.user.name}
                          </h6>
                        ) : (
                          <h6 className="fs-12 p-1 lh-1 rounded justify-items-end bg-secondary text-light bg-gradient">
                            {trans.user.name}
                          </h6>
                        )}
                      </div>
                      {data.type === 2 && trans.status === 1 ? (
                        <h6 className="small mb-1 fw-normal text-dark p-1 alert-warning border rounded px-2">
                          <em>{t('open_date_text')}</em>
                        </h6>
                      ) : (
                        <h6 className={`small mb-1`}>
                          <span className="text-dark text-uppercase small w-24 fs-6 me-1 text-center opacity-75">
                            <span className="fa fa-clock-o"></span>
                          </span>
                          {index < bids.length - 1 ? (
                            <strong>
                              {bids[prevIndex].datetime !== datetime ? (
                                <>
                                  <Moment
                                    format="MMM DD - HH:mm"
                                    locale={lang}
                                    className="small fw-normal text-muted opacity-50 text-decoration-linough"
                                  >
                                    {bids[prevIndex].datetime}
                                  </Moment>
                                  <span className="mx-1 fa text-success fa-long-arrow-right"></span>
                                  <Moment
                                    format="MMM DD - HH:mm"
                                    className="text-dark "
                                    locale={lang}
                                  >
                                    {datetime}
                                  </Moment>
                                </>
                              ) : (
                                <Moment format="MMM DD - HH:mm" locale={lang}>
                                  {datetime}
                                </Moment>
                              )}
                            </strong>
                          ) : (
                            <Moment format="MMM DD - HH:mm" locale={lang}>
                              {datetime}
                            </Moment>
                          )}
                        </h6>
                      )}

                      <h6 className="small mb-1">
                        <span className="text-dark text-uppercase text-center small me-1 w-24 fs-6 opacity-75">
                          {t('$')}
                        </span>
                        {index < bids.length - 1 ? (
                          <strong>
                            {bids[prevIndex].price !== price ? (
                              <span>
                                <span className="text-muted opacity-50 fw-normal small">
                                  {bids[prevIndex].price}
                                </span>
                                <span className="mx-1 fa text-success fa-long-arrow-right"></span>
                                <span className="text-dark">{price}</span>
                              </span>
                            ) : (
                              <span>
                                {price === 0 ? (
                                  <span className="text-muted">
                                    {product.price_min} - {product.price_max}
                                  </span>
                                ) : (
                                  <span>{price}</span>
                                )}
                              </span>
                            )}
                          </strong>
                        ) : (
                          <strong>
                            {price === 0 ? (
                              <span className="text-muted">
                                {product.price_min} - {product.price_max}
                              </span>
                            ) : (
                              <span>{price}</span>
                            )}
                          </strong>
                        )}
                      </h6>
                      {note && note !== '' && (
                        <div
                          className="py-1 bg-gradient bg-light px-2 border-top"
                          style={{
                            margin: '0 -.5rem -.5rem',
                            borderRadius: '0 0 4px 4px',
                          }}
                        >
                          <p className="small mb-0 text-dark">
                            <span className="me-1 text-center w-24 fs-12 text-muted opacity-50">
                              <span className="fa fa-commenting-o"></span>
                            </span>
                            <em>{note}</em>
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <Modal
            isOpen={isOpen}
            onRequestClose={() => setState({ isOpen: false })}
            contentLabel="Modal"
            className="reveal medium p-3"
            ariaHideApp={false}
          >
            <div className="modal-container">
              {action === 1 && (
                <div className="action-seller">
                  <h6 className="text-uppercase small mb-4 text-success fw-bold">
                    {t('confirm_order_heading')}
                  </h6>
                  <div className="order-item border p-2 my-3 bg-light rounded shadow">
                    <div className="row g-3 align-items-center">
                      <div className="col-3">
                        <img
                          src={image}
                          alt={name}
                          className="img-thumbnail shadow-sm"
                        />
                      </div>
                      <div className="col-9">
                        <h6 className="small lh-1 mb-1 fw-bold text-dark">
                          {name}
                        </h6>
                        <h6 className="small mb-1 ">
                          <span className="fa fa-clock-o text-success me-1"></span>{' '}
                          <span className="text-capitalize text-dark">
                            <Moment locale={lang} format="MMM DD - HH:mm">
                              {lastBid.datetime}
                            </Moment>
                          </span>
                        </h6>
                        <h6 className="small mb-0">
                          <strong className="text-capitalize text-dark">
                            <span className="text-success me-1">{t('$')}</span>{' '}
                            {lastBid.price === 0 ? (
                              <span className="text-muted">
                                {product.price_min} - {product.price_max}
                              </span>
                            ) : (
                              <span>{lastBid.price}</span>
                            )}
                          </strong>
                        </h6>
                      </div>
                    </div>
                    {/* {lastBid.note && lastBid.note !== '' && (
												<>
													<h6 className="mb-0 mt-2 small mb-1"><span className="text-success fa fa-comment me-1"></span> {t('notes_label')}: </h6>
													<p className="text-muted small"><em>{lastBid.note}</em></p>
												</>
											)} */}
                  </div>
                  <div className="order-item border p-2 my-3 bg-light rounded shadow">
                    <h6 className="text-uppercase small text-success mb-2">
                      {t('buyer')}
                    </h6>
                    <div className="row g-3 align-items-center">
                      {user.photo && (
                        <div className="col-3">
                          <img
                            src={user.photo}
                            className="img-thumbnail rounded-circle"
                            alt={user.name ? user.name : user.username}
                          />
                        </div>
                      )}
                      <div className="col-9">
                        <h6 className="small mb-0">
                          {user.name ? user.name : user.username}
                        </h6>
                        {/* {user.phone && (
														<h6 className="small mt-1 mb-0"><span className="fa fa-phone w-24 text-muted opacity-50 me-2"></span>{user.phone}</h6>
													)} */}
                        {user.address && (
                          <h6 className="small mt-1 mb-0">
                            <span className="fa fa-map-marker text-danger opacity-50 me-1"></span>
                            {user.address}
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="my-notes mb-0">
                      <div className="form-floating">
                        <textarea
                          className="form-control "
                          name="notes"
                          id="additionalInfo"
                          rows="3"
                          placeholder={t('write_a_note_label')}
                          value={notes}
                          onChange={e => setState({ notes: e.target.value })}
                          style={{ height: '120px' }}
                        ></textarea>
                        <label
                          className="text-success font-cabin small"
                          htmlFor="additionalInfo"
                        >
                          {t('write_a_note_label')}
                        </label>
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn btn-success w-100 shadow"
                    onClick={() => processOrder(confirmStatus)}
                  >
                    {t('confirm_button')}
                  </button>
                </div>
              )}

              {action === 2 && (
                <div className="action-seller">
                  <h6 className="text-uppercase small mb-4 text-warning fw-bold">
                    {t('change_order_heading')}
                  </h6>
                  <div className="order-item border p-2 my-3 bg-white rounded shadow d-none">
                    <div className="row g-3 align-items-center">
                      <div className="col-3">
                        <img
                          src={image}
                          alt={name}
                          className="img-thumbnail shadow-sm"
                        />
                      </div>
                      <div className="col-9">
                        <h6 className="small lh-1 mb-1 fw-bold text-dark">
                          {name}
                        </h6>
                        <h6 className="small mb-1 ">
                          <span className="fa fa-clock-o text-success me-1"></span>{' '}
                          <span className="text-capitalize text-dark">
                            <Moment locale={lang} format="MMM DD - HH:mm">
                              {lastBid.datetime}
                            </Moment>
                          </span>
                        </h6>
                        <h6 className="small mb-0">
                          <strong className="text-capitalize text-dark">
                            <span className="text-success me-1">{t('$')}</span>{' '}
                            {lastBid.price === 0 ? (
                              <span className="text-muted">
                                {product.price_min} - {product.price_max}
                              </span>
                            ) : (
                              <span>{lastBid.price}</span>
                            )}
                          </strong>
                        </h6>
                      </div>
                    </div>
                    {lastBid.note && lastBid.note !== '' && (
                      <div
                        style={{
                          margin: '0 -.5rem -.5rem',
                          borderRadius: '0 0 4px 4px',
                        }}
                        className="mt-2 bg-light px-2 py-1"
                      >
                        <h6 className="mb-1 small fw-normal">
                          <span className=" opacity-50 fa fa-commenting-o me-1"></span>{' '}
                          <em>{lastBid.note}</em>
                        </h6>
                      </div>
                    )}
                  </div>
                  <div className="order-item border p-2 my-3 bg-light rounded shadow d-none">
                    <h6 className="text-uppercase small text-success mb-2">
                      {t('buyer')}
                    </h6>
                    <div className="row g-3 align-items-center">
                      {user.photo && (
                        <div className="col-4">
                          <img
                            src={user.photo}
                            alt={user.name ? user.name : user.username}
                          />
                        </div>
                      )}
                      <div className="col-auto">
                        <h6 className="small mb-0">
                          <span className="fa w-24 fa-user text-muted opacity-50 me-2"></span>
                          {user.name ? user.name : user.username}
                        </h6>
                        {user.phone && (
                          <h6 className="small mt-1 mb-0">
                            <span className="fa fa-phone w-24 text-muted opacity-50 me-2"></span>
                            {user.phone}
                          </h6>
                        )}
                        {user.address && (
                          <h6 className="small mt-1 mb-0">
                            <span className="fa fa-map-marker w-24 text-muted opacity-50 me-2"></span>
                            {user.address}
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      className="small text-success font-cabin"
                      htmlFor="requestedPrice"
                    >
                      {t('requested_price_label')}
                      <span className="text-danger">*</span>{' '}
                      <small className="text-body text-capitalize">
                        ( {product.price_min * horseCount} -{' '}
                        {product.price_max * horseCount} )
                      </small>
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">{t('$')}</span>
                      <input
                        type="number"
                        className="form-control text-end"
                        id="requestedPrice"
                        name="requestedPrice"
                        pattern="\d*"
                        min="0"
                        placeholder={lastBid.price}
                        value={price}
                        onChange={e => setState({ price: e.target.value })}
                        required
                      />
                      <span className="input-group-text">.00</span>
                    </div>
                    <label
                      className="small text-success font-cabin"
                      htmlFor="datetime"
                    >
                      {t('preferred_date_time_label')}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="mb-3">
                      <DatePicker
                        showTimeSelect
                        selected={date}
                        locale={lang}
                        onChange={date => setState({ date: date })}
                        customInput={<CustomInputCalendar />}
                        dateFormat="MMM dd, yyyy - h:mm a"
                        className="w-100"
                        filterDate={date => {
                          let valid = moment(date).isAfter(moment());
                          // if (moment(date).isoWeekday() >= 6) { valid = false }
                          return valid;
                        }}
                        excludeTimes={[
                          new Date().setHours(1, 0),
                          new Date().setHours(1, 30),
                          new Date().setHours(2, 0),
                          new Date().setHours(2, 30),
                          new Date().setHours(3, 0),
                          new Date().setHours(3, 30),
                          new Date().setHours(4, 0),
                          new Date().setHours(4, 30),
                          new Date().setHours(5, 0),
                          new Date().setHours(5, 30),
                          new Date().setHours(6, 0),
                          new Date().setHours(6, 30),
                          new Date().setHours(21, 30),
                          new Date().setHours(22, 0),
                          new Date().setHours(22, 30),
                          new Date().setHours(23, 0),
                          new Date().setHours(23, 30),
                          new Date().setHours(24, 0),
                          new Date().setHours(24, 30),
                        ]}
                      />
                    </div>
                    {/* <Datetime id="datepicker" isValidDate={valid} locale={lang} className="rdt-input-group" inputProps={{ readOnly: true, className: 'form-control rounded-end datetime-date' }} initialValue={date} dateFormat="YYYY-MM-DD" closeOnSelect={true} timeFormat={false} onChange={(e) => {
														const d = e.format('YYYY-MM-DD');
														const t = time;
														const dt = `${d} ${t}`;
														setState({ date: d, newDatetime: dt })
													}} /> */}

                    {/* <DateTimePicker name="datetime" onChange={e => setState({ date: e })} value={date} format="y-MM-dd HH:mm" calendarIcon={<span className="fa fa-calendar" />} clearIcon={null} className="d-flex w-100 mb-3 bg-white rounded" showLeadingZeros={true} required /> */}
                    <div className="my-notes mb-0">
                      <div className="form-floating">
                        <textarea
                          className="form-control "
                          name="notes"
                          id="additionalInfo"
                          rows="3"
                          placeholder={t('write_a_note_label')}
                          value={notes}
                          onChange={e => setState({ notes: e.target.value })}
                          style={{ height: '120px' }}
                        ></textarea>
                        <label
                          className="text-success font-cabin small"
                          htmlFor="additionalInfo"
                        >
                          {t('write_a_note_label')}
                        </label>
                      </div>
                    </div>
                  </div>
                  {isBidding ? (
                    <button
                      className="btn btn-warning btn-disabled w-100 shadow"
                      disabled
                    >
                      {t('change_button')}
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning w-100 shadow"
                      onClick={() => processOrder(changeStatus)}
                    >
                      {t('change_button')}
                    </button>
                  )}
                </div>
              )}

              {action === 3 && (
                <div className="action-seller">
                  <h6 className="text-uppercase small mb-4 text-danger fw-bold">
                    {t('cancel_order_heading')}
                  </h6>
                  <div className="order-item border p-2 my-3 bg-light rounded shadow">
                    <div className="row g-3 align-items-center">
                      <div className="col-3">
                        <img
                          src={image}
                          alt={name}
                          className="img-thumbnail shadow-sm"
                        />
                      </div>
                      <div className="col-9">
                        <h6 className="small lh-1 mb-1 fw-bold text-dark">
                          {name}
                        </h6>
                        <h6 className="small mb-1 ">
                          <span className="fa fa-clock-o text-success me-1"></span>{' '}
                          <span className="text-capitalize text-dark">
                            <Moment locale={lang} format="MMM DD - HH:mm">
                              {lastBid.datetime}
                            </Moment>
                          </span>
                        </h6>
                        <h6 className="small mb-0">
                          <strong className="text-capitalize text-dark">
                            <span className="text-success me-1">{t('$')}</span>{' '}
                            {lastBid.price === 0 ? (
                              <span className="text-muted">
                                {product.price_min} - {product.price_max}
                              </span>
                            ) : (
                              <span>{lastBid.price}</span>
                            )}
                          </strong>
                        </h6>
                      </div>
                    </div>
                    {/* {lastBid.note && lastBid.note !== '' && (
												<>
													<h6 className="mb-0 mt-2 small mb-1"><span className="text-success fa fa-comment me-1"></span> {t('notes_label')}: </h6>
													<p className="text-muted small"><em>{lastBid.note}</em></p>
												</>
											)} */}
                  </div>
                  <div className="order-item border p-2 my-3 bg-light rounded shadow">
                    <h6 className="text-uppercase small text-success mb-2">
                      {t('buyer')}
                    </h6>
                    <div className="row g-3 align-items-center">
                      {user.photo && (
                        <div className="col-3">
                          <img
                            src={user.photo}
                            className="img-thumbnail rounded-circle"
                            alt={user.name ? user.name : user.username}
                          />
                        </div>
                      )}
                      <div className="col-9">
                        <h6 className="small mb-0">
                          {user.name ? user.name : user.username}
                        </h6>
                        {/* {user.phone && (
														<h6 className="small fs-12 mt-1 mb-0"><span className="fa fa-phone w-24 text-muted opacity-50 me-2"></span>{user.phone}</h6>
													)} */}
                        {user.address && (
                          <h6 className="small fs-12 mt-1 mb-0">
                            <span className="fa fa-map-marker text-danger opacity-50 me-1"></span>
                            {user.address}
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="my-notes mb-0">
                      <div className="form-floating">
                        <textarea
                          className="form-control "
                          name="notes"
                          id="additionalInfo"
                          placeholder={t('write_a_note_label')}
                          value={notes}
                          onChange={e => setState({ notes: e.target.value })}
                          style={{ height: '120px' }}
                        ></textarea>
                        <label
                          className="text-success font-cabin small"
                          htmlFor="additionalInfo"
                        >
                          {t('write_a_note_label')}
                        </label>
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn btn-danger w-100 shadow"
                    onClick={() => processOrder(cancelStatus)}
                  >
                    {t('cancel_button')}
                  </button>
                </div>
              )}

              {action === 4 && (
                <div className="action-seller">
                  <h6 className="text-uppercase small mb-4 text-success fw-bold">
                    {t('complete_order_heading')}
                  </h6>
                  <div className="order-item border p-2 my-3 bg-light rounded shadow">
                    <div className="row g-3 align-items-center">
                      <div className="col-3">
                        <img
                          src={image}
                          alt={name}
                          className="img-thumbnail shadow-sm"
                        />
                      </div>
                      <div className="col-9">
                        <h6 className="small lh-1 mb-1 fw-bold text-dark">
                          {name}
                        </h6>
                        <h6 className="small mb-1 ">
                          <span className="fa fa-clock-o text-success me-1"></span>{' '}
                          <span className="text-capitalize text-dark">
                            <Moment locale={lang} format="MMM DD - HH:mm">
                              {lastBid.datetime}
                            </Moment>
                          </span>
                        </h6>
                        <h6 className="small mb-0">
                          <strong className="text-capitalize text-dark">
                            <span className="text-success me-1">{t('$')}</span>{' '}
                            {lastBid.price === 0 ? (
                              <span className="text-muted">
                                {product.price_min} - {product.price_max}
                              </span>
                            ) : (
                              <span>{lastBid.price}</span>
                            )}
                          </strong>
                        </h6>
                      </div>
                    </div>
                    {/* {lastBid.note && lastBid.note !== '' && (
												<>
													<h6 className="mb-0 mt-2 small mb-1"><span className="text-success fa fa-comment me-1"></span> {t('notes_label')}: </h6>
													<p className="text-muted small"><em>{lastBid.note}</em></p>
												</>
											)} */}
                  </div>
                  <div className="order-item border p-2 my-3 bg-light rounded shadow">
                    <h6 className="text-uppercase small text-success mb-2">
                      {t('buyer')}
                    </h6>
                    <div className="row g-3 align-items-center">
                      {user.photo && (
                        <div className="col-3">
                          <img
                            src={user.photo}
                            className="img-thumbnail rounded-circle"
                            alt={user.name ? user.name : user.username}
                          />
                        </div>
                      )}
                      <div className="col-9">
                        <h6 className="small mb-0">
                          {user.name ? user.name : user.username}
                        </h6>
                        {/* {user.phone && (
														<h6 className="small mt-1 mb-0"><span className="fa fa-phone w-24 text-muted opacity-50 me-2"></span>{user.phone}</h6>
													)} */}
                        {user.address && (
                          <h6 className="small mt-1 mb-0">
                            <span className="fa fa-map-marker text-danger opacity-50 me-1"></span>
                            {user.address}
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="my-notes mb-0">
                      <div className="form-floating">
                        <textarea
                          className="form-control "
                          name="notes"
                          id="additionalInfo"
                          rows="3"
                          placeholder={t('write_a_note_label')}
                          value={notes}
                          onChange={e => setState({ notes: e.target.value })}
                          style={{ height: '120px' }}
                        ></textarea>
                        <label
                          className="text-success font-cabin small"
                          htmlFor="additionalInfo"
                        >
                          {t('write_a_note_label')}
                        </label>
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn btn-success w-100 shadow"
                    onClick={() => processOrder(completeStatus)}
                  >
                    {t('complete_order_button')}
                  </button>
                </div>
              )}
            </div>
            <button
              className="btn btn-close close-reveal"
              onClick={() => setState({ isOpen: false })}
            >
              &times;
            </button>
          </Modal>
        </div>
      </div>
    </div>
  );
}

const Marker = ({ photo }) => (
  <div
    className="map-marker text-center"
    style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
  >
    <img
      src={photo}
      className="rounded-circle"
      width="20"
      height="20"
      alt="marker"
    />
  </div>
);
