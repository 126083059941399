import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { useAuthState } from '../../context';
import { getComCatalog } from '../../libs/apis';
import Loader from '../../components/global/Loader';
import defaultItem from '../../assets/images/user.png';

function ViewCatalog() {
  const { t } = useTranslation();
  const history = useHistory();
  const userDetails = useAuthState();
  const { role } = userDetails;

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'company') {
    history.push('/login?redirect=/company&u=company');
  }
  const { profile, token, tokenId } = userDetails;
  const { name, legal_id } = profile;

  const initialState = {
    data: [],
    loading: false,
    error: false,
    errorMessage: '',
    isEmpty: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, loading, isEmpty } = state;

  useEffect(() => {
    const getData = async () => {
      setState({ loading: true, error: false, isEmpty: false });
      const payload = {
        token,
        tokenId,
      };
      await getComCatalog(payload)
        .then(res => {
          if (res.status === 200) {
            setState({ data: res.data.data });
            if (res.data.data.length === 0) {
              setState({ isEmpty: true });
            }
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ error: true });
        })
        .finally(() => setState({ loading: false }));
    };
    getData();
  }, [token, tokenId]);

  return (
    <div className="admin-container">
      <Helmet>
        <title>View Sellers | {name} Company</title>
      </Helmet>
      <div className="admin-header bg-danger bg-gradient py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="admin-header-content d-flex justify-content-between align-items-center">
                <button
                  className="btn text-light m-0 btn-sm"
                  onClick={() => history.goBack()}
                >
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">Back</span>
                </button>
                <h6 className="text-light text-right m-0">
                  {name} Company
                  <br />
                  <small>{legal_id}</small>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="">
                <div className="page-header mb-3">
                  <h1 className="fs-5">View Catalog</h1>
                </div>

                {!loading && data.length > 0 ? (
                  <div className="seller-list">
                    {data.map((product, index) => {
                      const {
                        product_id,
                        product_category_name,
                        product_name,
                        product_thumbnail_url,
                        seller_id,
                        price_min,
                        price_max,
                      } = product;
                      return (
                        <div
                          className="seller-item bg-light bg-gradient mb-2 shadow-sm rounded border p-3 position-relative"
                          key={product_id + index}
                        >
                          <div className="row align-items-center">
                            <div className="col-3">
                              <div className="seller-cover">
                                {product_thumbnail_url ? (
                                  <img
                                    src={product_thumbnail_url}
                                    alt="seller"
                                    className="img-thumbnail "
                                  />
                                ) : (
                                  <img
                                    src={defaultItem}
                                    alt="seller"
                                    className="img-thumbnail "
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-9">
                              <h6 className="text-primary mb-2">
                                <Link
                                  to={`/company/seller/${seller_id}/product/${product_id}`}
                                  className="stretched-link"
                                >
                                  {product_name}
                                </Link>
                              </h6>
                              <h6 className="mb-1 text-muted small fw-normal">
                                <span className="fa fa-tags text-primary me-1"></span>{' '}
                                {product_category_name}
                              </h6>
                              <h6 className="small mb-0 mt-1">
                                <span className="text-success me-1">
                                  {t('$')}
                                </span>{' '}
                                {price_min} - {price_max}
                              </h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="text-center py-3">
                    {isEmpty ? (
                      <div>
                        <h3>
                          <span className="fa fa-warning text-warning"></span>
                        </h3>
                        <h6>No product in your catalog</h6>
                        <Link to="/company/seller" className="btn btn-primary">
                          Start Adding Product
                        </Link>
                      </div>
                    ) : (
                      <Loader size={32} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewCatalog;
