import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

export default function InternalHeader({
  go = null,
  title = 'Luokki Admin',
  children = null,
}) {
  const history = useHistory();
  return (
    <div className="admin-header sticky-top shadow-sm bg-success bg-gradient py-3">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="admin-header-content d-flex justify-content-between align-items-center">
              {go ? (
                <Link className="btn text-light m-0 btn-sm" to={go}>
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">Back</span>
                </Link>
              ) : (
                <button
                  className="btn text-light m-0 btn-sm"
                  onClick={() => history.goBack()}
                >
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">Back</span>
                </button>
              )}
              <h6 className="text-light m-0">{title}</h6>
            </div>
          </div>
        </div>
      </div>{' '}
      <Helmet>
        <title>{title} | Luokki</title>
      </Helmet>
    </div>
  );
}
