import React, { useState, useEffect } from 'react';
import { Redirect, useParams, useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InternalHeader from '../../components/global/InternalHeader';
import Loader from '../../components/global/Loader';
import TransactionHistory from '../../components/global/TransactionHistory';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import { getItemDetail, deleteItem } from '../../libs/apis';

SwiperCore.use([Navigation]);

function ItemPage() {
  const { t } = useTranslation();
  const { itemId } = useParams();
  const history = useHistory();
  const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    message: '',
    isDeleting: false,
    isDeleteError: false,
    deleteMessage: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, isLoading, isError } = state;

  useEffect(() => {
    const getItemData = async () => {
      setState({ isLoading: true, isError: false, message: '' });
      const payload = {
        id: itemId,
      };
      await getItemDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            setState({ data: resp.data.response });
          } else {
            setState({ isError: true, message: resp.data.error.message });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true, messsage: err.error.message });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getItemData();
  }, [itemId]);

  if (!itemId) return <Redirect to="/profile" />;
  if (isLoading) return <Loader size="48" center />;
  if (isError) return <p>Error</p>;

  const {
    name,
    image,
    description,
    birthdate,
    withers,
    job,
    breed,
    gallery,
  } = data;

  const removeHorse = async () => {
    setState({ isDeleting: true, isDeleteError: false, deleteMessage: '' });
    const payload = {
      id: itemId,
    };
    await deleteItem(payload)
      .then(resp => {
        if (resp.status === 200) {
          history.push('/profile');
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isDeleteError: true, deleteMessage: err.error.message });
      })
      .finally(() => setState({ isDeleting: false }));
  };

  return (
    <div className="app-container">
      <InternalHeader go="/profile" title={name}>
        <div className="header-title">
          <h2>{t('back_button')}</h2>
        </div>
      </InternalHeader>

      <div className="container mt-3 mb-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="horse-image mb-3">
              <img src={image} alt={name} className="img-thumbnail" />
            </div>
            <div className="main-content">
              <h1 className="fs-4 mb-3 text-success fw-bold">{name}</h1>
              <p>{description}</p>
              <div className="card bg-white shadow mb-3">
                <div className="card-header bg-secondary p-2">
                  <h6 className="mb-0 text-light small">
                    <span className="fa fa-table me-2"></span>
                    {t('horse_detail')}
                  </h6>
                </div>
                <div className="card-body p-0">
                  <div className="table-container">
                    <table className="table fs-14 table-bordered table-striped mb-0">
                      <tbody>
                        <tr>
                          <th>{t('horse_date_of_birth')}</th>
                          <td>:</td>
                          <td className="w-50">{birthdate || '-'}</td>
                        </tr>
                        <tr>
                          <th>{t('horse_withers')}</th>
                          <td>:</td>
                          <td>{withers || '-'}</td>
                        </tr>
                        <tr>
                          <th>{t('horse_job')}</th>
                          <td>:</td>
                          <td>{job || '-'}</td>
                        </tr>
                        <tr>
                          <th>{t('horse_breed')}</th>
                          <td>:</td>
                          <td>{breed || '-'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="horse-service-container">
                <div className="card mb-3 bg-light bg-gradient shadow">
                  <div className="card-header bg-secondary p-2">
                    <h6 className="mb-0 small text-light">
                      <span className="fa fa-history me-2"></span>{' '}
                      {t('Service History')}
                    </h6>
                  </div>
                  <div className="card-body py-2">
                    <TransactionHistory horseId={itemId} />
                  </div>
                </div>
              </div>

              {gallery && gallery.length > 0 && (
                <div className="horse-gallery sgallery-container">
                  <div className="card shadow">
                    <div className="card-header bg-secondary p-2">
                      {gallery.length > 1 ? (
                        <div className="swiper-navigations d-flex align-items-center justify-content-between">
                          <button className="swiper-nav swiper-prev btn btn-sm py-1 btn-light ">
                            <span className="fa fa-chevron-left"></span>
                          </button>
                          <h6 className="m-0 small text-light">
                            <span className="fa fa-image me-2"></span>
                            {t('horse_gallery')}
                          </h6>
                          <button className="swiper-nav swiper-next btn btn-sm btn-light py-1">
                            <span className="fa fa-chevron-right"></span>
                          </button>
                        </div>
                      ) : (
                        <div className="swiper-navigations d-flex align-items-center justify-content-center">
                          <h6 className="m-0 small text-light">
                            <span className="fa fa-image me-2"></span>
                            {t('horse_gallery')}
                          </h6>
                        </div>
                      )}
                    </div>
                    <div className="card-body p-0">
                      <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        autoHeight={true}
                        navigation={{
                          nextEl: '.swiper-next',
                          prevEl: '.swiper-prev',
                        }}
                      >
                        {gallery.map((g, index) => (
                          <SwiperSlide key={g + index}>
                            <img src={g} alt={g + index} className="w-100" />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              )}

              <div className=" d-flex p-3 fixed-bottom bg-light bg-gradient border-top">
                <Link
                  to={`/item/${itemId}/edit`}
                  className="btn btn-sm btn-warning rounded-3 me-3 w-50"
                >
                  <span className="fa fa-pencil me-1"></span>
                  {t('edit_horse_button')}
                </Link>
                <button
                  className="btn btn-sm rounded-3 btn-danger w-50"
                  onClick={removeHorse}
                >
                  <span className="fa fa-times me-1"></span>
                  {t('remove_horse_button')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemPage;
