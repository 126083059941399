import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useAuthState } from '../../context';
import { updateProduct, deleteProduct } from '../../libs/apis';

export default function Product({ data }) {
  const { t } = useTranslation();
  const { user } = useAuthState();
  const {
    id,
    name,
    category,
    price_min,
    price_max,
    description,
    image,
    active = false,
  } = data;

  const initialState = {
    isOpen: false,
    isLoading: false,
    isError: false,
    isSale: active,
    isDeleteOpen: false,
    isDeleting: false,
    isDeleted: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    isOpen,
    isLoading,
    isError,
    isSale,
    isDeleteOpen,
    isDeleting,
    isDeleted,
  } = state;

  const handleDelete = async () => {
    setState({ isDeleting: true, isDeleted: false });
    const payload = {
      id,
    };
    await deleteProduct(payload)
      .then(resp => {
        if (resp.status === 200) {
          setState({ isDeleted: true });
        }
      })
      .finally(() => setState({ isDeleting: false }));
  };

  if (!user) return null;
  if (isDeleted) return null;

  const setForSale = async active => {
    setState({ loading: true });
    const payload = {
      id,
      category_id: category.id,
      name,
      price_min,
      description,
      price_max,
      active,
    };
    await updateProduct(payload)
      .then(resp => {
        if (resp.status === 200) {
          if (resp.data.response) {
            setState({ isSale: resp.data.response.active });
          }
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true });
      })
      .finally(() => {
        setState({ loading: false, isOpen: false });
      });
  };

  return (
    <div
      className={`card mb-4 rounded-3 bg-gradient border-2 bg-white ${
        isSale ? 'shadow' : 'opacity-75'
      }`}
    >
      <div className="card-header pr-2  bg-gradient">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="mb-0">
            <Link
              to={`/seller/product/edit/${id}`}
              className="text-decoration-none"
            >
              <strong>{name}</strong>
            </Link>
          </h6>
          <div className="text-end d-flex align-items-center justify-content-end">
            <h6 className="mb-0 me-1 small fs-12">{t('on_sale')}</h6>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id={`customSwitch-${id}`}
                checked={isSale}
                onChange={e => setForSale(e.target.checked)}
              />
              <label
                className="custom-control-label"
                htmlFor={`customSwitch-${id}`}
              ></label>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-3">
        {isError && (
          <div className="alert alert-warning my-3">
            <p className="m-0 small">
              Failed to update product status, try again..
            </p>
          </div>
        )}
        <div className="d-flex align-items-center">
          {image && (
            <div
              className="mb-0 me-3"
              style={{ width: '64px', flex: '0 0 64px' }}
            >
              <img src={image} alt={name} className="rounded-3 shadow-sm" />
            </div>
          )}
          <div className="product-info" style={{ flex: '1' }}>
            <div className="">
              <div className="d-flex justify-content-between mb-2">
                <h6 className="mb-0 fw-bold">
                  <span className="text-success">{t('$')}</span> {price_min}-
                  {price_max}
                </h6>
                <h6 className="mb-1 small">
                  <small className=" text-muted">
                    <span className="fa fa-tags text-primary opacity-50 me-1"></span>
                    {t(category.name.toLowerCase().replace(' ', '_'))}
                  </small>
                </h6>
              </div>
              <p className="mb-2 lh-sm small">{description}</p>
            </div>
          </div>
        </div>

        <Modal
          isOpen={isOpen}
          onRequestClose={() => setState({ isOpen: false })}
          contentLabel="Modal"
          className="reveal small p-3"
          ariaHideApp={false}
        >
          <button className="btn btn-close close-reveal">&times;</button>
          <h5>Are you sure?</h5>
          <p>Do you want to set this product for sale?</p>
          {isLoading ? (
            <div className="button-group">
              <button disabled className="btn btn-secondary">
                Cancel
              </button>
              <button disabled className="btn ms-3 btn-primary">
                <span className="fa fa-spinner fa-spin"></span> Loading
              </button>
            </div>
          ) : (
            <div className="button-group">
              <button
                onClick={() => setState({ isOpen: false })}
                className="btn btn-secondary"
              >
                Cancel
              </button>
              <button onClick={setForSale} className="btn ms-3 btn-primary">
                Set For Sale
              </button>
            </div>
          )}
        </Modal>
      </div>
      <div className="card-footer bg-light">
        <div className="button-group">
          <Link
            to={`/seller/product/edit/${id}`}
            className="btn rounded-3 mb-0 me-3 btn-sm small fs-12 btn-secondary"
          >
            <span className="fa fa-pencil me-1"></span>
            {t('edit_label')}
          </Link>
          <button
            className="btn mb-0 btn-sm small fs-12 btn-outline-danger"
            onClick={() => setState({ isDeleteOpen: true })}
          >
            <span className="fa fa-trash me-1"></span>
            {t('delete_label')}
          </button>
        </div>
        <Modal
          isOpen={isDeleteOpen}
          onRequestClose={() => setState({ isDeleteOpen: false })}
          contentLabel="Modal"
          className="reveal small p-3"
          ariaHideApp={false}
        >
          <button
            className="btn btn-close close-reveal"
            onClick={() => setState({ isDeleteOpen: false })}
          >
            &times;
          </button>
          <h5>Are you sure?</h5>
          <p>Do you want to delete this product?</p>
          {isDeleting ? (
            <div className="button-group">
              <button disabled className="btn btn-secondary">
                Cancel
              </button>
              <button disabled className="btn ms-3 btn-danger">
                <span className="fa fa-spinner fa-spin"></span> Deleting..
              </button>
            </div>
          ) : (
            <div className="button-group">
              <button
                onClick={() => setState({ isDeleteOpen: false })}
                className="btn btn-secondary"
              >
                Cancel
              </button>
              <button onClick={handleDelete} className="btn ms-3 btn-danger">
                Delete
              </button>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
}
