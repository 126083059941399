import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from './config/routes.js';
import { AuthProvider } from './context';
import AppRoute from './components/AppRoute';
import ScrollToTop from './components/ScrollToTop';
import { getUserToken, deleteUser } from './libs/utils';
import { getProfile } from './libs/apis';

function App() {
  const initialState = {
    loading: true,
    error: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { loading } = state;

  const token = getUserToken() || null;
  useEffect(() => {
    const checkUser = async t => {
      await getProfile(token)
        .then(resp => {
          if (resp.status === 403) {
            setState({ error: true });
            deleteUser();
          }
        })
        .catch(err => {
          console.log('err', err);
        })
        .finally(() => setState({ loading: false }));
    };
    if (token) {
      checkUser(token);
    } else {
      setState({ loading: false });
    }
  }, [token]);

  if (loading) {
    return null;
  }

  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Switch>
          {routes.map(route => (
            <AppRoute
              key={route.path}
              path={route.path}
              component={route.component}
              isPrivate={route.isPrivate}
              showHeader={route.showHeader}
              showMenu={route.showMenu}
            />
          ))}
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
