import React, { useState, useEffect, forwardRef } from 'react';
import {
  Link,
  useParams,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';
import Modal from 'react-modal';
import moment from 'moment';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fi';
import 'moment/locale/id';
import fi from 'date-fns/locale/fi';
import id from 'date-fns/locale/id';

import {
  getProductDetail,
  getItems,
  createTransaction,
  addItem,
  getCategories,
  getGeoLocation,
  uploadImage,
} from '../../libs/apis';
import { useAuthState } from '../../context';

import InternalHeader from '../../components/global/InternalHeader';
import Loader from '../../components/global/Loader';
import WidgetSeller from '../../components/global/WidgetSeller';

import defaultItem from '../../assets/images/default-product.jpg';
import userImg from '../../assets/images/user.png';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
registerLocale('fi', fi);
registerLocale('id', id);

SwiperCore.use([Navigation]);

function PurchasePage() {
  let { productId } = useParams();
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  moment.locale(lang);
  const history = useHistory();
  const location = useLocation();
  const { user, role } = useAuthState();
  const today = new Date();
  const newDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
    7,
    0,
    0,
  );

  const CustomInputCalendar = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`btn btn-outline-secondary w-100 text-start`}
      onClick={onClick}
      ref={ref}
    >
      <span className="fa fa-calendar me-2"></span>
      <span className="font-cabin fs-14">{value}</span>
    </button>
  ));

  const initialState = {
    price: 0,
    date: newDate,
    notes: '',
    data: [],
    loading: true,
    error: false,
    message: '',
    isOpen: false,
    items: [],

    isLoadingItem: false,
    isErrorItem: false,
    selectedHorse: [],
    isLoadingSubmit: false,
    isErrorSubmit: false,
    messageSubmit: '',
    isAddOpen: false,
    isAddLoading: false,
    isAddError: false,
    categories: [],
    selectedCategory: 0,
    horseName: '',
    horseImage: '',
    birthdate: new Date('2010-01-01'),
    withers: '',
    job: '',
    breed: '',
    horseDescription: '',
    horseGallery: [],
    inputGallery: '',
    isUploadError: false,
    isUploading: false,
    lat: user ? (user.latitude ? parseFloat(user.latitude) : '') : '',
    lng: user ? (user.longitude ? parseFloat(user.longitude) : '') : '',
    center: [],
    geolocationStatus: false,
    draggable: true,
    geocode: [],
    geoAddress: '',
    zoom: 15,
    deliveryAddress: '',
    sameAddress: true,
    openDate: false,
    continuous: false,
    emergency: false,
    interval: 1,
    cycle: 7,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };

  const {
    date,
    notes,
    price,
    data,
    loading,
    error,
    message,
    isOpen,
    isLoadingItem,
    items,
    selectedHorse,
    categories,
    selectedCategory,
    isAddOpen,
    isAddLoading,
    isUploading,
    isUploadError,
    horseName,
    horseImage,
    lat,
    lng,
    center,
    geolocationStatus,
    draggable,
    deliveryAddress,
    zoom,
    geoAddress,
    sameAddress,
    continuous,
    openDate,
    emergency,
    interval,
    cycle,
  } = state;

  const {
    birthdate,
    withers,
    job,
    breed,
    horseDescription,
    inputGallery,
    horseGallery,
  } = state;

  const getGeoLoc = async (lati, long) => {
    await getGeoLocation({ lat: lati, lng: long }).then(res => {
      if (res.status === 200 && res.data) {
        const result = res.data.results;
        const filterResult = result.filter(
          e => e.types.indexOf('street_address') !== -1,
        );
        const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
        if (selectedGeo) {
          const geo = selectedGeo.address_components.reverse();
          const geoAddress = selectedGeo.formatted_address;
          setState({ geocode: geo, geoAddress });
          // console.log('geo', geo)
        }
      }
    });
  };

  const onCheckChange = idx => {
    if (selectedHorse.indexOf(idx) === -1) {
      const newSelected = [...selectedHorse, idx];
      setState({ selectedHorse: newSelected });
      // setState({
      //   price:
      //     newSelected.length > 0 ? newSelected.length * data.price_max : price,
      // });
    } else {
      const newSelected = selectedHorse.filter(e => e !== idx);
      setState({ selectedHorse: newSelected });
      // setState({
      //   price:
      //     newSelected.length > 0 ? newSelected.length * data.price_max : price,
      // });
    }
  };

  useEffect(() => {
    const getProductData = async payload => {
      setState({ loading: true, error: false, message: '' });
      await getProductDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              setState({
                data: resp.data.response,
              });
            }
          } else {
            setState({ error: true, message: resp.data.error.message });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ error: true });
        })
        .finally(() => setState({ loading: false }));
    };
    const payload = {
      id: productId,
    };
    getProductData(payload);

    const getItemData = async () => {
      await getItems()
        .then(resp => {
          if (resp.status === 200) {
            setState({ items: resp.data.response });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isErrorItem: true });
        })
        .finally(() => setState({ isLoadingItem: false }));
    };

    getItemData();
    const getCategoryData = async () => {
      await getCategories(1).then(resp => {
        if (resp.status === 200) {
          setState({ categories: resp.data.response });
          if (resp.data.response.length > 0) {
            setState({ selectedCategory: resp.data.response[0].id });
          }
        }
      });
    };
    getCategoryData();
  }, [productId]);

  useEffect(() => {
    const getInitialGeoLoc = async (lati, long) => {
      await getGeoLocation({ lat: lati, lng: long }).then(res => {
        if (res.status === 200 && res.data) {
          const result = res.data.results;
          const filterResult = result.filter(
            e => e.types.indexOf('street_address') !== -1,
          );
          const selectedGeo = filterResult.length > 0 ? filterResult[0] : null;
          if (selectedGeo) {
            const geo = selectedGeo.address_components.reverse();
            const geoAddress = selectedGeo.formatted_address;
            setState({ geocode: geo, geoAddress });
          }
        }
      });
    };
    if (lat === '' && lng === '') {
      navigator.geolocation.getCurrentPosition(
        position => {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          console.log('getCurrentPosition Success ' + latitude + longitude); // logs position correctly
          getInitialGeoLoc(latitude, longitude);
          setState({
            lat: latitude,
            lng: longitude,
            geolocationStatus: true,
            center: [latitude, longitude],
          });
        },
        error => {
          console.error(JSON.stringify(error));
        },
        { enableHighAccuracy: true, timeout: 2000, maximumAge: 1000 },
      );
    } else {
      getInitialGeoLoc(lat, lng);
      setState({ geolocationStatus: true, center: [lat, lng] });
    }
  }, [lat, lng, user]);

  const onChangeMap = ({ center, zoom }) => {
    setState({
      center: center,
      zoom: zoom,
    });
  };

  const onMapDragEnd = e => {
    setState({
      lat: e.center.lat(),
      lng: e.center.lng(),
    });
    getGeoLoc(lat, lng);
  };

  if (!productId) return <Redirect to="/404" />;
  if (loading) return <Loader size="48" center />;
  if (error)
    return (
      <div
        className="app-container text-center d-flex flex-direction-column align-items-center justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <div className="content py-5">
          <h3 className="mb-3">{message}</h3>
          <button onClick={() => history.goBack()} className="btn btn-warning">
            Back To Previous Page
          </button>
        </div>
      </div>
    );

  if (role !== 'buyer')
    return (
      <div
        className="app-container text-center d-flex flex-direction-column align-items-center justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <div className="content py-5">
          <h3 className="mb-3">For Buyer</h3>
          <p>
            Only Buyer can made purchase. <br />
            Register your buyer account, or{' '}
            <Link to={`/login${location.search}`}>login</Link>
          </p>
          <button onClick={() => history.goBack()} className="btn btn-warning">
            Back To Previous Page
          </button>
        </div>
      </div>
    );
  const {
    seller,
    name,
    description,
    image,
    category,
    gallery,
    price_min,
    price_max,
  } = data;

  const { username, photo, phone, address } = user;

  const canPurchase = selectedHorse.length > 0 && date !== '';

  const handleSubmit = async e => {
    e.preventDefault();
    if (canPurchase) {
      setState({
        isLoadingSubmit: true,
        isErrorSubmit: false,
        messageSubmit: '',
      });
      let type = 1;
      if (openDate) type = 2;
      if (continuous) type = 3;
      if (emergency) type = 4;
      const dayCount = interval * cycle;
      let newAddress = geoAddress;
      if (deliveryAddress && deliveryAddress !== '') {
        newAddress = `${geoAddress} <br/><strong>- ${deliveryAddress}</strong>`;
      }
      let newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
      if (type === 2) {
        newDate = moment()
          .set({ minute: 0, second: 0 })
          .format('YYYY-MM-DD HH:mm:ss');
      }
      const payload = {
        product_id: productId,
        price,
        datetime: newDate,
        note: notes,
        items: selectedHorse,
        latitude: lat,
        longitude: lng,
        address: newAddress,
        interval: dayCount,
        type,
      };
      await createTransaction(payload)
        .then(resp => {
          if (resp.status === 200 || resp.status === 201) {
            history.replace(`/order/${resp.data.response.id}`);
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isErrorSubmit: true });
        })
        .finally(() => setState({ isLoadingSubmit: false }));
    }
  };

  const onDropImage = async e => {
    const file = e.target.files;
    setState({ isUploading: true, isUploadError: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({ horseImage: res.data.secure_url });
        }
      })
      .catch(err => {
        setState({ isUploadError: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ isUploading: false });
      });
  };

  const submitHorse = async ev => {
    ev.preventDefault();
    setState({ isAddLoading: true, isAddError: false });
    const payload = {
      name: horseName,
      category_id: selectedCategory,
      image: horseImage,
      birthdate: moment(birthdate).format('YYYY-MM-DD'),
      withers,
      job,
      breed,
      description: horseDescription,
      gallery: horseGallery,
    };
    await addItem(payload)
      .then(resp => {
        if (resp.status === 201 || resp.status === 200) {
          const item = resp.data.response || null;
          if (item && item.id) {
            // history.push(`/item/${item.id}`)
            const newItem = items.length === 0 ? [item] : [...items, item];
            setState({ items: newItem, horseName: '', horseImage: '' });
            const carousel = document.querySelector('.carousel-wrapper');
            if (carousel) {
              carousel.scrollLeft += 5000;
            }
          }
        } else {
          setState({ isAddError: true });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isAddError: true });
      })
      .finally(() => {
        setState({ isAddLoading: false, isAddOpen: false });
      });
  };

  const onDropGallery = async e => {
    const file = e.target.files;
    setState({ uploadGallery: true, errorGallery: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({
            horseGallery: [...horseGallery, res.data.secure_url],
            inputGallery: '',
          });
        }
      })
      .catch(err => {
        setState({ errorGallery: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploadGallery: false });
      });
  };

  const deleteGallery = item => {
    const newGallery = horseGallery.filter(e => e !== item);
    setState({ horseGallery: newGallery });
  };
  return (
    <div className="app-container pb-0">
      <InternalHeader title={`${t('purchase_page')}: ${name}`} />
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="content">
              <div className="multisteps-form py-3 pt-4">
                <div className="row">
                  <div className="col-12  ms-auto me-auto">
                    <div className="multisteps-form__progress">
                      <button
                        className="multisteps-form__progress-btn js-active"
                        type="button"
                        title={t('request_label')}
                      >
                        {t('request_label')}
                      </button>
                      <button
                        className="multisteps-form__progress-btn"
                        type="button"
                        title={t('quotation_label')}
                      >
                        {t('quotation_label')}
                      </button>
                      <button
                        className="multisteps-form__progress-btn"
                        type="button"
                        title={t('confirmation_label')}
                      >
                        {t('confirmation_label')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card shadow bg-light bg-gradient mb-3">
                <div className="card-header bg-secondary bg-gradient py-2">
                  <h6 className="m-0 text-light">1. {t('purchase_heading')}</h6>
                </div>
                <div className="card-body">
                  <h6 className="small text-uppercase text-success mb-2 fw-normal">
                    {t('product_information_heading')}
                  </h6>

                  <div className="d-flex mb-3 align-items-center">
                    <div className="me-2" style={{ flex: '0 0 60px' }}>
                      {image ? (
                        <img
                          src={image}
                          alt=""
                          style={{ width: '60px' }}
                          className="img-thumbnail  "
                        />
                      ) : (
                        <img
                          src={defaultItem}
                          alt=""
                          style={{ width: '60px' }}
                          className="img-thumbnail "
                        />
                      )}
                    </div>
                    <div className="seller-info">
                      <h6
                        className="mb-1 fw-bold"
                        onClick={() => setState({ isOpen: true })}
                      >
                        {name}{' '}
                        <span
                          className="fa fa-info-circle ms-2 text-muted"
                          title="View description"
                        ></span>
                      </h6>
                      <h6 className="small mb-1 fw-normal opacity-50">
                        <span className="fa text-primary fa-tags me-1"></span>
                        {t(category.name.toLowerCase().replace(' ', '_'))}
                      </h6>
                      <h6 className="mb-1 fw-bold">
                        <span className=" text-success">{t('$')}</span>{' '}
                        {price_min}-{price_max}
                      </h6>
                      <Modal
                        isOpen={isOpen}
                        onRequestClose={() => setState({ isOpen: false })}
                        contentLabel="Modal"
                        className="reveal small p-3"
                        ariaHideApp={false}
                      >
                        <h4 className="text-uppercase text-success fs-5 fw-bold">
                          {name}
                        </h4>
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="mb-2 fw-normal opacity-50 text-muted">
                            <span className="text-primary fa fa-tags me-1"></span>
                            {t(category.name.toLowerCase().replace(' ', '_'))}
                          </h6>
                          <h5 className="mb-2 ms-3 fw-bold">
                            <span className="small text-success">{t('$')}</span>{' '}
                            {price_min} - {price_max}
                          </h5>
                        </div>
                        {image ? (
                          <img
                            src={image}
                            alt=""
                            className="img-thumbnail  mb-3"
                          />
                        ) : (
                          <img
                            src={defaultItem}
                            alt=""
                            className="img-thumbnail mb-3 "
                          />
                        )}

                        <h6 className="text-uppercase mb-1 small text-success opacity-75 size-14">
                          <span className="fa fa-info-circle me-2"></span>
                          {t('modal_description_heading')}
                        </h6>
                        <p>{description}</p>

                        <div className="stats d-none">
                          <h5 className="mb-1 text-success opacity-75 size-14 text-uppercase">
                            <span className="fa fa-line-chart me-2"></span>
                            {t('statistic_heading')}
                          </h5>
                          <div className="cards">
                            <div className="card-bodys">
                              <table className="table bg-white mb-3 table-bordered table-md">
                                <tbody>
                                  <tr>
                                    <td className="text-nowrap w-25">
                                      <small>{t('stat_completed')}</small>
                                    </td>
                                    <td className="text-center">:</td>
                                    <td className="w-75 font-cabin">
                                      300 {t('order_text')}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-nowrap w-25">
                                      <small>{t('stat_total_order')}</small>
                                    </td>
                                    <td className="text-center">:</td>
                                    <td className="w-75 font-cabin">
                                      350 {t('order_text')}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-nowrap w-25">
                                      <small>{t('stat_avg_price')}</small>
                                    </td>
                                    <td className="text-center">:</td>
                                    <td className="w-75 font-cabin">
                                      <span className="text-success">
                                        {t('$')}
                                      </span>{' '}
                                      175
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-nowrap w-25">
                                      <small>{t('stat_visited')}</small>
                                    </td>
                                    <td className="text-center">:</td>
                                    <td className="w-75 font-cabin">
                                      1.985.123 {t('stat_times')}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="text-nowrap w-25">
                                      <small>{t('stat_last_ordered')}</small>
                                    </td>
                                    <td className="text-center">:</td>
                                    <td className="w-75 font-cabin">
                                      February 12, 2021
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {gallery && gallery.length > 0 && (
                          <div className="gallery mb-3">
                            <h6 className="mb-2 text-uppercase small text-success opacity-75">
                              <span className="fa fa-image me-2"></span>{' '}
                              {t('modal_gallery_heading')}
                            </h6>
                            <div className="gallery-container">
                              <Swiper
                                spaceBetween={50}
                                slidesPerView={1}
                                onSlideChange={() =>
                                  console.log('slide change')
                                }
                                onSwiper={swiper => console.log(swiper)}
                                autoHeight={true}
                                navigation={{
                                  nextEl: '.swiper-next',
                                  prevEl: '.swiper-prev',
                                }}
                              >
                                {gallery.map((g, index) => (
                                  <SwiperSlide key={g + index}>
                                    <img
                                      src={g}
                                      alt={g + index}
                                      className="w-100"
                                    />
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                              <div className="swiper-navigation d-flex justify-content-between">
                                <button className="swiper-nav swiper-prev ">
                                  <span className="fa fa-chevron-left"></span>
                                </button>
                                <button className="swiper-nav swiper-next ">
                                  <span className="fa fa-chevron-right"></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        <button
                          onClick={() => setState({ isOpen: false })}
                          className="btn btn-sm btn-secondary w-100"
                        >
                          {t('close_button')}
                        </button>
                        <button
                          className="btn btn-close close-reveal"
                          onClick={() => setState({ isOpen: false })}
                        >
                          &times;
                        </button>
                      </Modal>
                    </div>
                  </div>
                  <hr />

                  <h6 className="small text-success text-uppercase  mb-2 fw-normal">
                    {t('seller_information_heading')}
                  </h6>
                  <WidgetSeller data={seller} />

                  <hr />

                  <h6 className="small text-success text-uppercase  mb-2 fw-normal">
                    {t('buyer_information_heading')}
                  </h6>
                  <div className="d-flex mb-0 align-items-center">
                    <div className="me-2" style={{ flex: '0 0 60px' }}>
                      {photo ? (
                        <img
                          src={photo}
                          alt=""
                          style={{ width: '60px' }}
                          className="img-thumbnail rounded-circle "
                        />
                      ) : (
                        <img
                          src={userImg}
                          alt=""
                          style={{ width: '60px' }}
                          className="img-thumbnail rounded-circle "
                        />
                      )}
                    </div>
                    <div className="seller-info">
                      <h6 className="mb-0 ">
                        {user.name ? user.name : username}
                      </h6>
                      <h6 className="small fw-normal">{phone}</h6>
                      {address && address !== '' && (
                        <h6 className="mb-0 fw-normal small">
                          <span className="fa fa-map-marker text-danger me-2"></span>{' '}
                          {address}
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col">
            <div className="content">
              <div className="card shadow mb-3 bg-light bg-gradient">
                <div className="card-header bg-secondary bgt-gradient text-light py-2">
                  <h6 className="mb-0 text-light">
                    2. {t('selected_horse_heading')}:{' '}
                    <strong
                      className="fs-4"
                      style={{ textShadow: '1px 1px 1px rgba(0,0,0,.33)' }}
                    >
                      {selectedHorse.length}
                    </strong>
                  </h6>
                  <small>
                    <em>{t('selected_horse_info_text')}</em>
                  </small>
                </div>
                <div className="card-body p-0">
                  {items.length > 0 ? (
                    <div className="carousel-wrapper">
                      <div className="my-horse-container">
                        {items.map((horse, idx) => {
                          const isSelected =
                            selectedHorse.indexOf(horse.id) !== -1;
                          return (
                            <div
                              className="my-horse-item"
                              key={horse.name + idx}
                            >
                              <label
                                className={`card card-selection position-relative ${
                                  isSelected
                                    ? 'border-success shadow'
                                    : 'opacity-50'
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  name="selected_horse"
                                  onChange={() => onCheckChange(horse.id)}
                                  checked={isSelected}
                                />
                                <img
                                  src={horse.image}
                                  alt={horse.name}
                                  className="card-img-top"
                                />
                                <span className="card-body border-top py-2">
                                  <p className="card-text">{horse.name}</p>
                                </span>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <>
                      {isLoadingItem && (
                        <div className="text-center p-3">
                          <Loader size="32" />
                        </div>
                      )}
                    </>
                  )}
                  <div className="text-center p-3">
                    <button
                      className="btn btn-sm btn-warning w-100 border-secondary"
                      onClick={() => setState({ isAddOpen: true })}
                    >
                      <span className="fa fa-plus me-2"></span>
                      {t('add_horse_button')}
                    </button>
                  </div>
                  <Modal
                    isOpen={isAddOpen}
                    onRequestClose={() => setState({ isAddOpen: false })}
                    contentLabel="Modal"
                    className="reveal medium p-3"
                    ariaHideApp={false}
                  >
                    <h4>{t('add_horse_button')}</h4>
                    <hr />
                    <div>
                      <div className="form-floating mb-3 d-none">
                        <select
                          className="form-select "
                          name="category"
                          onChange={e =>
                            setState({ selectedCategory: e.target.value })
                          }
                        >
                          <option value="0" disabled>
                            {t('category_select')}
                          </option>
                          {categories.length > 0 &&
                            categories.map((cat, index) => (
                              <option key={cat.id + index} value={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                        </select>
                        <label htmlFor="category">{t('category_select')}</label>
                      </div>
                      <div className="form-group">
                        {isUploadError && (
                          <div className="alert alert-danger text-center">
                            <p className="m-0">{t('failed_to_upload')}</p>
                          </div>
                        )}
                        {isUploading && <Loader size={24} />}
                        <div className="image-uploader text-center">
                          {horseImage && (
                            <div className="mb-3 d-flex justify-content-center">
                              <img
                                src={horseImage}
                                alt="upload"
                                className="img-thumbnail"
                              />
                            </div>
                          )}

                          <label
                            htmlFor="img"
                            className="position-relative w-100 d-flex align-items-center mb-3 btn btn-primary"
                          >
                            <span className="fa fa-image me-3"></span>
                            {horseImage ? (
                              <small>{t('change_photo_label')}</small>
                            ) : (
                              <small>{t('upload_photo_label')}</small>
                            )}
                            <span className="text-danger"> *</span>

                            <input
                              type="file"
                              id="img"
                              onChange={onDropImage}
                              className="fs-6 small"
                              style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                                opacity: 0,
                              }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          className="form-control"
                          type="text"
                          id="horseName"
                          value={horseName}
                          onChange={e =>
                            setState({ horseName: e.target.value })
                          }
                          disabled={isAddLoading}
                          required
                          placeholder={t('horse_name_label')}
                        />
                        <label
                          htmlFor="horseName"
                          className="font-cabin text-primary"
                        >
                          {t('horse_name_label')}{' '}
                          <span className="text-danger">*</span>{' '}
                        </label>
                      </div>
                      <label
                        htmlFor="datepicker"
                        className="font-cabin text-primary"
                      >
                        {t('horse_date_of_birth')}
                      </label>
                      <div className="d-flex mb-3">
                        <DatePicker
                          selected={birthdate}
                          locale={lang}
                          onChange={newDate => {
                            setState({ birthdate: newDate });
                          }}
                          customInput={<CustomInputCalendar />}
                        />
                      </div>
                      <label
                        htmlFor="withers"
                        className="font-cabin text-primary"
                      >
                        {t('horse_withers')}
                      </label>
                      <div className="form-group mb-3">
                        <input
                          className="form-control"
                          type="text"
                          id="withers"
                          value={withers}
                          onChange={e => setState({ withers: e.target.value })}
                          disabled={isAddLoading}
                          required
                        />
                      </div>
                      <label htmlFor="job" className="font-cabin text-primary">
                        {t('horse_job')}
                      </label>
                      <div className="form-group mb-3">
                        <input
                          className="form-control"
                          type="text"
                          id="job"
                          value={job}
                          onChange={e => setState({ job: e.target.value })}
                          disabled={isAddLoading}
                          required
                        />
                      </div>
                      <label
                        htmlFor="breed"
                        className="font-cabin text-primary"
                      >
                        {t('horse_breed')}
                      </label>
                      <div className="form-group mb-3">
                        <input
                          className="form-control"
                          type="text"
                          id="breed"
                          value={breed}
                          onChange={e => setState({ breed: e.target.value })}
                          disabled={isAddLoading}
                          required
                        />
                      </div>
                      <label
                        htmlFor="breed"
                        className="font-cabin text-primary"
                      >
                        {t('horse_description')}
                      </label>
                      <div className="form-group mb-3">
                        <textarea
                          className="form-control"
                          id="horseDescription"
                          value={horseDescription}
                          onChange={e =>
                            setState({ horseDescription: e.target.value })
                          }
                          disabled={isAddLoading}
                          required
                          style={{ height: '160px' }}
                        />
                      </div>

                      <div className="gallery mb-3 p-3 bg-white bg-gradient border rounded">
                        <h6 className="font-cabin text-primary small">
                          {t('horse_gallery')}
                        </h6>
                        <div className="gallery-uploader mb-3">
                          <div className="add-gallery">
                            <label
                              htmlFor="add-gallery"
                              className="btn w-100 bg-light border rounded-3 shadow-sm bg-gradient position-relative"
                            >
                              <span className="icon me-2">
                                <span className="fa fa-plus"></span>
                              </span>
                              <span className="text">{t('add_gallery')}</span>
                              <input
                                type="file"
                                id="add-gallery"
                                name="add-gallery"
                                onChange={onDropGallery}
                                value={inputGallery}
                              />
                            </label>
                          </div>
                        </div>
                        {horseGallery.length > 0 && (
                          <div className="gallery-container mb-3 row g-2">
                            {horseGallery.map((g, i) => (
                              <div className="col-6" key={g + i}>
                                <div className="gallery-item border rounded shadow-sm">
                                  <img
                                    src={g}
                                    alt={g + i}
                                    className="rounded-top"
                                  />
                                  <div className="p-2 bg-light">
                                    <button
                                      className="btn btn-danger btn-sm w-100 m-0"
                                      onClick={() => deleteGallery(g)}
                                    >
                                      <span className="fa fa-trash me-1"></span>
                                      <small>{t('delete_label')}</small>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      {horseImage === '' || horseName === '' ? (
                        <button
                          type="button"
                          className="btn btn-lg btn-secondary w-100 mb-3"
                        >
                          {t('add_horse_button')}{' '}
                          <span className="ms-2 fa fa-chevron-right"></span>
                        </button>
                      ) : (
                        <button
                          onClick={submitHorse}
                          className="btn btn-lg btn-success w-100 mb-3"
                        >
                          {t('add_horse_button')}{' '}
                          <span className="ms-2 fa fa-chevron-right"></span>
                        </button>
                      )}
                    </div>
                    <button
                      className="btn btn-close close-reveal"
                      onClick={() => setState({ isAddOpen: false })}
                    >
                      &times;
                    </button>
                  </Modal>
                </div>
              </div>

              <div className="card bg-light bg-gradient border-success mb-3 shadow">
                <div className="card-header bg-success bg-gradient py-2">
                  <h6 className="m-0 text-light">
                    3. {t('additional_information_heading')}
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-quotation-container">
                    <div className="form-container">
                      <label
                        className="small text-success font-cabin"
                        htmlFor="requestedPrice"
                      >
                        {t('requested_price_label')}
                        <span className="text-danger">*</span>{' '}
                        <small className="text-body text-capitalize">
                          ({' '}
                          {selectedHorse.length > 0
                            ? price_min * selectedHorse.length
                            : price_min}{' '}
                          -{' '}
                          {selectedHorse.length > 0
                            ? price_max * selectedHorse.length
                            : price_max}{' '}
                          )
                        </small>
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">{t('$')}</span>
                        <input
                          type="number"
                          className="form-control text-end"
                          id="requestedPrice"
                          name="requestedPrice"
                          placeholder="0"
                          value={price}
                          onChange={e => setState({ price: e.target.value })}
                          required
                          pattern="\d*"
                          min="0"
                        />
                        <span className="input-group-text">.00</span>
                      </div>

                      <div className="d-flex align-items-center justify-content-between">
                        <label
                          className="small text-success font-cabin"
                          htmlFor="datetime"
                        >
                          {t('delivery_date_label')}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="form-check form-switch emergency-check">
                          <label
                            className="form-check-label text-warning font-cabin fs-12"
                            htmlFor="emergency"
                          >
                            {t('emergency_text')}
                          </label>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`emergency`}
                            checked={emergency}
                            onChange={e => {
                              setState({
                                emergency: e.target.checked,
                                openDate: false,
                                continuous: false,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {openDate ? (
                        <div
                          className="p-2 bg-gradient rounded-3 mb-3"
                          style={{ background: '#f8a83233' }}
                        >
                          <p className="small mb-0">{t('open_date_info')}</p>
                        </div>
                      ) : (
                        <div className="mb-3">
                          {emergency ? (
                            <DatePicker
                              showTimeSelect
                              selected={date}
                              locale={lang}
                              onChange={date => setState({ date: date })}
                              customInput={<CustomInputCalendar />}
                              dateFormat="MMM dd, yyyy - h:mm a"
                              className="w-100"
                              filterDate={date => {
                                let valid = moment(date).isAfter(moment());
                                return valid;
                              }}
                            />
                          ) : (
                            <DatePicker
                              showTimeSelect
                              selected={date}
                              locale={lang}
                              onChange={date => setState({ date: date })}
                              customInput={<CustomInputCalendar />}
                              dateFormat="MMM dd, yyyy - h:mm a"
                              className="w-100"
                              filterDate={date => {
                                let valid = moment(date).isAfter(moment());
                                // if (moment(date).isoWeekday() >= 6) { valid = false }
                                return valid;
                              }}
                              excludeTimes={[
                                new Date().setHours(1, 0),
                                new Date().setHours(1, 30),
                                new Date().setHours(2, 0),
                                new Date().setHours(2, 30),
                                new Date().setHours(3, 0),
                                new Date().setHours(3, 30),
                                new Date().setHours(4, 0),
                                new Date().setHours(4, 30),
                                new Date().setHours(5, 0),
                                new Date().setHours(5, 30),
                                new Date().setHours(6, 0),
                                new Date().setHours(6, 30),
                                new Date().setHours(21, 30),
                                new Date().setHours(22, 0),
                                new Date().setHours(22, 30),
                                new Date().setHours(23, 0),
                                new Date().setHours(23, 30),
                                new Date().setHours(24, 0),
                                new Date().setHours(24, 30),
                              ]}
                            />
                          )}
                        </div>
                      )}

                      <div className="p-2 bg-white border mb-3 rounded-3 border-2">
                        <label
                          className="small mb-2 font-cabin text-success"
                          htmlFor="datetime"
                        >
                          <span className="fa fa-wrench me-2"></span>
                          {t('customize_your_order')}
                        </label>

                        <div className="form-check form-switch mb-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`openDate`}
                            checked={openDate}
                            onChange={e =>
                              setState({
                                openDate: e.target.checked,
                                continuous: false,
                                emergency: false,
                              })
                            }
                          />
                          <label
                            className={`form-check-label font-cabin fs-12 ${
                              openDate
                                ? 'opacity-100'
                                : 'text-muted opacity-50 '
                            }`}
                            htmlFor={`openDate`}
                          >
                            {t('open_date')}
                          </label>
                        </div>

                        <div className="form-check form-switch mb-0">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`continuousService`}
                            checked={continuous}
                            onChange={e =>
                              setState({
                                continuous: e.target.checked,
                                openDate: false,
                                emergency: false,
                              })
                            }
                          />
                          <label
                            className={`form-check-label font-cabin fs-12 ${
                              continuous
                                ? 'opacity-100'
                                : 'text-muted opacity-50 '
                            }`}
                            htmlFor={`continuousService`}
                          >
                            {t('make_this_order_continuous_text')}
                          </label>
                        </div>

                        {continuous && (
                          <div className="mb-0 mt-1">
                            <label
                              className="small text-success font-cabin"
                              htmlFor="interval"
                            >
                              {t('select_interval')}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-group mb-0">
                              <span className="input-group-text">
                                <span className="fa fa-refresh"></span>
                              </span>
                              <input
                                type="number"
                                className="form-control text-end"
                                value={interval}
                                onChange={e =>
                                  setState({ interval: e.target.value })
                                }
                                placeholder={t('interval_text')}
                                pattern="\d*"
                                min="0"
                              />
                              <select
                                name="interval"
                                id="interval"
                                className="form-select"
                                value={cycle}
                                onChange={e =>
                                  setState({ cycle: e.target.value })
                                }
                              >
                                <option value={7}>{t('weekly_text')}</option>
                                <option value={30}>{t('monthly_text')}</option>
                              </select>
                            </div>
                          </div>
                        )}
                      </div>

                      <label
                        className="small text-success font-cabin"
                        htmlFor="datetime"
                      >
                        {t('delivery_address_label')}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="">
                        <div className="form-check form-switch mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`customSwitch`}
                            checked={sameAddress}
                            onChange={e =>
                              setState({ sameAddress: e.target.checked })
                            }
                          />
                          <label
                            className={`form-check-label font-cabin fs-12 ${
                              sameAddress
                                ? 'opacity-100'
                                : 'text-muted opacity-50 '
                            }`}
                            htmlFor={`customSwitch`}
                          >
                            {t('same_as_my_location_label')}
                          </label>
                        </div>
                        {!sameAddress && (
                          <div
                            className="p-3 mb-3 border rounded bg-white"
                            style={{ marginTop: '-.5rem' }}
                          >
                            <h6 className="small text-muted">
                              {t('set_delivery_location_label')}
                            </h6>
                            {geolocationStatus && (
                              <div className="">
                                <div
                                  className="mb-1 border position-relative"
                                  style={{
                                    height: '80vw',
                                    maxHeight: '400px',
                                    width: '100%',
                                  }}
                                >
                                  <GoogleMapReact
                                    bootstrapURLKeys={{
                                      key: `AIzaSyCuaV7aVdh3G0VmDW4wn3C9JJUGZXqGWaY`,
                                    }}
                                    center={center}
                                    zoom={zoom}
                                    draggable={draggable}
                                    onChange={onChangeMap}
                                    onDragEnd={onMapDragEnd}
                                    options={{ gestureHandling: 'greedy' }}
                                  ></GoogleMapReact>
                                  <div className="marker-overlay">
                                    <div className="pin">
                                      <span className="fa fa-map-marker text-success"></span>
                                    </div>
                                  </div>
                                </div>
                                <div className="geolocation-text mb-3 bg-white p-2">
                                  <h6 className="mb-1 fs-12">
                                    <span className="fa fa-map-marker me-1 text-danger"></span>
                                    {t('your_location_text')}:
                                  </h6>
                                  <p className="lh-1 mb-0 fs-14 text-dark">
                                    {geoAddress}
                                  </p>
                                </div>
                                <div className="form-floating mb-0">
                                  <textarea
                                    className="form-control mb-0"
                                    name="notes"
                                    id="deliveryAddress"
                                    rows="2"
                                    placeholder={t('address_detail_label')}
                                    value={deliveryAddress}
                                    onChange={e =>
                                      setState({
                                        deliveryAddress: e.target.value,
                                      })
                                    }
                                    style={{ height: '80px' }}
                                  ></textarea>
                                  <label
                                    className="text-success font-cabin small"
                                    htmlFor="deliveryAddress"
                                  >
                                    {t('address_detail_label')}
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="form-floating mb-3">
                        <textarea
                          className="form-control"
                          name="notes"
                          id="additionalInfo"
                          rows="3"
                          placeholder={t('write_a_note_label')}
                          value={notes}
                          onChange={e => setState({ notes: e.target.value })}
                          style={{ height: '120px' }}
                        ></textarea>
                        <label
                          className="text-success font-cabin small"
                          htmlFor="additionalInfo"
                        >
                          {t('write_a_note_label')}
                        </label>
                      </div>

                      {canPurchase ? (
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn bg-gradient btn-lg btn-primary w-100"
                        >
                          {t('place_order_button')}
                        </button>
                      ) : (
                        <button
                          className="btn bg-gradient btn-lg btn-secondary w-100"
                          disabled
                        >
                          {t('place_order_button')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchasePage;
