import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Page from '../global/Page';

import { loginUser, useAuthState, useAuthDispatch } from '../../context';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useAuthDispatch();
  const { loading, errorMessage } = useAuthState();
  const history = useHistory();

  const handleLogin = async e => {
    e.preventDefault();

    try {
      let response = await loginUser(dispatch, { username, password });
      if (response.status !== 200) return;
      history.push('/admin');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="app-container">
      <Helmet>
        <title>Admin Login | Luokki</title>
      </Helmet>
      <div className="internal-header sticky-top bg-light shadow-sm">
        <div className="back-button">
          <Link to="/">
            <span className="fa fa-chevron-left"></span>
          </Link>
        </div>
        <div className="header-content">
          <div className="header-title">
            <h2>Admin Login</h2>
          </div>
        </div>
      </div>
      <div className="main-content py-3">
        <Page>
          {/* <h1 className="mb-3 text-center">Login</h1> */}
          {errorMessage ? (
            <div className="alert alert-danger fade show" role="alert">
              {errorMessage}
            </div>
          ) : null}
          <form>
            <div className="p-3">
              <h4 className="small mb-3 text-center">
                Please login to your admin account
              </h4>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="text"
                  id="email"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  disabled={loading}
                  placeholder="username"
                />
                <label htmlFor="email">Username</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="password"
                  id="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  disabled={loading}
                  placeholder="Password"
                />
                <label htmlFor="password">Password</label>
              </div>

              <button
                className="btn mt-1 btn-lg btn-success shadow w-100"
                onClick={handleLogin}
                disabled={loading}
              >
                Admin Login
              </button>
            </div>
          </form>
        </Page>
      </div>
    </div>
  );
}

export default Login;
