import React, { useState } from 'react';
import Modal from 'react-modal';
import Slot from './Slot';
import { Link } from 'react-router-dom';
import { useAuthState } from '../../context';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/id';

export default function Item({ data = null, timeslot = null }) {
  const { role } = useAuthState();
  const isSeller = role === 'seller';
  const link = isSeller ? 'seller/order' : 'order';
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  moment.locale(lang);
  const [open, setOpen] = useState(false);
  if (!data) return null;
  const { id, transaction_items, status, product } = data;
  // const color = status >= 4 ? 'success' : 'warning';
  // let first = parseInt(moment(data.transaction_request.datetime).format('H'))
  // let hour = first;
  // transaction_items.forEach(e => {
  //   let h = parseInt(e.time.split(':')[0])
  //   if (h > hour) {
  //     hour = h;
  //   }
  // });
  // const height = ((hour - first) + 2) * 64;
  const stats = [
    {
      status: 'new_order_status',
      color: 'text-dark',
      background: 'bg-warning',
      border: 'border-warning',
      alert: 'alert-warning',
    },
    {
      status: 'changed_status',
      color: 'text-dark',
      background: 'bg-info',
      border: 'border-info',
      alert: 'alert-info',
    },
    {
      status: 'confirmed_status',
      color: 'text-light',
      background: 'bg-success',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'invoice_sent_status',
      color: 'text-success',
      background: 'bg-light',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'payment_confirmation_status',
      color: 'text-success',
      background: 'bg-light',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'completed_status',
      color: 'text-success',
      background: 'bg-light',
      border: 'border-success',
      alert: 'alert-success',
    },
    {
      status: 'cancelled_status',
      color: 'text-danger',
      background: 'bg-danger',
      border: 'border-danger',
      alert: 'alert-danger',
    },
  ];

  const stat = status - 1;
  const classNames = `${stats[stat].color} ${stats[stat].background} ${stats[stat].border}`;

  return (
    <div className="order-item">
      <div
        className={`item-summary m-1 rounded-1 border ${classNames} bg-gradient`}
        onClick={() => setOpen(!open)}
        style={{ padding: '2px' }}
      >
        <div
          className="text-end small fs-12 lh-1 ms-1 float-end"
          style={{ marginTop: '-.125rem', marginRight: '-.125rem' }}
        >
          {open ? (
            <span className="fa fa-minus"></span>
          ) : (
            <span className="fa fa-plus"></span>
          )}
        </div>
        <h6 className={`mb-0 lh-0 fs-10 small ${stats[stat].color} font-ssp`}>
          {transaction_items.map((h, i) => (
            <span className="horse-name" key={i}>
              {i > 0 && <span>, </span>}
              {h.item.name}
            </span>
          ))}
        </h6>
      </div>

      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        contentLabel="Modal"
        className="reveal p-3 small"
        ariaHideApp={false}
      >
        <div className="horse-list-container">
          <h6 className="mb-3 text-primary fw-bold">
            {t('order_detail_heading')}
          </h6>
          <div className="border shadow-sm p-2 mb-3">
            <div className="d-flex align-items-center">
              {product.image && (
                <img
                  src={product.image}
                  alt={product.name}
                  className="me-2 img-thumbnail rounded"
                  style={{ width: '48px' }}
                />
              )}
              <div style={{ flex: 1 }}>
                <h6 className="small mb-1">{product.name}</h6>
                <h6 className="fs-12 mb-0">
                  <span className="fa fa-clock-o me-2"></span>
                  {moment(timeslot).format('MMMM DD, H:mm a')}
                </h6>
              </div>
              <div
                className={`mb-1 fs-10 small border align-self-start p-1 lh-1 rounded-3 fw-normal bg-gradient ${stats[stat].background} ${stats[stat].color} ${stats[stat].border}`}
              >
                {t(stats[stat].status)}
              </div>
            </div>
          </div>
          <h6 className="mb-3 text-primary fw-bold">{t('horse_list')}</h6>
          <div className="horse-items mb-3">
            {transaction_items.map((h, i) => (
              <Slot
                horse={h}
                status={status}
                orderId={id}
                key={i}
                timeslot={timeslot}
                single={transaction_items.length === 1}
              />
            ))}
          </div>
          <div className="btn-container d-flex ">
            <Link
              className="btn btn-sm me-2 w-50 btn-secondary"
              to={`/${link}/${id}`}
            >
              {t('view_order_button')} #{id}
            </Link>
            <button
              className="btn btn-sm w-50 btn-outline-secondary"
              onClick={() => setOpen(false)}
            >
              {t('close_button')}
            </button>
          </div>
        </div>
        <button
          className="btn btn-close close-reveal"
          onClick={() => setOpen(false)}
        >
          &times;
        </button>
      </Modal>
    </div>
  );
}
