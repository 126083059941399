import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuthState } from '../../context';
import OrderSummary from '../../components/seller/OrderSummary';

function SellerOrder() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, role } = useAuthState();

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');

  return (
    <div className="admin-container">
      <div className="admin-header py-3 bg-primary bg-gradient shadow">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <h1 className="fs-6 text-light mb-0">
                  <span className="fa fa-list me-2"></span>
                  {t('order_text')}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container py-3 pb-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <OrderSummary />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerOrder;
