import React, { useState, useEffect, forwardRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InternalHeader from '../../components/global/InternalHeader';
import Loader from '../../components/global/Loader';
import {
  getCategories,
  updateItem,
  uploadImage,
  getItemDetail,
} from '../../libs/apis';
import moment from 'moment';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fi';
import 'moment/locale/id';
import fi from 'date-fns/locale/fi';
import id from 'date-fns/locale/id';
registerLocale('fi', fi);
registerLocale('id', id);

function AddItemPage() {
  const { t, i18n } = useTranslation();
  const lang = i18n ? i18n.language : 'en';
  moment.locale(lang);
  const history = useHistory();
  const { itemId } = useParams();
  const initialState = {
    categories: [],
    selectedCategory: 0,
    name: '',
    image: '',
    isLoading: true,
    isError: false,
    isSubmiting: false,
    isSubmitError: false,
    submitMessage: '',
    message: '',
    isUploading: false,
    isUploadError: false,
    data: [],
    birthdate: '',
    withers: '',
    job: '',
    breed: '',
    description: '',
    gallery: [],
    inputGallery: '',
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const {
    selectedCategory,
    categories,
    name,
    image,
    isLoading,
    isError,
    message,
    isUploading,
    isUploadError,
    birthdate,
    withers,
    job,
    breed,
    description,
    gallery,
    inputGallery,
  } = state;

  const onDropImage = async e => {
    const file = e.target.files;
    setState({ isUploading: true, isUploadError: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          const resized = res.data.secure_url.replace(
            'https://res.cloudinary.com/luokki/image/upload/',
            'https://res.cloudinary.com/luokki/image/upload/w_150,h_150,c_fill/',
          );
          setState({ image: resized });
        }
      })
      .catch(err => {
        setState({ isUploadError: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ isUploading: false });
      });
  };
  const onDropGallery = async e => {
    const file = e.target.files;
    setState({ uploadGallery: true, errorGallery: false });

    const payload = {
      file: file[0],
    };
    await uploadImage(payload)
      .then(res => {
        if (res.status === 200) {
          setState({
            gallery: [...gallery, res.data.secure_url],
            inputGallery: '',
          });
        }
      })
      .catch(err => {
        setState({ errorGallery: true });
        console.log('err', err);
      })
      .finally(() => {
        setState({ uploadGallery: false });
      });
  };

  const deleteGallery = item => {
    const newGallery = gallery.filter(e => e !== item);
    setState({ gallery: newGallery });
  };

  const handleSubmit = async ev => {
    ev.preventDefault();
    setState({ isSubmiting: true, isSubmitError: false, submitMessage: '' });
    const payload = {
      name,
      category_id: selectedCategory,
      image,
      id: itemId,
      birthdate: moment(birthdate).format('YYYY-MM-DD'),
      withers,
      job,
      breed,
      description,
      gallery,
    };
    await updateItem(payload)
      .then(resp => {
        if (resp.status === 201 || resp.status === 200) {
          const item = resp.data.response || null;
          if (item && item.id) {
            history.push(`/item/${item.id}`);
          }
        } else {
          setState({
            isSubmitError: true,
            submitMessage: resp.data.error.message,
          });
        }
      })
      .catch(err => {
        console.log('err', err);
        setState({ isError: true, submitMessage: err.message });
      })
      .finally(() => {
        setState({ isSubmiting: false });
      });
  };

  useEffect(() => {
    const getCategoryData = async () => {
      await getCategories(1).then(resp => {
        if (resp.status === 200) {
          setState({ categories: resp.data.response });
          // if (resp.data.response.length > 0) {
          //   setState({ selectedCategory: resp.data.response[0].id })
          // }
        }
      });
    };
    getCategoryData();
    const getItemData = async () => {
      setState({ isLoading: true, isError: false, message: '' });
      const payload = {
        id: itemId,
      };
      await getItemDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            const item = resp.data.response;
            setState({
              ...resp.data.response,
              birthdate: new Date(resp.data.response.birthdate),
              selectedCategory: item.category.id,
            });
          } else {
            setState({ isError: true, message: resp.data.error.message });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ isError: true, messsage: err.error.message });
        })
        .finally(() => setState({ isLoading: false }));
    };
    getItemData();
  }, [itemId]);

  const CustomInputCalendar = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`btn btn-outline-secondary w-100 text-start`}
      onClick={onClick}
      ref={ref}
    >
      <span className="fa fa-calendar me-2"></span>
      <span className="font-cabin fs-14">{value}</span>
    </button>
  ));

  return (
    <div className="app-container">
      <InternalHeader go="/profile" title={t('edit_horse_heading')}>
        <div className="header-title">
          <h2>{t('edit_horse_heading')}</h2>
        </div>
      </InternalHeader>

      <div className="main-container py-4">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content text-left">
                <div className="form-container">
                  {isError && (
                    <div className="alert alert-danger fade show" role="alert">
                      {message}
                    </div>
                  )}

                  <div>
                    <div className=" mb-3 d-none">
                      <label htmlFor="category">{t('category_select')}</label>
                      <select
                        className="form-select "
                        name="category"
                        onChange={e =>
                          setState({ selectedCategory: e.target.value })
                        }
                      >
                        <option value="0" disabled>
                          {t('category_select')}
                        </option>
                        {categories.length > 0 &&
                          categories.map((cat, index) => (
                            <option key={cat.id + index} value={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group">
                      {isUploadError && (
                        <div className="alert alert-danger text-center">
                          <p className="m-0">{t('failed_to_upload')}</p>
                        </div>
                      )}
                      {isUploading && <Loader size={24} />}
                      <div className="image-uploader text-center">
                        {image && (
                          <div className="mb-3 d-flex justify-content-center">
                            <img
                              src={image}
                              alt="upload"
                              className="img-thumbnail"
                            />
                          </div>
                        )}

                        <label
                          htmlFor="img"
                          className="position-relative w-100 d-flex align-items-center mb-3 btn btn-primary"
                        >
                          <span className="fa fa-image me-3"></span>
                          {image ? (
                            <small>{t('change_photo_label')}</small>
                          ) : (
                            <small>{t('upload_photo_label')}</small>
                          )}
                          <span className="text-danger"> *</span>

                          <input
                            type="file"
                            id="img"
                            onChange={onDropImage}
                            className="fs-6 small"
                            style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                              opacity: 0,
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="name" className="font-cabin text-primary">
                        {t('horse_name_label')}{' '}
                        <span className="text-danger">*</span>{' '}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        value={name}
                        onChange={e => setState({ name: e.target.value })}
                        disabled={isLoading}
                        required
                        placeholder={t('horse_name_label')}
                      />
                    </div>
                    <label
                      htmlFor="datepicker"
                      className="font-cabin text-primary"
                    >
                      {t('horse_date_of_birth')}
                    </label>
                    <div className="d-flex mb-3">
                      <DatePicker
                        selected={birthdate}
                        locale={lang}
                        showYearDropdown
                        dropdownMode="select"
                        onChange={newDate => {
                          setState({ birthdate: newDate });
                        }}
                        customInput={<CustomInputCalendar />}
                      />
                    </div>
                    <label
                      htmlFor="withers"
                      className="font-cabin text-primary"
                    >
                      {t('horse_withers')}
                    </label>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="withers"
                        value={withers}
                        onChange={e => setState({ withers: e.target.value })}
                        disabled={isLoading}
                        required
                      />
                    </div>
                    <label htmlFor="job" className="font-cabin text-primary">
                      {t('horse_job')}
                    </label>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="job"
                        value={job}
                        onChange={e => setState({ job: e.target.value })}
                        disabled={isLoading}
                        required
                      />
                    </div>
                    <label htmlFor="breed" className="font-cabin text-primary">
                      {t('horse_breed')}
                    </label>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        type="text"
                        id="breed"
                        value={breed}
                        onChange={e => setState({ breed: e.target.value })}
                        disabled={isLoading}
                        required
                      />
                    </div>
                    <label htmlFor="breed" className="font-cabin text-primary">
                      {t('horse_description')}
                    </label>
                    <div className="form-group mb-3">
                      <textarea
                        className="form-control"
                        id="description"
                        value={description}
                        onChange={e =>
                          setState({ description: e.target.value })
                        }
                        disabled={isLoading}
                        required
                        style={{ height: '160px' }}
                      />
                    </div>

                    <div className="gallery mb-3 p-3 bg-white bg-gradient border rounded">
                      <h6 className="font-cabin text-primary small">
                        {t('horse_gallery')}
                      </h6>
                      <div className="gallery-uploader mb-3">
                        <div className="add-gallery">
                          <label
                            htmlFor="add-gallery"
                            className="btn w-100 bg-light border rounded-3 shadow-sm bg-gradient position-relative"
                          >
                            <span className="i`con">
                              <span className="fa fa-plus"></span>
                            </span>
                            <span className="text">{t('add_gallery')}</span>
                            <input
                              type="file"
                              id="add-gallery"
                              name="add-gallery"
                              onChange={onDropGallery}
                              value={inputGallery}
                            />
                          </label>
                        </div>
                      </div>
                      {gallery.length > 0 && (
                        <div className="gallery-container mb-3 row g-2">
                          {gallery.map((g, i) => (
                            <div className="col-6" key={g + i}>
                              <div className="gallery-item border rounded shadow-sm">
                                <img
                                  src={g}
                                  alt={g + i}
                                  className="rounded-top"
                                />
                                <div className="p-2 bg-light">
                                  <button
                                    className="btn btn-danger btn-sm w-100 m-0"
                                    onClick={() => deleteGallery(g)}
                                  >
                                    <span className="fa fa-trash me-1"></span>
                                    <small>{t('delete_label')}</small>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {image === '' || name === '' ? (
                      <button
                        type="button"
                        className="btn btn-lg btn-secondary w-100 mb-3"
                      >
                        {t('save_horse_button')}{' '}
                        <span className="ms-2 fa fa-chevron-right"></span>
                      </button>
                    ) : (
                      <button
                        onClick={handleSubmit}
                        className="btn btn-lg btn-success w-100 mb-3"
                      >
                        {t('save_horse_button')}{' '}
                        <span className="ms-2 fa fa-chevron-right"></span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddItemPage;
