import axios from 'axios';

axios.defaults.validateStatus = false;

const ROOT_URL = 'https://horsesfirst.qtipmeapi.com/api-v1';

export async function loginUser(dispatch, payload) {
  const data = new FormData();
  data.append('username', payload.username);
  data.append('password', payload.password);
  const requestOptions = {
    url: `${ROOT_URL}/login.php`,
    method: 'POST',
    headers: {
      'X-CORS-Access-Key': 'herp-derp',
    },
    data,
  };

  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    const response = await axios(requestOptions);
    const newData = response.data;

    if (response.status === 200) {
      const userData = {
        user: payload.username,
        ...newData.data,
      };
      const profileData = new FormData();
      profileData.append('token', userData.token);
      profileData.append('token_id', userData.token_id);
      const profileRequest = {
        url: `${ROOT_URL}/portal/common/get-profile.php`,
        method: 'POST',
        headers: {
          'X-CORS-Access-Key': 'herp-derp',
        },
        data: profileData,
      };

      const profileResponse = await axios(profileRequest);
      if (profileResponse.status === 200) {
        const profile = profileResponse.data;
        const userProfile = {
          ...userData,
          profile: profile.data,
        };
        dispatch({ type: 'LOGIN_SUCCESS', payload: userProfile });
        localStorage.setItem('currentUser', JSON.stringify(userProfile));
      }
    } else {
      dispatch({ type: 'LOGIN_ERROR', error: newData.message });
      console.log('error', newData);
    }
    return newData;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
    console.log(error);
  }
}

export async function setCredentials(payload) {
  localStorage.setItem('token', payload.token);
  const now = new Date();
  let expiresIn = now.getTime() + payload.expires_in * 1000;
  localStorage.setItem('expires_in', JSON.stringify(expiresIn));
}
export async function setUser(dispatch, payload) {
  dispatch({ type: 'LOGIN_SUCCESS', payload: payload });
  localStorage.setItem('user', JSON.stringify(payload));
}

export async function updateUser(dispatch, payload) {
  dispatch({ type: 'UPDATE_USER', payload });
  localStorage.setItem('user', JSON.stringify(payload));
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('expires_in');
}
