import React, { useState, useEffect } from 'react';
import { Link, useHistory, Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import Page from '../../components/global/Page';
import InternalHeader from '../../components/global/InternalHeader';
import Loader from '../../components/global/Loader';
import WidgetSeller from '../../components/global/WidgetSeller';

import { useAuthState } from '../../context';
import { getProductDetail } from '../../libs/apis';

import defaultItem from '../../assets/images/default-product.jpg';

SwiperCore.use([Navigation]);

function ProductPage() {
  let { productId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { user, role } = useAuthState();

  const initialState = {
    data: [],
    loading: true,
    error: false,
    message: '',
    favorited: false,
  };
  const [state, setMyState] = useState(initialState);
  const setState = newState => {
    setMyState(prevState => ({ ...prevState, ...newState }));
  };
  const { data, loading, error, message } = state;

  useEffect(() => {
    const getProductData = async payload => {
      setState({ loading: true, error: false, message: '' });
      await getProductDetail(payload)
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.response) {
              setState({ data: resp.data.response });
            }
          } else if (resp.status === 500) {
            setState({ error: true, message: resp.data.message });
          } else {
            setState({ error: true, message: resp.data.error.message });
          }
        })
        .catch(err => {
          console.log('err', err);
          setState({ error: true });
        })
        .finally(() => setState({ loading: false }));
    };
    navigator.geolocation.getCurrentPosition(
      position => {
        // let lat = position.coords.latitude
        // let lng = position.coords.longitude
        // console.log("getCurrentPosition Success " + lat + ', ' + lng) // logs position correctly
        // const loc = {
        //   center: {
        //     lat: lat,
        //     lng: lng,
        //   },
        //   zoom: 8,
        // }
        // const loc = {
        //   center: {
        //     lat: 60.631802,
        //     lng: 24.857800,
        //   },
        //   zoom: 8,
        // }
        const payload = {
          id: productId,
        };
        getProductData(payload);
      },
      error => {
        // const loc = {
        //   center: {
        //     lat: 60.631802,
        //     lng: 24.857800,
        //   },
        //   zoom: 8,
        // }
        const payload = {
          id: productId,
        };
        getProductData(payload);
      },
      { enableHighAccuracy: true, timeout: 2000, maximumAge: 1000 },
    );
  }, [productId, history]);

  if (!productId) return <Redirect to="/404" />;
  if (loading) return <Loader size="48" center />;
  if (error)
    return (
      <div className="app-container text-center d-flex flex-direction-column align-items-center justify-content-center w-100 vh-100">
        <div className="content py-5">
          <h3 className="mb-3">{message}</h3>
          <Link to="/" className="btn btn-warning">
            Back To Previous
          </Link>
        </div>
      </div>
    );

  const {
    seller,
    name,
    id,
    description,
    image,
    category,
    price_min,
    price_max,
    gallery,
  } = data;

  return (
    <div className="app-container">
      <InternalHeader title={name} go="/">
        <>
          <div className="header-title">
            <h2 className="fw-bold">{name}</h2>
            <h3 className="opacity-50 size-12">
              <span className="fa fa-tags me-1 text-primary"></span>
              {t(category.name.toLowerCase().replace(' ', '_'))}
            </h3>
          </div>
          <div className="header-additional">
            <h4 className="size-12 text-uppercase opacity-50">{t('price')}</h4>
            <h5 className="size-14 fw-bold">
              <span className="text-primary">{t('$')}</span> {price_min}-
              {price_max}
            </h5>
          </div>
        </>
      </InternalHeader>

      <div className="product-image">
        {image ? (
          <img src={image} alt={name} width="100%" />
        ) : (
          <img src={defaultItem} alt={name} />
        )}
      </div>
      <div className="main-content pb-5">
        {/* <div className="favorite-link">
          {favorited ? (
            <button
              className="btn btn-favorite shadow-sm active"
              onClick={() => setState({ favorited: false })}
            >
              <span className="fa fa-heart"></span>
            </button>
          ) : (
            <button
              className="btn btn-favorite shadow inactive"
              onClick={() => setState({ favorited: true })}
            >
              <span className="fa fa-heart"></span>
            </button>
          )}
        </div> */}
        <Page>
          <div className="content py-3">
            <h6 className="small mb-0 text-uppercase text-primary opacity-75">
              <span className="fa fa-info-circle me-2"></span>{' '}
              {t('description_heading')}
            </h6>
            <p>{description}</p>
            <div className="card shadow mb-4">
              <div className="card-body">
                <h5 className="mb-3 text-primary opacity-75 size-14 text-uppercase">
                  <span className="fa fa-user me-2"></span>{' '}
                  {t('provider_name_heading')}
                </h5>
                <WidgetSeller data={seller} />
              </div>
            </div>
            <div className="product-stats card shadow mb-3 d-none">
              <div className="stats card-body">
                <h5 className="mb-3 text-primary opacity-75 size-14 text-uppercase">
                  <span className="fa fa-line-chart me-2"></span>
                  {t('statistic_heading')}
                </h5>
                <div className="cards">
                  <div className="card-bodys">
                    <table className="table bg-white mb-0 table-bordered table-md">
                      <tbody>
                        <tr>
                          <td className="text-nowrap w-25">
                            <small>{t('stat_completed')}</small>
                          </td>
                          <td className="text-center">:</td>
                          <td className="w-75 font-cabin">
                            300 {t('order_text')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-nowrap w-25">
                            <small>{t('stat_total_order')}</small>
                          </td>
                          <td className="text-center">:</td>
                          <td className="w-75 font-cabin">
                            350 {t('order_text')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-nowrap w-25">
                            <small>{t('stat_avg_price')}</small>
                          </td>
                          <td className="text-center">:</td>
                          <td className="w-75 font-cabin">
                            <span className="text-primary">{t('$')}</span> 175
                          </td>
                        </tr>
                        <tr>
                          <td className="text-nowrap w-25">
                            <small>{t('stat_visited')}</small>
                          </td>
                          <td className="text-center">:</td>
                          <td className="w-75 font-cabin">
                            1.985.123 {t('stat_times')}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-nowrap w-25">
                            <small>{t('stat_last_ordered')}</small>
                          </td>
                          <td className="text-center">:</td>
                          <td className="w-75 font-cabin">February 12, 2021</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {gallery && gallery.length > 0 && (
              <div className="gallery mb-3">
                <h6 className="mb-2 text-uppercase small text-primary opacity-75">
                  <span className="fa fa-image me-2"></span>{' '}
                  {t('gallery_heading')}
                </h6>
                <div className="gallery-container">
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    autoHeight={true}
                    navigation={{
                      nextEl: '.swiper-next',
                      prevEl: '.swiper-prev',
                    }}
                  >
                    {gallery.map((g, index) => (
                      <SwiperSlide key={g + index}>
                        <img src={g} alt={g + index} className="w-100" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {gallery.length > 1 && (
                    <div className="swiper-navigation d-flex justify-content-between">
                      <button className="swiper-nav swiper-prev ">
                        <span className="fa fa-chevron-left"></span>
                      </button>
                      <button className="swiper-nav swiper-next ">
                        <span className="fa fa-chevron-right"></span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Page>
        <div className="page-cta shadow-lg p-2 fixed-bottom bg-white">
          {user && role === 'buyer' ? (
            <Link
              to={`/purchase/${id}`}
              className="btn bg-gradient btn-primary w-100"
            >
              <span className="menu-icon me-2">
                <span className="fa fa-send"></span>
              </span>
              <span className="menu-text">{t('request_quotation_button')}</span>
            </Link>
          ) : (
            <Link
              to={
                !user
                  ? `/login?redirect=/product/${productId}`
                  : `/for-buyer?redirect=/product/${productId}`
              }
              className="btn bg-gradient btn-primary w-100"
            >
              <span className="menu-icon me-2">
                <span className="fa fa-send"></span>
              </span>
              <span className="menu-text">{t('request_quotation_button')}</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductPage;
