import React from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../../components/global/AdminHeader';

function AdminBuyerIndex() {
  return (
    <div className="admin-container">
      <AdminHeader title="Buyer Admin" />
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="content py-3">
              <div className="admin-content d-flex align-items-center justify-content-end mb-3">
                <Link to="/" className="btn btn-sm btn-success">
                  <span className="fa fa-plus me-2"></span>Register New Buyer
                </Link>
              </div>
              <div className="table-responsive-sm">
                <table className="table table-bordered table table-striped bg-white">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Link to="/">Ivan</Link>
                      </td>
                      <td>
                        <div className="table-actions d-flex align-items-center justify-content-end">
                          <Link
                            to="/"
                            className="btn btn-sm btn-primary rounded-circle ms-2"
                          >
                            <span className="fa fa-eye"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-warning rounded-circle ms-2"
                          >
                            <span className="fa fa-edit"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-danger rounded-circle ms-2"
                          >
                            <span className="fa fa-times"></span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link to="/">Tom</Link>
                      </td>
                      <td>
                        <div className="table-actions d-flex align-items-center justify-content-end">
                          <Link
                            to="/"
                            className="btn btn-sm btn-primary rounded-circle ms-2"
                          >
                            <span className="fa fa-eye"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-warning rounded-circle ms-2"
                          >
                            <span className="fa fa-edit"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-danger rounded-circle ms-2"
                          >
                            <span className="fa fa-times"></span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link to="/">Helsynki</Link>
                      </td>
                      <td>
                        <div className="table-actions d-flex align-items-center justify-content-end">
                          <Link
                            to="/"
                            className="btn btn-sm btn-primary rounded-circle ms-2"
                          >
                            <span className="fa fa-eye"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-warning rounded-circle ms-2"
                          >
                            <span className="fa fa-edit"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-danger rounded-circle ms-2"
                          >
                            <span className="fa fa-times"></span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link to="/">Tamperee</Link>
                      </td>
                      <td>
                        <div className="table-actions d-flex align-items-center justify-content-end">
                          <Link
                            to="/"
                            className="btn btn-sm btn-primary rounded-circle ms-2"
                          >
                            <span className="fa fa-eye"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-warning rounded-circle ms-2"
                          >
                            <span className="fa fa-edit"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-danger rounded-circle ms-2"
                          >
                            <span className="fa fa-times"></span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link to="/">London</Link>
                      </td>
                      <td>
                        <div className="table-actions d-flex align-items-center justify-content-end">
                          <Link
                            to="/"
                            className="btn btn-sm btn-primary rounded-circle ms-2"
                          >
                            <span className="fa fa-eye"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-warning rounded-circle ms-2"
                          >
                            <span className="fa fa-edit"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-danger rounded-circle ms-2"
                          >
                            <span className="fa fa-times"></span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link to="/">Super Cool Long Name</Link>
                      </td>
                      <td>
                        <div className="table-actions d-flex align-items-center justify-content-end">
                          <Link
                            to="/"
                            className="btn btn-sm btn-primary rounded-circle ms-2"
                          >
                            <span className="fa fa-eye"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-warning rounded-circle ms-2"
                          >
                            <span className="fa fa-edit"></span>
                          </Link>
                          <Link
                            to="/"
                            className="btn btn-sm btn-danger rounded-circle ms-2"
                          >
                            <span className="fa fa-times"></span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr className="bg-light">
                      <td colspan="2">
                        <div className="table-footer py-3">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination pagination-sm justify-content-end m-0">
                              <li className="page-item disabled">
                                <a className="page-link" href="#prev">
                                  Previous
                                </a>
                              </li>
                              <li className="page-item">
                                <a className="page-link" href="#1">
                                  1
                                </a>
                              </li>
                              <li className="page-item">
                                <a className="page-link" href="#2">
                                  2
                                </a>
                              </li>
                              <li className="page-item">
                                <a className="page-link" href="#3">
                                  3
                                </a>
                              </li>
                              <li className="page-item">
                                <a className="page-link" href="#next">
                                  Next
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminBuyerIndex;
