export function getUserToken() {
  let token = null;
  if (window) {
    const t = window.localStorage.getItem('token');
    if (t) {
      token = t;
    }
  }
  return token;
}

export function deleteUser() {
  if (window) {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('expires_in');
    window.localStorage.removeItem('user');
  }
}

export function numberFormat(num) {
  const floatNum = parseFloat(num).toFixed(2);
  return floatNum;
}
