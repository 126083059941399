import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuthState } from '../context';
import Header from './global/Header';
import MainMenu from './global/MainMenu';

const AppRoutes = ({
  component: Component,
  path,
  isPrivate,
  showHeader = true,
  showMenu = true,
  ...rest
}) => {
  const userDetails = useAuthState();
  return (
    <Route
      path={path}
      render={props =>
        isPrivate && !Boolean(userDetails.user) ? (
          <Redirect to={{ pathname: '/login' }} />
        ) : (
          <>
            {showHeader && <Header />}
            <Component {...props} />
            {showMenu && <MainMenu />}
          </>
        )
      }
      {...rest}
    />
  );
};

export default AppRoutes;
