import React from 'react';
import AdminLogin from '../../components/admin/AdminLogin';
import Dashboard from '../../components/admin/Dashboard';

import { useAuthState } from '../../context';

function Admin() {
  const userDetails = useAuthState();
  const { role } = userDetails;

  if (role !== 'admin') return <AdminLogin />;
  return <Dashboard />;
}

export default Admin;
