import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuthState } from '../../context';
import ProductSummary from '../../components/seller/ProductSummary';

function SellerProduct() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, role } = useAuthState();

  if (role === 'admin') {
    history.push('/admin');
  }
  if (role !== 'seller') {
    history.push('/login?redirect=/seller&u=seller');
  }
  if (!user) history.push('/login');

  return (
    <div className="admin-container">
      <div className="admin-header py-3 bg-primary bg-gradient shadow">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <Link to="/seller" className=" text-light text-decoration-none">
                  <span className="fa fa-chevron-left me-2"></span>
                  <span className="text">{t('back_button')}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container py-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="content">
                <ProductSummary />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MainMenu /> */}
    </div>
  );
}

export default SellerProduct;
